import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import * as R from 'ramda';
import { motion } from 'framer-motion';

import { Report } from '../../../types';
import {
  Card,
  CardBody,
  Caption1,
  ColoredCircle,
  H5,
  UnstyledLink,
  UserAvatar,
  Tooltip,
} from '../../atoms';
import { paths } from '../../../routing/paths';

import { formatDate, formats } from '../../../util/dates';
import S from '../../../util/strings';
import ReportActions from './ReportActions';
import { Theme } from '../../../styles/settings';

interface ReportsGridProps {
  reports: Report[];
}

const GridCard = styled(Card)`
  cursor: pointer;
  border-color: ${({ theme }) => theme.backgroundColor.default};
  background-color: ${({ theme }) => theme.backgroundColor.background2};
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-style: solid;
  border-width: 1px;

  &:hover {
    border-color: ${({ theme }) => theme.backgroundColor.button.focus};
    transform: scale(1.05, 1.05);
  }
`;

const GridContainer = styled(motion.div)``;

const ReportTitle = styled(Box)`
  line-height: 28px;
  min-height: 60px;
  font-size: ${({ theme }) => theme.fontSize.h4};
`;

const LastUpdated = styled(Caption1)`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor.text2};
`;

const Date = styled(Caption1)`
  font-weight: bold;
  margin-top: 2px;
`;

const ReportCategory = styled(H5)`
  margin-top: 7px;
`;

const ReportLink = styled(UnstyledLink)`
  color: ${({ theme }) => theme.textColor.default};
`;

interface CollaboratorAvatarsProps {
  collaborators: Report['collaborators'];
  seriesSeed: number;
}

const CollaboratorAvatars: React.FC<CollaboratorAvatarsProps> = ({
  collaborators: givenCollaborators,
  seriesSeed,
}) => {
  const NUM_AVATARS_TO_DISPLAY = 3;
  const { avatarColor } = useTheme();
  const collaborators = R.values(givenCollaborators);
  const palette = R.values(avatarColor);

  const collaboratorsWithAvatars = R.take(
    NUM_AVATARS_TO_DISPLAY,
    collaborators
  );
  const collaboratorsWithoutAvatars = R.drop(
    NUM_AVATARS_TO_DISPLAY,
    collaborators
  );
  const numCollaboratorsWithoutAvatars = R.length(collaboratorsWithoutAvatars);

  return (
    <>
      <Box display="flex" alignItems="center">
        {collaboratorsWithAvatars.map((collaborator, i) => (
          <Tooltip key={collaborator.id} title={collaborator.user.name} arrow>
            <Box ml={1}>
              <UserAvatar
                user={collaborator.user}
                indexInSeries={i}
                seriesOffset={seriesSeed}
                palette={palette}
              />
            </Box>
          </Tooltip>
        ))}
        {numCollaboratorsWithoutAvatars > 0 && (
          <Tooltip
            leaveDelay={1000}
            title={
              <>
                {collaboratorsWithoutAvatars.map((collaborator, i) => (
                  <Flex alignItems="center" py="2px" key={collaborator.id}>
                    <UserAvatar
                      user={collaborator.user}
                      indexInSeries={i}
                      seriesOffset={seriesSeed}
                      palette={palette}
                    />
                    <div style={{ paddingLeft: '4px' }}>
                      {collaborator.user.name}
                    </div>
                  </Flex>
                ))}
              </>
            }
          >
            <ColoredCircle
              color={Theme.colors.greyDark4}
              style={{ marginLeft: '4px' }}
            >{`+${numCollaboratorsWithoutAvatars}`}</ColoredCircle>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

// TODO: consolidate w/ the method in ReportsTable?
const reportToCardData = (report: Report) => {
  return {
    ...report,
    category: S.titleCase(S.kebabCase(report.category).replace('-', ' ')),
    lastUpdated: formatDate({
      date: report.lastUpdated,
      format: formats.shortMonthDayAndYear,
    }),
  };
};

const ReportsGrid: React.FC<ReportsGridProps> = ({ reports }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 4,
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
      p="20px"
    >
      {R.map(reportToCardData, reports).map((report, i) => (
        <GridContainer
          initial={{
            opacity: 0,
            y: -50,
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.3,
            },
          }}
          key={report.id}
        >
          <GridCard>
            <CardBody>
              <Flex flexDirection="column" justifyContent="space-between">
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  mr="-7px"
                  mb="60px"
                >
                  <ReportCategory>{report.category}</ReportCategory>
                  <ReportActions />
                </Flex>
                <ReportTitle mb={1}>
                  <ReportLink to={paths.report(report.id)}>
                    {report.name}
                  </ReportLink>
                </ReportTitle>
                <Box mt={4}>
                  <LastUpdated>Last Updated</LastUpdated>
                </Box>
                <Flex
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Date>{report.lastUpdated}</Date>
                  <CollaboratorAvatars
                    collaborators={report.collaborators}
                    seriesSeed={i}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </GridCard>
        </GridContainer>
      ))}
    </Box>
  );
};

export default ReportsGrid;
