import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

export interface HasId {
  id: string;
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export const makeId = ({ entity }: { entity: string }) => {
  return uuid();
};

export const byId = <A extends HasId>(things: A[]): Record<HasId['id'], A> => {
  return R.zipObj(R.map(R.prop('id'), things), things);
};

export const toNumber = (str: string) => {
  // Taken from https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2800233

  /* eslint-disable no-bitwise */
  return Array.from(str).reduce(
    (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
    0
  );
  /* eslint-enable no-bitwise */
};
