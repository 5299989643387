import moment from 'moment';
import TimePicker, { TimePickerProps } from 'rc-time-picker';
import React from 'react';
import { HiClock } from 'react-icons/hi';

import { ClassNames, ClassNamesContent, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { styleUtils, ThemeType } from '../../styles/settings';

const StyledTimePicker = styled(TimePicker)`
  display: block;

  .rc-time-picker-input {
    ${styleUtils.pillContainerStyle}
    padding-left: calc(${({ theme }) => theme.padding.input.x} + 25px);
    padding-top: calc(${({ theme }) => theme.padding.input.y} + 4px);
    padding-bottom: calc(${({ theme }) => theme.padding.input.y} + 4px);
    font-size: inherit;
    color: inherit;
    height: auto;
    background-color: ${({ theme }) => theme.backgroundColor.background2};
  }
  .rc-time-picker-clear {
    height: 20px;
    width: 20px;
    padding-top: 5px;
  }
  .rc-time-picker-clear-icon {
    height: 20px;
    width: 20px;
    margin-top: 15px;
  }
`;

const StyledIcon = styled(HiClock)`
  position: absolute;
  left: ${({ theme }) => theme.padding.input.x};
  color: ${({ theme }) => theme.textColor.text3};
  top: 50%;
  transform: translate(0, -50%);
`;

const popupClassname = (css: ClassNamesContent['css'], theme: ThemeType) => css`
  z-index: 1500;

  .rc-time-picker-panel-inner {
    font-size: inherit;
    left: +30px;
    top: +40px;
    background: ${theme.backgroundColor.background1};
  }

  .rc-time-picker-panel-select {
    font-size: inherit;
    li {
      :hover {
        color: ${theme.backgroundColor.background1};
        background: ${theme.backgroundColor.button.default};
      }
    }
  }
  .rc-time-picker-panel-input-wrap {
    display: none;
  }
  .rc-time-picker-panel-select-option-selected {
    background: ${theme.backgroundColor.button.default};
    color: ${theme.backgroundColor.background1};
  }
`;

interface Props extends Omit<TimePickerProps, 'value'> {
  value: Date | null;
}

const CustomTimePicker: React.FC<Props> = ({ value, ...props }) => {
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css }) => (
        <StyledTimePicker
          showSecond={false}
          popupClassName={popupClassname(css, theme)}
          use12Hours
          inputIcon={<StyledIcon />}
          value={value ? moment(value) : undefined}
          inputReadOnly
          {...props}
        />
      )}
    </ClassNames>
  );
};

export default CustomTimePicker;
