import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';

import { standardDateRangeFilters } from '../../data/report/dateRangeFilterData';
import { AllDateRangeOptions, CustomDateRangeOptions } from '../../types';
import { formatDate } from '../../util/dates';
import {
  CardBody,
  DropdownItem,
  LabelledDropdown,
  Popover,
  UnstyledButton,
} from '../atoms';
import CustomDateRangePicker from './CustomDateRangePicker/CustomDateRangePicker';

const allDropdownItems: DropdownItem[] = [
  {
    label: 'Custom Date Range',
    value: CustomDateRangeOptions.Custom,
  },
  ...Object.values(standardDateRangeFilters).map(({ label, value }) => ({
    label,
    value,
  })),
];

interface CustomRangeButtonProps {
  show: boolean;
  disableElevation: boolean;
}

const CustomRangeButton = styled(UnstyledButton)<CustomRangeButtonProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  background: ${({ theme }) => theme.backgroundColor.background1};
  border-color: ${({ theme }) => theme.backgroundColor.background1};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding-right: 8px;
  padding-left: 8px;
  margin-left: -2px;
  :hover {
    border-color: ${({ theme }) => theme.backgroundColor.button.focus};
  }
`;

interface Props {
  selectedOption: AllDateRangeOptions;
  endDate: Date;
  startDate: Date;
  startTime: Date | null;
  endTime: Date | null;
  handleDateRangeOptionChange: (value: AllDateRangeOptions) => void;
  handleCustomDateRangeChange: (
    newStartDate: Date,
    newEndDate: Date,
    newStartTime: Date | null,
    newEndTime: Date | null
  ) => void;
}

const DateRangeDropdown: React.FC<Props> = ({
  selectedOption,
  handleDateRangeOptionChange,
  handleCustomDateRangeChange,
  endDate,
  startDate,
  startTime,
  endTime,
}) => {
  const [showDatePickerPopover, setShowDatePickerPopover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const customContainerRef = useRef<HTMLButtonElement>(null);

  const handlePopoverClose = () => {
    setShowDatePickerPopover(false);
    setAnchorEl(undefined);
  };

  const handlePopoverOpen = () => {
    setShowDatePickerPopover(true);
    setAnchorEl(customContainerRef.current as HTMLButtonElement);
  };

  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as AllDateRangeOptions;
    handleDateRangeOptionChange(value);

    if (value === CustomDateRangeOptions.Custom) {
      handlePopoverOpen();
    }
  };

  const handleCustomDateRangeApply = (
    newStartDate: Date,
    newEndDate: Date,
    newStartTime: Date | null,
    newEndTime: Date | null
  ) => {
    handlePopoverClose();
    handleCustomDateRangeChange(
      newStartDate,
      newEndDate,
      newStartTime,
      newEndTime
    );
  };

  const handleCustomDateRangeCancel = () => {
    handlePopoverClose();
  };

  return (
    <>
      <LabelledDropdown
        value={selectedOption}
        onChange={handleDropdownChange}
        dropdownItems={allDropdownItems}
        label="Date Range"
        filter
      />

      <CustomRangeButton
        show={selectedOption === CustomDateRangeOptions.Custom}
        type="button"
        onClick={handlePopoverOpen}
        ref={customContainerRef}
        aria-label="Open custom date range picker"
        disableElevation
      >
        {formatDate({ date: startDate })} - {formatDate({ date: endDate })}
      </CustomRangeButton>

      <Popover
        open={showDatePickerPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CardBody>
          <CustomDateRangePicker
            initialStartDate={startDate}
            initialEndDate={endDate}
            initialStartTime={startTime}
            initialEndTime={endTime}
            startPickerId="start-date-picker"
            endPickerId="end-date-picker"
            handleApply={handleCustomDateRangeApply}
            handleCancel={handleCustomDateRangeCancel}
          />
        </CardBody>
      </Popover>
    </>
  );
};

export default DateRangeDropdown;
