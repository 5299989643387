import * as R from 'ramda';
import React from 'react';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { ownerName } from '../../../data/reports/reportGroups';
import { Report } from '../../../types';
import { formatDate, formats } from '../../../util/dates';
import S from '../../../util/strings';
import { NotificationIcon, TableCell, TableRow } from '../../atoms';
import { HeadCell, IndexTable } from '../../organisms';
import ReportActions from './ReportActions';

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.backgroundColor.rowHover};
  }
`;

interface RowData extends Record<string, string | number | boolean> {
  id: string;
  name: string;
  category: string;
  ownerName: string;
  lastUpdated: string;
  notifications: boolean;
}

interface ReportsTableProps {
  reports: Report[];
  onRowClicked: (reportId: string) => void; // TODO: revisit if/when we want to link to specific reports
}

const rowDataFields = (report: Report) => {
  return R.pick(['id', 'name', 'category', 'lastUpdated'], report);
};

const reportToRowData = (report: Report): RowData => {
  return {
    ...rowDataFields(report),
    ownerName: ownerName(report) || '—',
    category: S.titleCase(S.kebabCase(report.category).replace('-', ' ')),
    lastUpdated: formatDate({
      date: report.lastUpdated,
      format: formats.shortMonthDayAndYear,
    }),
    notifications: Boolean(report.notifications),
  };
};

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
  },
  { id: 'category', numeric: false, disablePadding: false, label: 'CATEGORY' },
  { id: 'creator', numeric: false, disablePadding: false, label: 'CREATOR' },
  {
    id: 'lastUpdated',
    numeric: false,
    disablePadding: false,
    label: 'LAST UPDATED',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    notSortable: true,
  },
];

// const Container = styled(motion.div)``;

const ReportsTable: React.FC<ReportsTableProps> = ({
  onRowClicked,
  reports,
}) => {
  const rows = R.map(reportToRowData, reports);

  return (
    <IndexTable
      headCells={headCells}
      rows={rows}
      renderRow={(row: RowData, key: string) => {
        return (
          <StyledTableRow
            hover
            onClick={() => onRowClicked(row.id)}
            role="checkbox"
            tabIndex={-1}
            key={key}
          >
            <TableCell scope="row">
              <Flex alignItems="center">
                <Box flex="1">{row.name}</Box>

                {row.notifications && (
                  <NotificationIcon
                    width="24px"
                    height="24px"
                    iconSize="16px"
                  />
                )}
              </Flex>
            </TableCell>
            <TableCell>{row.category}</TableCell>
            <TableCell>{row.ownerName}</TableCell>
            <TableCell>{row.lastUpdated}</TableCell>
            <TableCell>
              <ReportActions />
            </TableCell>
          </StyledTableRow>
        );
      }}
    />
  );
};

export default ReportsTable;
