import { getTime } from 'date-fns';

import {
  Collaborator,
  CollaboratorId,
  Notification,
  Report,
  ReportCategory,
} from '../../types';
import { dummyReportWidgetsData } from './dummyReportData';

export const makeReport = ({
  name,
  category,
  lastUpdated,
  id,
  filters,
  segmentationFilters,
  detailedSegmentationFilters,
  collaborators,
  notifications,
}: {
  name: string;
  category?: ReportCategory;
  lastUpdated?: number;
  id: string;
  filters: Report['filters'];
  segmentationFilters?: Report['segmentationFilters'];
  detailedSegmentationFilters?: Report['detailedSegmentationFilters'];
  collaborators?: Record<CollaboratorId, Collaborator>;
  notifications?: Notification[];
}): Report => {
  return {
    id,
    name,
    category: category || ReportCategory.Management,
    lastUpdated: lastUpdated || getTime(new Date()),
    filters,
    segmentationFilters,
    detailedSegmentationFilters,
    collaborators: collaborators || {},
    notifications,
    widgets: dummyReportWidgetsData(id),
  };
};
