import * as R from 'ramda';

import { HasId } from '../types/common';

const mapFnToKeysAndVals = <A, B>(
  fn: (key: string, val: A) => B,
  obj: Record<string, A>
) => R.map(([key, val]) => fn(key, val), R.toPairs(obj));

// Like mapValues, but the fn takes both key and val as args
export const mapKvpValues = <A, B>(
  fn: (key: string, val: A) => B,
  obj: Record<string, A>
) => R.zipObj(R.keys(obj), mapFnToKeysAndVals(fn, obj));

// Like mapKeys, but the fn takes both key and val as args
export const mapKvpKeys = <A>(
  fn: (key: string, val: A) => string,
  obj: Record<string, A>
) => R.zipObj(mapFnToKeysAndVals<A, string>(fn, obj), R.values(obj));

export const mapValues = <A, B>(fn: (input: A) => B, obj: Record<string, A>) =>
  mapKvpValues((_k, v) => fn(v), obj);

export const mapKeys = <A>(
  fn: (input: string) => string,
  obj: Record<string, A>
) => mapKvpKeys((k) => fn(k), obj);

export const mapKeysAndValues = <A, B>({
  mapKeys: onKeys,
  mapValues: onValues,
  obj,
}: {
  mapKeys: (input: string) => string;
  mapValues: (input: A) => B;
  obj: Record<string, A>;
}) => mapValues(onValues || R.identity, mapKeys(onKeys || R.identity, obj));

export const filterValues = <K extends string | number | symbol, V>(
  fn: (val: V) => boolean,
  obj: Record<K, V>
) => {
  const pairs = R.toPairs(obj);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const matchingPairs = R.filter(([_, v]) => fn(v), pairs);
  return R.fromPairs(matchingPairs);
};

export const mergeAndLimitToDefaultKeys = <A = unknown>({
  defaults,
  values,
}: {
  defaults: Record<string, A>;
  values: Record<string, A>;
}) => {
  const merged = {
    ...defaults,
    ...values,
  };

  return R.pick(R.keys(defaults), merged);
};

export const changeKeys = <Obj extends Record<string, string>>(
  obj: Obj,
  changes: Record<string, string>
) => {
  const changeOrDefault = (key: string) => changes[key] || key;

  return mapKeys(changeOrDefault, obj);
};

export const addItemById = <T extends HasId>(
  prevObj: Record<string, T>,
  newItem: T
) => {
  return {
    ...prevObj,
    [newItem.id]: newItem,
  };
};

export const removeItemById = <T extends HasId>(
  prevObj: Record<string, T>,
  item: T
) => {
  return R.omit([item.id], prevObj);
};
