export const paths = {
  home: () => '/',
  intro: () => '/intro',
  welcome: () => '/welcome',
  report: (id?: string) => `/report/${id || ':id'}`,
  widgetData: (id?: string) => `/widget/${id || ':id'}/data`,
  reports: () => '/reports',
  notifications: () => '/notifications',
  profile: () => '/profile',
  profileEdit: () => '/profile/profile-edit',
  profilePreferences: () => '/profile/profile-preferences',
  iframeReport: () => '/iframe-report',
};

export default paths;
