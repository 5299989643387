import { ScheduledReport } from '../../../../types';
import { makeId } from '../../../../util/ids';
import { CardModal } from '../../../organisms';
import { CardModalProps } from '../../../organisms/CardModal';
import ReportActionsIndex from '../ReportActionsIndex';
import useSetActionsModal from '../useSetActions';
import ScheduleReport from './ScheduleReport';
import { ScheduleReportFormValues } from './types';

interface ScheduleReportModalProps extends Omit<CardModalProps, 'children'> {
  scheduledReports: Record<string, ScheduledReport>;
  onAddReport: (report: ScheduledReport) => void;
  onRemoveReport: (report: ScheduledReport) => void;
}

const ScheduleReportModal: React.FC<ScheduleReportModalProps> = ({
  scheduledReports,
  onClose,
  onAddReport,
  onRemoveReport,
  ...props
}) => {
  const {
    showIndex,
    switchToForm,
    itemCurrentlyEditing,
    handleClose,
    handleEdit,
    reset,
  } = useSetActionsModal<ScheduledReport>(onClose);

  const modalTitle = showIndex ? 'Report Schedule' : 'Schedule Report';

  const handleSubmit = (data: ScheduleReportFormValues) => {
    const report = {
      id: itemCurrentlyEditing?.id || makeId({ entity: 'ScheduledReport' }),
      ...data,
    };
    onAddReport(report);
    reset();
  };

  return (
    <CardModal title={modalTitle} onClose={handleClose} {...props}>
      {showIndex ? (
        <ReportActionsIndex
          items={scheduledReports}
          onAddClick={switchToForm}
          onEditClick={handleEdit}
          onRemoveClick={onRemoveReport}
          removeConfirmationText="Are you sure you want to delete that report?"
          emptyItemsText="No reports have been scheduled"
          addButtonText="Add Schedule"
        />
      ) : (
        <ScheduleReport
          existingReport={itemCurrentlyEditing}
          onSubmit={handleSubmit}
          onCancel={() => reset()}
        />
      )}
    </CardModal>
  );
};

export default ScheduleReportModal;
