import { ReportAlert } from '../../../../types';
import { makeId } from '../../../../util/ids';
import { CardModal } from '../../../organisms';
import { CardModalProps } from '../../../organisms/CardModal';
import ReportActionsIndex from '../ReportActionsIndex';
import useSetActionsModal from '../useSetActions';
import SetAlertForm from './SetAlertForm';
import { SetAlertFormValues } from './types';

interface SetAlertModalProps extends Omit<CardModalProps, 'children'> {
  alerts: Record<string, ReportAlert>;
  onAdd: (alert: ReportAlert) => void;
  onRemove: (alert: ReportAlert) => void;
}

const SetAlertModal: React.FC<SetAlertModalProps> = ({
  alerts,
  onClose,
  onAdd,
  onRemove,
  ...props
}) => {
  const {
    showIndex,
    switchToForm,
    itemCurrentlyEditing,
    handleClose,
    handleEdit,
    reset,
  } = useSetActionsModal<ReportAlert>(onClose);

  const handleSubmit = (data: SetAlertFormValues) => {
    const alert = {
      id: itemCurrentlyEditing?.id || makeId({ entity: 'SetAlert' }),
      ...data,
    };
    onAdd(alert);
    reset();
  };

  return (
    <CardModal title="Set Alerts" onClose={handleClose} {...props}>
      {showIndex ? (
        <ReportActionsIndex
          items={alerts}
          onAddClick={switchToForm}
          onEditClick={handleEdit}
          onRemoveClick={onRemove}
          removeConfirmationText="Are you sure you want to delete that alert?"
          emptyItemsText="No alerts have been set"
          addButtonText="Add Alert"
        />
      ) : (
        <SetAlertForm
          existingAlert={itemCurrentlyEditing}
          onSubmit={handleSubmit}
          onCancel={() => reset()}
        />
      )}
    </CardModal>
  );
};

export default SetAlertModal;
