import { array, date, number, object, string } from 'yup';

import { RepeatOptions } from '../../../../types';

export const scheduleReportModalValidation = object().shape({
  title: string().required('Required'),
  emailTo: array().min(1, 'Add at least 1 email').required('Required'),
  startDate: date().required('Required'),
  startTime: date().required('Required'),
  repeat: string().required('Required'),
  repeatEveryNumber: number().when('repeat', {
    is: (value: string) => value === RepeatOptions.Custom,
    then: number().positive('Must be > 0').required('Required'),
    otherwise: number().notRequired(),
  }),
  repeatEveryPeriod: string().when('repeat', {
    is: (value: string) => value === RepeatOptions.Custom,
    then: string().required('Required'),
    otherwise: string().notRequired(),
  }),
});
