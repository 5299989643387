import React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { Box } from 'rebass';

import { ErrorMessage } from '@hookform/error-message';

import { widgetFilterOptions } from '../../../data/widget/widgetDisplayData';
import { SelectOption, WidgetFilterOptions } from '../../../types';
import {
  Button,
  Dropdown,
  ErrorTextWithContainer,
  IconButton,
  MenuItem,
  Paragraph,
  SearchDropdown,
} from '../../atoms';
import { EditWidgetFormValues } from './editWidgetTypes';

interface FilterInputsSetProps {
  index: number;
  optionSet: WidgetFilterOptions;
  onRemoveClick: (index: number) => void;
}

const FilterInputsSet: React.FC<FilterInputsSetProps> = ({
  index,
  onRemoveClick,
  optionSet,
}) => {
  const { control, setValue } = useFormContext<EditWidgetFormValues>();

  const attribute = useWatch({
    control,
    name: `filters.${index}.attribute` as const,
  });

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: '1.25fr 1fr 1fr auto',
      }}
    >
      <Box>
        <Controller
          name={`filters.${index}.attribute` as const}
          control={control}
          render={({ field: { name, onChange, ...restOfField } }) => (
            <Dropdown
              placeholder="Select attribute"
              placeholderDisabled
              onChange={(e) => {
                setValue(
                  `filters.${index}.operator` as `filters.0.operator`,
                  ''
                );
                setValue(`filters.${index}.values` as `filters.0.values`, []);
                onChange(e);
              }}
              name={name}
              fullWidth
              {...restOfField}
            >
              {Object.values(optionSet).map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Dropdown>
          )}
        />
        <ErrorMessage
          name={`filters.${index}.attribute` as const}
          render={({ message }) => (
            <ErrorTextWithContainer>{message}</ErrorTextWithContainer>
          )}
        />
      </Box>

      <Box>
        <Controller
          name={`filters.${index}.operator` as const}
          control={control}
          render={({ field }) => (
            <Dropdown
              placeholder="Select operator"
              placeholderDisabled
              disabled={!attribute}
              fullWidth
              {...field}
            >
              {attribute &&
                optionSet[attribute].operators.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Dropdown>
          )}
        />
        <ErrorMessage
          name={`filters.${index}.operator` as const}
          render={({ message }) => (
            <ErrorTextWithContainer>{message}</ErrorTextWithContainer>
          )}
        />
      </Box>

      <Box>
        <Controller
          // TS casting as name: https://react-hook-form.com/api/usefieldarray
          name={`filters.${index}.values` as 'filters.0.values'}
          control={control}
          render={({ field: { name, value } }) => (
            <SearchDropdown
              id={`filter-value-${index}`}
              options={attribute ? optionSet[attribute].values : []}
              disabled={!attribute}
              selectedValue={value || []}
              onChange={(event, newValue) => {
                const newSelections = newValue as SelectOption<string>[];

                setValue(
                  name as 'filters.0.values',
                  newSelections.map((option) => option.value),

                  // Ideally should not need this logic
                  // looks like bug in react-hook-form
                  // validation should be automatically triggered with setValue
                  // when error is created or corrected
                  {
                    shouldValidate:
                      newSelections.length === 0 || newSelections.length === 1,
                  }
                );
              }}
            />
          )}
        />
        <ErrorMessage
          name={`filters.${index}.values` as 'filters.0.values'}
          render={({ message }) => (
            <ErrorTextWithContainer>{message}</ErrorTextWithContainer>
          )}
        />
      </Box>
      <IconButton
        type="button"
        onClick={() => onRemoveClick(index)}
        icon={HiX}
        active={false}
        variant="secondary"
      />
    </Box>
  );
};

const EditWidgetFilters = () => {
  const { control } = useFormContext<EditWidgetFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  // Attributes dropdown determined by 'value'
  // Might need additional watches for table
  const mainValue = useWatch({
    control,
    name: 'value',
  });

  if (!mainValue) {
    return (
      <Paragraph>
        Select a widget type and add values to enable filters.
      </Paragraph>
    );
  }

  const optionSet = widgetFilterOptions(mainValue);

  return (
    <div>
      <Box
        sx={{
          display: 'grid',
          gridGap: 2,
          gridTemplateColumns: '1fr',
        }}
        pb={2}
      >
        {fields.map((item, index) => (
          <FilterInputsSet
            key={item.id}
            index={index}
            onRemoveClick={remove}
            optionSet={optionSet}
          />
        ))}
      </Box>

      <Button variant="text" type="button" bold onClick={() => append({})}>
        + Add new filter
      </Button>
    </div>
  );
};

export default EditWidgetFilters;
