// NOTE: this file is for any imports from Material UI that just need
// to be re-exported as atoms.
//
// If we need to "do anything" with a particular component then you
// should break it out into its own atom file

import {
  Autocomplete as MUIAutocomplete,
  Box as MUIBox,
  Checkbox as MUICheckbox,
  FormControlLabel as MUIFormControlLabel,
  FormGroup as MUIFormGroup,
  Grid as MUIGrid,
  InputLabel as MUIInputLabel,
  Menu as MUIMenu,
  Popper as MUIPopper,
  Snackbar as MUISnackbar,
  Table as MUITable,
  TableBody as MUITableBody,
  TableContainer as MUITableContainer,
  TableHead as MUITableHead,
  TableSortLabel as MUITableSortLabel,
  Tabs as MUITabs,
  Tooltip as MUITooltip,
} from '@mui/material';

export const Autocomplete = MUIAutocomplete;
export const Box = MUIBox;
export const Checkbox = MUICheckbox;
export const FormControlLabel = MUIFormControlLabel;
export const FormGroup = MUIFormGroup;
export const Grid = MUIGrid;
export const InputLabel = MUIInputLabel;
export const Menu = MUIMenu;
export const Popper = MUIPopper;
export const Snackbar = MUISnackbar;
export const Table = MUITable;
export const TableBody = MUITableBody;
export const TableContainer = MUITableContainer;
export const TableHead = MUITableHead;
export const TableSortLabel = MUITableSortLabel;
export const Tabs = MUITabs;
export const Tooltip = MUITooltip;

export enum VerticalOrigin {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

export enum HorizontalOrigin {
  left = 'left',
  center = 'center',
  right = 'right',
}
