import { useEffect } from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
// Hook for capturing clicks outside an element
const useOutsideClick = (
  ref: React.MutableRefObject<any>,
  callback: () => void
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };

    // NO deps, can add if need to optimize
  });
};

export default useOutsideClick;
