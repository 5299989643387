import styled from '@emotion/styled';
import { Paper as OrigPaper } from '@mui/material';

const Paper = styled(OrigPaper)`
  /* TODO: paper needs to be styled per Figma styles */
  &.MuiPaper-rounded {
    border-radius: ${({ theme }) => theme.borders.default.radius};
  }
`;

export default Paper;
