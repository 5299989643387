import React from 'react';

import {
  BarChartData,
  BarChartWidget,
  CalloutData,
  CalloutWidget,
  LineChartData,
  LineChartWidget,
  ReportWidget,
  TableData,
  TableWidget,
  WidgetType as WT,
} from '../../types';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';
import ReportWidgetBar from './ReportWidgetBar';
import ReportWidgetCallout from './ReportWidgetCallout';
import ReportWidgetLine from './ReportWidgetLine';
import ReportWidgetTable from './ReportWidgetTable';

interface Props {
  reportWidget: Omit<ReportWidget, 'layout'>;
  preview?: boolean;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const ReportWidgetComponent: React.FC<Props> = ({
  reportWidget,
  ...restOfProps
}) => {
  const { widget, data } = reportWidget;

  switch (widget.type) {
    case WT.Callout:
      return (
        <ReportWidgetCallout
          widget={widget as CalloutWidget}
          data={data as CalloutData | undefined}
          {...restOfProps}
        />
      );
    case WT.Table:
      return (
        <ReportWidgetTable
          widget={widget as TableWidget}
          data={data as TableData | undefined}
          {...restOfProps}
        />
      );
    case WT.LineChart:
      return (
        <ReportWidgetLine
          widget={widget as LineChartWidget}
          data={data as LineChartData[] | undefined}
          {...restOfProps}
        />
      );
    case WT.BarChart:
      return (
        <ReportWidgetBar
          widget={widget as BarChartWidget}
          data={data as BarChartData | undefined}
          {...restOfProps}
        />
      );
    default:
      return null;
  }
};

export default ReportWidgetComponent;
