import React from 'react';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';
import { IconDecline } from '../../assets/images';

import { styleUtils } from '../../styles/settings';
import { UnstyledButton } from './Buttons';
import PillLabel from './PillLabel';
import { Paragraph } from './Text';

interface Props {
  onRemove: () => void;
  text: string;
  label?: string;
  removeButtonLabel: string;
}

const Container = styled(Flex)`
  ${styleUtils.pillContainerStyle}
`;

const CloseButton = styled(UnstyledButton)`
  border-radius: ${({ theme }) => theme.borders.default.radius};
`;

const StyledX = styled(IconDecline)`
  background: ${({ theme }) => theme.backgroundColor.button.tertiary.default};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  color: ${({ theme }) => theme.backgroundColor.button.tertiary.focus};
`;

const Chip: React.FC<Props> = ({
  onRemove,
  text,
  label,
  removeButtonLabel,
}) => {
  return (
    <Container alignItems="center">
      {label && (
        <Box mr={[3]}>
          <PillLabel>{label}</PillLabel>
        </Box>
      )}
      <Paragraph>{text}</Paragraph>
      <Box mr={[3]} />
      <CloseButton
        type="button"
        onClick={onRemove}
        aria-label={removeButtonLabel}
      >
        <Flex>
          <StyledX />
        </Flex>
      </CloseButton>
    </Container>
  );
};

export default Chip;
