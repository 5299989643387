import {
  QueryMetric,
  ReportWidget,
  WidgetSize,
  WidgetTheme as Themes,
  WidgetType,
} from '../../types';
import { layoutSizeAndConstraintsProps } from '../../util/widget';
import {
  widgetMainValueOptions,
  widgetXAxisValueOptions,
} from '../widget/widgetDisplayData';

const WT = WidgetType;

export const dummyReportWidgetsData = (
  reportId: string
): Record<string, ReportWidget> => ({
  '22222': {
    reportId,
    widget: {
      id: '22222',
      name: 'Total Spend',
      type: WT.Callout,
      theme: Themes.Theme1,
      value: widgetMainValueOptions.spend.value,
      valueMetric: QueryMetric.Sum,
      size: WidgetSize.Small,
      filters: [
        {
          attribute: 'mcc',
          operator: 'Is one of',
          values: ['airlines'],
        },
      ],
    },
    layout: {
      i: '22222',
      x: 0,
      y: 0,
      ...layoutSizeAndConstraintsProps(WT.Callout),
    },
  },
  '33333': {
    reportId,
    widget: {
      id: '33333',
      name: 'Total Volume',
      type: WT.Callout,
      theme: Themes.Theme2,
      value: widgetMainValueOptions.volume.value,
      valueMetric: QueryMetric.Sum,
      size: WidgetSize.Small,
    },
    layout: {
      i: '33333',
      x: 0,
      y: 1,
      ...layoutSizeAndConstraintsProps(WT.Callout),
    },
  },
  '44444': {
    reportId,
    widget: {
      id: '44444',
      name: 'Interchange',
      type: WT.Callout,
      theme: Themes.Theme2,
      value: widgetMainValueOptions.interchange.value,
      valueMetric: QueryMetric.Sum,
      size: WidgetSize.Small,
    },
    layout: {
      i: '44444',
      x: 0,
      y: 2,
      ...layoutSizeAndConstraintsProps(WT.Callout),
    },
  },
  '55555': {
    reportId,
    widget: {
      id: '55555',
      name: 'Total Active Cards',
      type: WT.Callout,
      theme: Themes.Theme1,
      value: widgetMainValueOptions.cards.value,
      valueMetric: QueryMetric.Sum,
      size: WidgetSize.Small,
    },
    layout: {
      i: '55555',
      x: 0,
      y: 3,
      ...layoutSizeAndConstraintsProps(WT.Callout),
    },
  },
  '111111': {
    reportId,
    widget: {
      id: '111111',
      name: 'Total Spend',
      type: WT.LineChart,
      theme: Themes.Theme1,
      value: widgetMainValueOptions.spend.value,
      valueMetric: QueryMetric.Sum,
      secondaryValue: widgetXAxisValueOptions.time.value,
      secondaryValueMetric: QueryMetric.Day,
      size: WidgetSize.Medium,
    },
    layout: {
      i: '111111',
      x: 1,
      y: 1,
      ...layoutSizeAndConstraintsProps(WT.LineChart),
    },
  },
  '99998': {
    reportId,
    widget: {
      id: '99998',
      name: 'Interchange',
      type: WT.LineChart,
      theme: Themes.Theme2,
      value: widgetMainValueOptions.debit.value,
      valueMetric: QueryMetric.Sum,
      secondaryValue: widgetXAxisValueOptions.time.value,
      secondaryValueMetric: QueryMetric.Day,
      size: WidgetSize.Medium,
    },
    layout: {
      i: '99998',
      x: 1,
      y: 3,
      ...layoutSizeAndConstraintsProps(WT.LineChart),
    },
  },
});
