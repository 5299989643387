import * as R from 'ramda';
import React, { ReactNode, SyntheticEvent } from 'react';
import { Flex } from 'rebass';

import styled from '@emotion/styled';

import { SelectOption } from '../../types';
import { UnstyledButton } from './Buttons';
import Input from './Input';
import { Autocomplete, Checkbox, Popper } from './MUIReExports';

interface Props {
  id: string;
  placeholder?: string;
  selectedValue: SelectOption['value'][];
  options: SelectOption[];
  onChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: SelectOption<string> | SelectOption<string>[] | null
  ) => void;
  multiselect?: boolean;
  disabled?: boolean;
}

const ToggleButton = styled(UnstyledButton)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  border: ${({ theme }) => theme.borders.input};
  width: 100%;
  color: ${({ theme }) => theme.textColor.icon};
  padding: 10.5px 5px;
`;

const Selection = styled.span`
  border-radius: ${({ theme }) => theme.borders.default.radius};
  border: ${({ theme }) => theme.borders.input};
  background-color: ${({ theme }) => theme.backgroundColor.element2};
  color: ${({ theme }) => theme.textColor.onDark};
  padding: 5px 5px;
  margin: -5px 0px;
`;

const StyledPopper = styled(Popper)`
  z-index: 99;
  width: 190px;
  border-radius: ${({ theme }) => theme.borders.default.radius};
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  box-shadow: ${({ theme }) => theme.shadows.widgetShadow};
  margin-top: 5px;

  .MuiAutomcomplete-root {
    background-color: ${({ theme }) => theme.backgroundColor.background2};
  }
`;

// TODO: replace with re-used checkbox in atoms (from ui package)
// TODO: add proper color: theme.backgroundColor.button.default
// re-using ui atom will take care of color
const StyledCheckbox = styled(Checkbox)`
  width: auto;
`;

const SearchDropdown: React.FC<Props> = ({
  id,
  placeholder,
  selectedValue,
  options,
  onChange,
  multiselect = true,
  disabled,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const optionsByValue = R.indexBy(R.prop('value'), options);
  const selectedOptions = selectedValue.map((value) => optionsByValue[value]);
  const popperId = open ? id : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const buttonContent = (): ReactNode => {
    if (selectedValue.length === 0) {
      return placeholder || 'Search and select';
    } else if (selectedValue.length === 1) {
      const selectedOption = options.find(
        (option) => option.value === selectedValue[0]
      );
      return (
        <Flex justifyContent="flex-start" alignItems="center">
          <Selection>{selectedOption?.label}</Selection>
        </Flex>
      );
    }

    return (
      <Flex justifyContent="flex-start" alignItems="center">
        <Selection>{selectedValue.length} selected</Selection>
      </Flex>
    );
  };

  return (
    <div>
      <ToggleButton
        type="button"
        aria-describedby={id}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonContent()}
      </ToggleButton>
      <StyledPopper
        id={popperId}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
      >
        <Autocomplete
          open
          onClose={handleClose}
          multiple={multiselect}
          options={options}
          value={selectedOptions}
          onChange={onChange}
          disableCloseOnSelect
          disablePortal
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderTags={() => null}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <StyledCheckbox checked={selected} color="primary" />
              {option.label}
            </li>
          )}
          slotProps={{
            paper: {
              sx: {
                marginTop: '5px',
                backgroundColor: theme.backgroundColor.background1,
                color: theme.textColor.text1,
                '&.MuiPaper-rounded': {
                  borderRadius: '5px',
                },
                '&.MuiPaper-elevation8': {
                  boxShadow: theme.shadows.widgetShadow,
                },
              },
            },
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input inputProps={params.inputProps} autoFocus />
            </div>
          )}
        />
      </StyledPopper>
    </div>
  );
};

export default SearchDropdown;
