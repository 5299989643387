import styled from '@emotion/styled';
import { Modal as MUIModal, ModalProps as OrigModalProps } from '@mui/material';

const StyledModal = styled(MUIModal)``;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

/* eslint-disable-next-line */
export interface ModalProps extends OrigModalProps {}

const Modal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <StyledModal {...props} disableBackdropClick>
      <ModalContent>{children}</ModalContent>
    </StyledModal>
  );
};

export default Modal;
