import * as R from 'ramda';

import { PLACEHOLDER_VALUE } from '../components/atoms/Dropdowns';
import {
  AdditionalFiltersData,
  AdditionalFilterSelection,
  DateRangeFilter,
  DateRangeFilterSelection,
  FilterTypes,
  SelectFilter,
  SelectFilterSelection,
} from '../types';

export const enumToOptions = <A extends Record<string, unknown>>(eenoom: A) =>
  R.map(
    ([label, value]) => ({
      label,
      value,
    }),
    R.toPairs(eenoom)
  );

export const selectFilter = ({
  id,
  label,
  options,
  name,
  defaultOption,
}: {
  id: SelectFilter['id'];
  label: SelectFilter['label'];
  defaultOption?: SelectFilter['defaultOption'];
  options: SelectFilter['options'];
  name: SelectFilter['name'];
}): SelectFilter => {
  return {
    _type: FilterTypes.SelectFilter,
    id,
    name,
    label,
    defaultOption,
    options,
  };
};

// ///// "Smart constructors" for their respective data types

export const dateRangeFilter = ({
  id,
  label,
  dateRangeOption,
  name,
}: Pick<
  DateRangeFilter,
  'id' | 'label' | 'dateRangeOption' | 'name'
>): DateRangeFilter => {
  return {
    _type: FilterTypes.DateRangeFilter,
    id,
    name,
    label,
    dateRangeOption,
  };
};

export const dateRangeFilterSelection = (
  args: DateRangeFilterSelection
): DateRangeFilterSelection => args;

export const singleSelection = (
  args: SelectFilterSelection
): SelectFilterSelection => args;

export const defaultSelection = (filter: SelectFilter<string>) => {
  if (!filter.defaultOption) {
    return {
      label: '',
      value: PLACEHOLDER_VALUE,
    };
  }

  return filter.defaultOption;
};

export const valueToOption = (value: string) => {
  return {
    label: value,
    value,
  };
};

export const additionalFilterCategoryLabel = (
  additionalFilterData: AdditionalFiltersData,
  filter: AdditionalFilterSelection
) => {
  return additionalFilterData[filter.categoryValue].label;
};

export const additionalFilterTypeLabel = (
  additionalFilterData: AdditionalFiltersData,
  filter: AdditionalFilterSelection
) => {
  return additionalFilterData[filter.categoryValue].types[filter.typeValue]
    .label;
};
