import React from 'react';
import { Flex, Box } from 'rebass';
import { useTheme } from '@emotion/react';

import { CompareToType, LineChartData, LineChartWidget } from '../../types';
import {
  byFieldToFormat,
  customizeTextFn,
  queryFieldToFormat,
} from '../../util/widget';
import { TrendLine } from '../atoms';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';
import Legend from './Legend';
import LineChart from './LineChart';
import ReportWidgetBase from './ReportWidgetBase';
import S from '../../util/strings';

interface ReportWidgetBarProps {
  widget: LineChartWidget;
  data?: LineChartData[];
  preview?: boolean;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const compareToLabel = (compareTo: CompareToType) => {
  const CTT = CompareToType;

  // TODO: add more here as necessary
  switch (compareTo) {
    case CTT.PriorPeriod:
      return 'Prior Period';
    default:
      return undefined;
  }
};

const legendItemsFrom = ({
  widget,
  colorPalette,
}: {
  widget: LineChartWidget;
  colorPalette: string[];
}) => {
  const valueMetricItem = {
    text: `${S.capitalize(widget.value)} ${S.capitalize(widget.valueMetric)}`, // TODO: would be good to use the label field from widgetDisplayData / widgetMainValueOptions...
    color: colorPalette[0],
  };

  const legendItems = [valueMetricItem];

  if (widget.compareTo) {
    const compareToItem = {
      text: compareToLabel(widget.compareTo) || '',
      color: colorPalette[1], // TODO: make more dynamic maybe?
    };

    legendItems.push(compareToItem);
  }

  return legendItems;
};

interface ReportWidgetFooterProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const ReportWidgetFooter: React.FC<ReportWidgetFooterProps> = ({
  left,
  right,
}) => {
  return (
    <Flex justifyContent="space-between">
      {left}
      {right}
    </Flex>
  );
};

const ReportWidgetBar: React.FC<ReportWidgetBarProps> = ({
  widget,
  data,
  preview,
  onEditWidgetSubmit,
}) => {
  const theme = useTheme();
  const colorPalette = Object.values(theme.widgetTheme[widget.theme]);

  const yLabelFn = customizeTextFn(widget.value);

  const leftFooterContent = () => {
    return <Legend items={legendItemsFrom({ widget, colorPalette })} />;
  };

  const rightFooterContent = () => {
    if (widget.compareTo === CompareToType.PriorPeriod) {
      // NOTE: Harcoding 'up' and 'diffPercent' for now but
      // this should obviously be dynamic
      const diffPercent = 5.2;
      const priorPeriodText = `${diffPercent}% from prior period`;
      return <TrendLine direction="up" text={priorPeriodText} />;
    }
    return undefined;
  };

  return (
    <ReportWidgetBase
      widget={widget}
      footerContent={
        <ReportWidgetFooter
          left={leftFooterContent()}
          right={rightFooterContent()}
        />
      }
      preview={preview}
      onEditWidgetSubmit={onEditWidgetSubmit}
    >
      <Box py={[3]}>
        {/* TODO: maybe add a skeleton if no data present? */}
        {data && (
          <LineChart
            tooltipTitle={widget.name}
            data={data}
            colorPalette={colorPalette}
            yAxisLabel={yLabelFn}
            yValueTooltipFormat={queryFieldToFormat[widget.value]}
            xValueTooltipFormat={byFieldToFormat[widget.secondaryValue]}
          />
        )}
      </Box>
    </ReportWidgetBase>
  );
};

export default ReportWidgetBar;
