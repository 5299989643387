/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';

import { NewLogoCOOPGrey as CoopLogoGrey } from '../../assets/images';
import { Sidebar } from '../organisms';

interface MainContentProps {
  mainContentBackground?: string;
}

interface Props extends MainContentProps {
  noSidebar?: boolean;
}

const MainContent = styled(Box)<MainContentProps>`
  background: ${({ theme, mainContentBackground }) =>
    mainContentBackground || theme.backgroundColor.background2};
`;

const LogoContainer = styled(Flex)`
  color: ${({ theme }) => theme.textColor.text3};
  cursor: pointer;
  #co,
  #op {
    fill: ${({ theme }) => theme.colors.grey4};
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  #outline {
    stroke: ${({ theme }) => theme.colors.grey4};
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover #co {
    fill: ${({ theme }) => theme.colors.white};
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover #outline {
    fill: #ed002f;
    stroke: #ed002f;
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover #op {
    fill: #9a000c;
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`;

const Footer = styled.footer<MainContentProps>`
  background: ${({ theme, mainContentBackground }) =>
    mainContentBackground || theme.backgroundColor.background2};
  color: ${({ theme }) => theme.textColor.text3};
`;

const Layout: React.FC<Props> = ({
  children,
  noSidebar,
  mainContentBackground,
}) => {
  return (
    <Flex>
      {!noSidebar && <Sidebar />}

      <Flex minHeight={['100vh']} flexDirection="column" flex="1">
        <MainContent flex={[1]} mainContentBackground={mainContentBackground}>
          <main>
            <Box
              maxWidth={['1300px']}
              margin={['0 auto']}
              pt={[5]}
              pb={[5]}
              px={[4, 5, 5, 5]}
            >
              {children}
            </Box>
          </main>
        </MainContent>

        <Footer mainContentBackground={mainContentBackground}>
          <Flex margin={['0 auto']} py={3} maxWidth="1365px" px={[3, 4]}>
            {/* <Box flex={['1']} alignSelf="center">
              <Caption1>
                Copyright CO-OP Financial Services 2020 - All Rights Reserved
              </Caption1>
            </Box> */}
            <LogoContainer
              flex={['1']}
              alignItems="center"
              justifyContent="center"
            >
              <CoopLogoGrey />
            </LogoContainer>

            {/* <Flex flex={['1']} justifyContent="flex-end" alignItems="center">
              <FooterLink to="#">
                <H5 fontWeight="700">Terms & Conditions</H5>
              </FooterLink>
              <Box mr={2} />
              <FooterLink to="#">
                <H5 fontWeight="700">Privacy Policy</H5>
              </FooterLink>
            </Flex> */}
          </Flex>
        </Footer>
      </Flex>
    </Flex>
  );
};
export default Layout;
