import React, { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { FormControl, FormGroup, FormLabel } from '@mui/material';

import CheckboxWithLabel from './CheckboxWithLabel';

export interface CheckboxItem {
  label: string;
  value: string;
  parentValue?: string;
}

type GroupValuesType = {
  [groupTitle: string]: {
    [rowTitle: string]: string[];
  };
};

type SetGroupValuesType = (
  value: GroupValuesType | ((prevValue: GroupValuesType) => GroupValuesType)
) => void;

// Define the type for the handleGroupChange function
type HandleGroupChange = (
  groupTitle: string,
  rowTitle: string,
  values: string[],
  isChecked: boolean
) => void;

interface CheckboxListProps {
  checkboxItems: CheckboxItem[];
  label?: string;
  title?: string;
  value: string[]; // Assuming this is an array of strings
  onChange: (newValues: string[]) => void; // Define the callback type
  includeAllOption?: boolean; // New optional prop
}

interface CheckboxRow {
  rowTitle: string;
  checkboxItems: CheckboxItem[];
}

export interface CheckboxGroup {
  groupTitle: string;
  rows: CheckboxRow[];
}

interface MultiCheckboxGroupProps {
  groups: CheckboxGroup[];
  title: string;
  groupValues: GroupValuesType;
  setGroupValues: SetGroupValuesType;
  handleGroupChange: HandleGroupChange;
}

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.textColor.text1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  font-weight: bold;
  padding-left: 7px;
  padding-top: 2px;
  cursor: pointer;

  &.Mui-focused {
    color: ${({ theme }) => theme.textColor.text1};
  }
`;

const FilterTitleContainer = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  cursor: pointer;
  user-select: none;
`;

const DropdownItemContainer = styled(motion.div)`
  padding-left: 20px;
  padding-top: 8px;
`;

const GroupContainer = styled(motion.div)``;

const GroupTitle = styled(Flex)`
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 10px 0;
`;

const StyledGroupTitle = styled(FormLabel)`
  color: ${({ theme }) => theme.textColor.text1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize20};
  padding-left: 6px;
  padding-top: 2px;
  cursor: pointer;

  &.Mui-focused {
    color: ${({ theme }) => theme.textColor.text1};
  }
`;

const RowTitle = styled.div`
  color: ${({ theme }) => theme.textColor.text3};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize10};
  padding: 6px 0;
`;

const CheckboxRowContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  min-width: 170px;
`;

const StyledCheckboxGroupContainer = styled(FormControl)`
  padding-left: 20px;
`;

const iconSize = {
  height: '14px',
  width: '14px',
};

export const MultiselectCheckboxListBase: React.FC<CheckboxListProps> = ({
  checkboxItems,
  value,
  includeAllOption = false, // Default to false if not provided
  onChange,
  ...rest
}) => {
  const selectedValues = Array.isArray(value) ? value : [];

  const isAllSelected = selectedValues.length === checkboxItems.length;

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    itemValue: string
  ) => {
    let updatedSelectedValues: string[];
    if (itemValue === 'all') {
      updatedSelectedValues = event.target.checked
        ? checkboxItems.map((item) => item.value)
        : [];
    } else if (event.target.checked) {
      updatedSelectedValues = [...selectedValues, itemValue];
    } else {
      updatedSelectedValues = selectedValues.filter(
        (currentValue) => currentValue !== itemValue
      );
    }
    onChange(updatedSelectedValues);
  };

  return (
    <FormControl {...rest}>
      <DropdownItemContainer>
        {includeAllOption && (
          <CheckboxWithLabel
            color="primary"
            checked={isAllSelected}
            indeterminate={selectedValues.length > 0 && !isAllSelected}
            onChange={(event) => handleCheckboxChange(event, 'all')}
            label="All"
          />
        )}
        <FormGroup>
          {checkboxItems.map((item) => (
            <Box key={item.value} pl={item.parentValue ? 4 : 0}>
              <CheckboxWithLabel
                color="primary"
                checked={selectedValues.includes(item.value)}
                onChange={(event) => handleCheckboxChange(event, item.value)}
                label={item.label}
              />
            </Box>
          ))}
        </FormGroup>
      </DropdownItemContainer>
    </FormControl>
  );
};

export const MultiselectCheckboxList: React.FC<CheckboxListProps> = ({
  checkboxItems,
  label,
  value,
  includeAllOption = false, // Default to false if not provided
  onChange,
  ...rest
}) => {
  const selectedValues = Array.isArray(value) ? value : [];

  const isAllSelected = selectedValues.length === checkboxItems.length;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    itemValue: string
  ) => {
    let updatedSelectedValues: string[];
    if (itemValue === 'all') {
      updatedSelectedValues = event.target.checked
        ? checkboxItems.map((item) => item.value)
        : [];
    } else if (event.target.checked) {
      updatedSelectedValues = [...selectedValues, itemValue];
    } else {
      updatedSelectedValues = selectedValues.filter(
        (currentValue) => currentValue !== itemValue
      );
    }
    onChange(updatedSelectedValues);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <FormControl {...rest}>
      <FilterTitleContainer onClick={toggleExpansion}>
        {isExpanded ? (
          <RemoveCircle color="primary" sx={iconSize} />
        ) : (
          <AddCircle color="primary" sx={iconSize} />
        )}

        <StyledFormLabel>{label}</StyledFormLabel>
      </FilterTitleContainer>
      <AnimatePresence>
        {isExpanded && (
          <DropdownItemContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {includeAllOption && (
              <CheckboxWithLabel
                color="primary"
                checked={isAllSelected}
                indeterminate={selectedValues.length > 0 && !isAllSelected}
                onChange={(event) => handleCheckboxChange(event, 'all')}
                label="All"
              />
            )}
            <FormGroup>
              {checkboxItems.map((item) => (
                <Box key={item.value} pl={item.parentValue ? 4 : 0}>
                  <CheckboxWithLabel
                    color="primary"
                    checked={selectedValues.includes(item.value)}
                    onChange={(event) =>
                      handleCheckboxChange(event, item.value)
                    }
                    label={item.label}
                  />
                </Box>
              ))}
            </FormGroup>
          </DropdownItemContainer>
        )}
      </AnimatePresence>
    </FormControl>
  );
};

export const MultiCheckboxGroup: React.FC<MultiCheckboxGroupProps> = ({
  groups,
  title,
  handleGroupChange,
  groupValues,
  setGroupValues,
}) => {
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

  const toggleExpansion = (groupTitle: string) => {
    setExpandedGroups((prev) => {
      const newExpandedGroups = new Set(prev);
      if (newExpandedGroups.has(groupTitle)) {
        newExpandedGroups.delete(groupTitle);
      } else {
        newExpandedGroups.add(groupTitle);
      }
      return newExpandedGroups;
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleTitleExpansion = () => setIsExpanded(!isExpanded);

  const handleRowAllChange = (
    groupTitle: string,
    rowTitle: string,
    isChecked: boolean
  ) => {
    const checkboxItems =
      groups
        .find((group) => group.groupTitle === groupTitle)
        ?.rows.find((row) => row.rowTitle === rowTitle)?.checkboxItems || [];

    // Update each checkbox based on 'isChecked'
    checkboxItems.forEach((item) => {
      handleGroupChange(groupTitle, rowTitle, [item.value], isChecked);
    });
  };

  return (
    <FormControl>
      <FilterTitleContainer onClick={toggleTitleExpansion}>
        {isExpanded ? (
          <RemoveCircle color="primary" sx={iconSize} />
        ) : (
          <AddCircle color="primary" sx={iconSize} />
        )}
        <StyledFormLabel>{title}</StyledFormLabel>
      </FilterTitleContainer>
      <AnimatePresence>
        {isExpanded && (
          <StyledCheckboxGroupContainer>
            {groups.map((group) => (
              <GroupContainer
                key={group.groupTitle}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <GroupTitle onClick={() => toggleExpansion(group.groupTitle)}>
                  {expandedGroups.has(group.groupTitle) ? (
                    <RemoveCircle color="primary" sx={iconSize} />
                  ) : (
                    <AddCircle color="primary" sx={iconSize} />
                  )}
                  <StyledGroupTitle>{group.groupTitle}</StyledGroupTitle>
                </GroupTitle>
                <Flex>
                  {expandedGroups.has(group.groupTitle) &&
                    group.rows.map((row) => (
                      <CheckboxRowContainer
                        key={row.rowTitle}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                      >
                        <RowTitle>{row.rowTitle}</RowTitle>
                        <FormGroup>
                          <CheckboxWithLabel
                            color="primary"
                            checked={row.checkboxItems.every((item) =>
                              groupValues[group.groupTitle]?.[
                                row.rowTitle
                              ]?.includes(item.value)
                            )}
                            indeterminate={
                              row.checkboxItems.some((item) =>
                                groupValues[group.groupTitle]?.[
                                  row.rowTitle
                                ]?.includes(item.value)
                              ) &&
                              !row.checkboxItems.every((item) =>
                                groupValues[group.groupTitle]?.[
                                  row.rowTitle
                                ]?.includes(item.value)
                              )
                            }
                            onChange={(e) =>
                              handleRowAllChange(
                                group.groupTitle,
                                row.rowTitle,
                                e.target.checked
                              )
                            }
                            label="All"
                          />

                          {row.checkboxItems.map((item) => (
                            <CheckboxWithLabel
                              key={item.value}
                              color="primary"
                              checked={(
                                groupValues[group.groupTitle][row.rowTitle] ||
                                []
                              ).includes(item.value)}
                              onChange={(e) =>
                                handleGroupChange(
                                  group.groupTitle,
                                  row.rowTitle,
                                  [item.value],
                                  e.target.checked
                                )
                              }
                              label={item.label}
                            />
                          ))}
                        </FormGroup>
                      </CheckboxRowContainer>
                    ))}
                </Flex>
              </GroupContainer>
            ))}
          </StyledCheckboxGroupContainer>
        )}
      </AnimatePresence>
    </FormControl>
  );
};
