import React, { useState } from 'react';
import { HiDotsVertical, HiPencil } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { paths } from '../../routing/paths';
import { Widget, WidgetSize } from '../../types';
import {
  Card,
  CardBody,
  CardFooter,
  DropdownBase,
  H4,
  IconButton,
  MenuItem,
  Tag,
} from '../atoms';

import { EditWidgetModal } from '../organisms';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';

interface ReportWidgetBaseProps {
  footerContent?: React.ReactNode;
  contentNoPadding?: boolean;
  preview?: boolean;
  widget: Widget;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const GraphContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const TableContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 10;
`;

const WidgetCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  cursor: default;
`;

const WidgetCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WidgetHeader = styled(Flex)`
  align-items: center;
  cursor: grab;
  :active {
    cursor: grabbing;
  }
`;

const WidgetFooter = styled(CardFooter)`
  border-bottom-left-radius: ${({ theme }) => theme.borders.default.radius};
  border-bottom-right-radius: ${({ theme }) => theme.borders.default.radius};
`;

const ReportWidgetBase: React.FC<ReportWidgetBaseProps> = ({
  children,
  footerContent,
  contentNoPadding,
  preview,
  widget,
  onEditWidgetSubmit,
}) => {
  const theme = useTheme();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const filterAppliedTag = widget.filters?.length ? (
    <Box ml={1}>
      <Tag>Filters Applied</Tag>
    </Box>
  ) : null;

  return (
    <WidgetCard>
      <WidgetCardBody>
        <WidgetHeader>
          <Text color={theme.widgetTheme[widget.theme].main} flex={[1]}>
            <H4>{widget.name}</H4>
          </Text>

          {widget.size !== WidgetSize.Small && filterAppliedTag}

          {!preview && (
            <>
              <DropdownBase
                renderItems={(closeMenu) => [
                  <MenuItem key="clone" onClick={closeMenu}>
                    Clone Widget
                  </MenuItem>,
                  <MenuItem key="add" onClick={closeMenu}>
                    Add to different report
                  </MenuItem>,
                  <MenuItem
                    key="view"
                    onClick={closeMenu}
                    component={Link}
                    to={paths.widgetData(widget.id)}
                  >
                    View data
                  </MenuItem>,
                  <MenuItem key="image" onClick={closeMenu}>
                    Save as image
                  </MenuItem>,
                  <MenuItem key="dl" onClick={closeMenu}>
                    Download CSV
                  </MenuItem>,
                  <MenuItem key="del" onClick={closeMenu}>
                    Delete
                  </MenuItem>,
                ]}
                renderComponent={(onClick, open) => (
                  <IconButton
                    icon={HiDotsVertical}
                    aria-controls="Widget actions"
                    onClick={onClick}
                    active={open}
                    variant="secondary"
                    size="small"
                  />
                )}
              />

              <IconButton
                icon={HiPencil}
                variant="secondary"
                active={false}
                size="small"
                onClick={() => setEditModalOpen(true)}
              />
            </>
          )}
        </WidgetHeader>
        <Box mb={[2]} />
        {!contentNoPadding && (
          <GraphContent justifySelf="center">{children}</GraphContent>
        )}
      </WidgetCardBody>

      {contentNoPadding && <TableContent>{children}</TableContent>}

      {footerContent && (
        <WidgetFooter hasBackground>
          <Flex flexWrap="wrap">
            <Box flex={['1 0 auto']}>{footerContent}</Box>

            {widget.size === WidgetSize.Small && filterAppliedTag}
          </Flex>
        </WidgetFooter>
      )}

      <EditWidgetModal
        widget={widget}
        open={editModalOpen}
        onSubmit={onEditWidgetSubmit}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="Edit widget modal"
        aria-describedby="Modal for editing the widget settings"
      />
    </WidgetCard>
  );
};

export default ReportWidgetBase;
