import React from 'react';
import { Box } from 'rebass';

import { useTheme } from '@emotion/react';

import { BarChartData, BarChartWidget } from '../../types';
import { customizeTextFn } from '../../util/widget';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';
import HorizontalBarChart from './HorizontalBarChart';
import Legend from './Legend';
import ReportWidgetBase from './ReportWidgetBase';

interface Props {
  widget: BarChartWidget;
  data?: BarChartData;
  preview?: boolean;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const ReportWidgetBar: React.FC<Props> = ({
  widget,
  data,
  preview,
  onEditWidgetSubmit,
}) => {
  const theme = useTheme();
  const colorPalette = Object.values(theme.widgetTheme[widget.theme]);

  const legendItems = data?.bars.map((bar, idx) => ({
    text: bar.name,
    color: colorPalette[idx],
  }));

  const labelFn = customizeTextFn(widget.value);

  return (
    <ReportWidgetBase
      widget={widget}
      footerContent={legendItems && <Legend items={legendItems} />}
      preview={preview}
      onEditWidgetSubmit={onEditWidgetSubmit}
    >
      <Box py={[3]}>
        {/* TODO: maybe add a skeleton if no data present? */}
        {data && (
          <HorizontalBarChart
            data={data}
            colorPalette={colorPalette}
            xAxisLabel={labelFn}
            pointLabel={labelFn}
          />
        )}
      </Box>
    </ReportWidgetBase>
  );
};

export default ReportWidgetBar;
