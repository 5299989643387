import * as React from 'react';
import { Box, Flex } from 'rebass';
import Layout from '../../templates/Layout';

const Notifications: React.FC = () => {
  return (
    <Layout>
      <Flex>
        <Box ml={5} />
      </Flex>
    </Layout>
  );
};

export default Notifications;
