import * as R from 'ramda';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, FlexProps } from 'rebass';

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';

import { dummyUsers } from '../../../../data/users/dummyUserData';
import {
  RepeatEveryMonthOnOptions,
  RepeatEveryPeriodOptions,
  RepeatEveryWeekOnOptions,
  RepeatOptions,
  ScheduledReport,
  User,
} from '../../../../types';
import S from '../../../../util/strings';
import {
  Button,
  Dropdown,
  ErrorTextWithContainer,
  Input,
  MenuItem,
  Paragraph,
  RadioButton,
  TimePicker,
} from '../../../atoms';
import { CustomDatePickerBase as DatePicker } from '../../../molecules';
import AutoCompleteContainer from '../AutoCompleteContainer';
import EmailToAutocomplete from '../EmailToAutoComplete';
import { scheduleReportModalValidation } from './scheduleReportModalValidation';
import { ScheduleReportFormValues } from './types';

const repeatOptionTitleFor = (option: RepeatOptions) => {
  const RO = RepeatOptions;

  const text = {
    [RO.DoesNotRepeat]: 'Does not repeat',
    [RO.Daily]: 'Daily',
    [RO.WeeklyOnMonday]: 'Weekly on Monday',
    [RO.MonthlyOnTheFirstMonday]: 'Monthly on the first Monday',
    [RO.AnnuallyOnArbitraryDate]: 'Annually on April 5',
    [RO.EveryWeekday]: 'Every weekday (Monday to Friday)',
    [RO.Custom]: 'Custom',
  };
  return text[option];
};

const repeatEveryPeriodTitleFor = (
  period: RepeatEveryPeriodOptions,
  num = 0
) => {
  const base = S.lowerCase(period);
  return num > 1 ? `${base}s` : base;
};

const repeatEveryMonthOnTitleFor = (day: RepeatEveryMonthOnOptions) => {
  const RO = RepeatEveryMonthOnOptions;

  const text = {
    [RO.MonthlyOnArbitraryDay]: 'Monthly on day 5',
    [RO.MonthlyOnTheFirstMonday]: 'Monthly on the first Monday',
  };

  return text[day];
};

const Weekdays = RepeatEveryWeekOnOptions;

const repeatEveryWeekOnOptionTitles = {
  [Weekdays.Sunday]: 'S',
  [Weekdays.Monday]: 'M',
  [Weekdays.Tuesday]: 'T',
  [Weekdays.Wednesday]: 'W',
  [Weekdays.Thursday]: 'T',
  [Weekdays.Friday]: 'F',
  [Weekdays.Saturday]: 'S',
};

const repeatEveryWeekOnTitleFor = (weekDay: RepeatEveryWeekOnOptions) => {
  const text = repeatEveryWeekOnOptionTitles;
  return text[weekDay];
};

const fullWidth = () => 'width: 100%';

export const LargeInput = styled(Input)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  ${fullWidth}
`;

export const LargeDropdown = styled(Dropdown)`
  ${fullWidth}
`;

interface LabelledFieldProps extends FlexProps {
  labelFor: string;
  label: string;
}

const LabelledField: React.FC<LabelledFieldProps> = ({
  labelFor,
  label,
  children,
  ...props
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Box width={1 / 4}>
        <label htmlFor={labelFor}>
          <Paragraph>{label}</Paragraph>
        </label>
      </Box>

      <Box width={3 / 4}>{children}</Box>
    </Flex>
  );
};

const allUsers = R.values(dummyUsers);

interface ScheduleReportProps {
  onSubmit: (data: ScheduleReportFormValues) => void;
  onCancel: () => void;
  existingReport?: ScheduledReport;
}

const ScheduleReport: React.FC<ScheduleReportProps> = ({
  existingReport,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(() => {
    if (existingReport) {
      return existingReport;
    }

    return {
      title: '',
      emailTo: [],
      startDate: new Date(),
      startTime: new Date(),
      repeat: RepeatOptions.DoesNotRepeat,
      repeatEveryNumber: 1,
      repeatEveryPeriod: RepeatEveryPeriodOptions.Day,
      repeatEveryWeekOn: RepeatEveryWeekOnOptions.Sunday,
      repeatEveryMonthOn: RepeatEveryMonthOnOptions.MonthlyOnArbitraryDay,
    };
  }, [existingReport]);

  const methods = useForm<ScheduleReportFormValues>({
    resolver: yupResolver(scheduleReportModalValidation),
    defaultValues,
  });

  const { handleSubmit, setValue, control, watch } = methods;

  const repeat = watch('repeat');
  const repeatEveryNumber = watch('repeatEveryNumber');
  const repeatEveryPeriod = watch('repeatEveryPeriod');

  useEffect(() => {
    if (repeat === RepeatOptions.Custom) {
      const defaults = R.pick(
        [
          'repeatEveryNumber',
          'repeatEveryPeriod',
          'repeatEveryWeekOn',
          'repeatEveryMonthOn',
        ],
        defaultValues
      );

      // NOTE: for some reason reset() doesn't work here...
      setValue('repeatEveryNumber', defaults.repeatEveryNumber);
      setValue('repeatEveryPeriod', defaults.repeatEveryPeriod);
      setValue('repeatEveryWeekOn', defaults.repeatEveryWeekOn);
      setValue('repeatEveryMonthOn', defaults.repeatEveryMonthOn);
    }
  }, [repeat, setValue, defaultValues]);

  const handleEmailToChanged = (_event: unknown, newUsers: User[]) => {
    setValue('emailTo', newUsers, { shouldValidate: true });
  };

  const renderCustomRepeatOptions = () => {
    return (
      <>
        <Box py={2} />
        <Paragraph as="h1">Custom Recurrence</Paragraph>
        <Box py={2} />

        <LabelledField
          labelFor="repeatCustomFields"
          label="Repeat Every"
          my={3}
        >
          <Flex>
            <Box mr={2}>
              <Controller
                name="repeatEveryNumber"
                control={control}
                render={({
                  field: { ...fieldProps },
                  fieldState: { error },
                }) => (
                  <>
                    <LargeInput
                      type="number"
                      id="repeatEveryNumber"
                      {...fieldProps}
                    />
                    {error && (
                      <ErrorTextWithContainer>
                        {error.message}
                      </ErrorTextWithContainer>
                    )}
                  </>
                )}
              />
            </Box>

            <Box>
              <Controller
                name="repeatEveryPeriod"
                control={control}
                render={({
                  field: { ...fieldProps },
                  fieldState: { error },
                }) => (
                  <>
                    <Dropdown id="repeatEveryPeriod" {...fieldProps}>
                      {R.values(RepeatEveryPeriodOptions).map((period) => (
                        <MenuItem value={period} key={period}>
                          {repeatEveryPeriodTitleFor(period, repeatEveryNumber)}
                        </MenuItem>
                      ))}
                    </Dropdown>
                    {error && (
                      <ErrorTextWithContainer>
                        {error.message}
                      </ErrorTextWithContainer>
                    )}
                  </>
                )}
              />
            </Box>
          </Flex>
        </LabelledField>

        {repeatEveryPeriod === RepeatEveryPeriodOptions.Week && (
          <LabelledField labelFor="repeatEveryWeekOn" label="Repeat On" mb={4}>
            <Controller
              name="repeatEveryWeekOn"
              control={control}
              render={({ field: { value, ...fieldProps } }) => (
                <Flex justifyContent="space-between">
                  {R.values(RepeatEveryWeekOnOptions).map((day) => (
                    <RadioButton
                      key={day}
                      label={repeatEveryWeekOnTitleFor(day)}
                      id={day}
                      value={day}
                      checked={day === value}
                      {...fieldProps}
                    />
                  ))}
                </Flex>
              )}
            />
          </LabelledField>
        )}

        {repeatEveryPeriod === RepeatEveryPeriodOptions.Month && (
          <LabelledField
            labelFor="repeatEveryMonthOn"
            label="Repeat Day"
            mb={4}
          >
            <Box>
              <Controller
                name="repeatEveryMonthOn"
                control={control}
                render={({ field: { ...fieldProps } }) => (
                  <Dropdown id="repeatEveryMonthOn" {...fieldProps}>
                    {R.values(RepeatEveryMonthOnOptions).map((period) => (
                      <MenuItem value={period} key={period}>
                        {repeatEveryMonthOnTitleFor(period)}
                      </MenuItem>
                    ))}
                  </Dropdown>
                )}
              />
            </Box>
          </LabelledField>
        )}
      </>
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <LabelledField labelFor="title" label="Schedule Name" mb={3}>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <LargeInput
                    type="text"
                    id="title"
                    placeholder="Enter a name"
                    autoComplete="off"
                    defaultValue={existingReport?.title}
                    {...field}
                  />

                  {error && (
                    <ErrorTextWithContainer>
                      {error.message}
                    </ErrorTextWithContainer>
                  )}
                </>
              )}
            />
          </LabelledField>

          <LabelledField
            labelFor="emailTo"
            label="Email to..."
            autoComplete="off"
            type="text"
            name="users"
          >
            <Controller
              name="emailTo"
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <>
                  <AutoCompleteContainer width={1} pb={2}>
                    <EmailToAutocomplete
                      chosenUsers={value}
                      users={allUsers}
                      onChange={handleEmailToChanged}
                    />
                  </AutoCompleteContainer>

                  {error && (
                    <ErrorTextWithContainer>
                      {error.message}
                    </ErrorTextWithContainer>
                  )}
                </>
              )}
            />
          </LabelledField>

          <LabelledField labelFor="" label="Start" mb={2}>
            <Flex justifyContent="space-between" alignItems="center">
              <Box mr={1}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { ...fieldProps } }) => (
                    <DatePicker {...fieldProps} />
                  )}
                />
              </Box>
              <Box ml={1} mt={2}>
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field: { ...fieldProps } }) => (
                    <TimePicker {...fieldProps} />
                  )}
                />
              </Box>
            </Flex>
          </LabelledField>

          <LabelledField labelFor="repeat" label="Repeat" mb={3}>
            <Controller
              name="repeat"
              control={control}
              render={({ field: { ...fieldProps } }) => (
                <LargeDropdown id="repeat" {...fieldProps}>
                  {R.values(RepeatOptions).map((option) => (
                    <MenuItem value={option} key={option}>
                      {repeatOptionTitleFor(option)}
                    </MenuItem>
                  ))}
                </LargeDropdown>
              )}
            />
          </LabelledField>

          {repeat === RepeatOptions.Custom && renderCustomRepeatOptions()}

          <Flex justifyContent="flex-end">
            <Box mr={3}>
              <Button variant="text" onClick={() => onCancel()}>
                Cancel
              </Button>
            </Box>

            <Box>
              <Button type="submit">
                {existingReport ? 'Update Schedule' : 'Create Schedule'}
              </Button>
            </Box>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};

export default ScheduleReport;
