import styled from '@emotion/styled';

interface Props {
  fontWeight?: string;
}

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.h1};
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.h2};
`;

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.h3};
  line-height: 1.875rem;
`;

export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.h4};
`;

export const H5 = styled.h5<Props>`
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: ${({ theme }) => theme.fontSize.h5};
`;

export const H6 = styled.h6<Props>`
  font-weight: ${({ fontWeight }) => fontWeight || '700'};
  font-size: ${({ theme }) => theme.fontSize.h6};
`;
