import * as R from 'ramda';

import { SelectFilter } from '../../types';
import { changeKeys } from '../../util/collections';
import {
  enumToOptions,
  selectFilter,
  valueToOption,
} from '../../util/filtersData';
import { byId } from '../../util/ids';

export enum CardTypeOptions {
  // All = 'All',
  Debit = 'Debit',
  Credit = 'Credit',
}

export enum SegmentationTypes {
  Debit = 'Debit',
  Credit = 'Credit',
  Holistic = 'Holistic',
}

export enum SegmentationSubtypes {
  Behavioral = 'Behavioral',
  BehavioralDebit = 'BehavioralDebit',
  Demographic = 'Demographic',
  Longevity = 'Longevity',
  Geographical = 'Geographical',
}

export enum NetworkOptions {
  // All = 'All',
  Visa = 'Visa',
  Mastercard = 'MasterCard',
  Discover = 'Discover',
  Interlink = 'Interlink',
  Maestro = 'Maestro',
  Pulse = 'Pulse',
  Star = 'Star',
  Nyce = 'Nyce',
  AccelExchange = 'AccelExchange',
  Presto = 'Presto',
  Affn = 'Affn',
  Jeanie = 'Jeanie',
  Shazam = 'Shazam',
  Coop = 'Coop',
  UnionPay = 'UnionPay',
  AlaskaOptions = 'AlaskaOptions',
}

// Using this mapping to "proper" labels since the enum keys/values
// should be equal to one another

const displayableNetworkOptions = changeKeys(NetworkOptions, {
  [NetworkOptions.AccelExchange]: 'Accel/Exchange',
  [NetworkOptions.Pulse]: 'PULSE',
  [NetworkOptions.Affn]: 'AFFN',
  [NetworkOptions.Jeanie]: 'JEANIE',
  [NetworkOptions.Coop]: 'COOP',
});

export enum CreditNetworkOptions {
  All = 'All',
  Visa = 'Visa',
  Mastercard = 'MasterCard',
  Discover = 'Discover',
  Pulse = 'Pulse',
  Star = 'Star',
  Nyce = 'Nyce',
  AccelExchange = 'AccelExchange',
  Presto = 'Presto',
  Affn = 'Affn',
  Jeanie = 'Jeanie',
  Shazam = 'Shazam',
  Coop = 'Coop',
  UnionPay = 'UnionPay',
  AlaskaOptions = 'AlaskaOptions',
}

const displayableCreditNetworkOptions = changeKeys(CreditNetworkOptions, {
  [NetworkOptions.AccelExchange]: 'Accel/Exchange',
  [NetworkOptions.Pulse]: 'PULSE',
  [NetworkOptions.Affn]: 'AFFN',
  [NetworkOptions.Jeanie]: 'JEANIE',
  [NetworkOptions.Coop]: 'COOP',
});

export enum ActivityTypeOptions {
  POS = 'POS',
  ATM = 'ATM',
  P2P = 'P2P',
}

export enum ATMNetworkOptions {
  Plus = 'Plus',
  Cu24 = 'Cu24',
  Cirrus = 'Cirrus',
  MoneyPass = 'MoneyPass',
  CuHere = 'CuHere',
}

const displayableATMNetworkOptions = changeKeys(ATMNetworkOptions, {
  [ATMNetworkOptions.Cu24]: 'CU24',
  [ATMNetworkOptions.CuHere]: 'CUHere',
});

export enum TransactionTypeOptions {
  All = 'All',
  OnUs = 'On Us',
  Foreign = 'Foreign',
}
const transactionTypeOptionsLabels = changeKeys(TransactionTypeOptions, {
  [TransactionTypeOptions.All]: 'All',
  [TransactionTypeOptions.OnUs]: 'On Us',
  [TransactionTypeOptions.Foreign]: 'Foreign',
});

export enum TransactionTypePosOptions {
  CardPresent = 'CardPresent',
  CardNotPresent = 'CardNotPresent',
  Recurring = 'Recurring',
}

const transactionTypePosOptionsLabels: Record<
  TransactionTypePosOptions,
  string
> = {
  [TransactionTypePosOptions.CardPresent]: 'Card Present',
  [TransactionTypePosOptions.CardNotPresent]: 'Card Not Present',
  [TransactionTypePosOptions.Recurring]: 'Recurring',
};

enum CardPresentOptions {
  DigitalWallet = 'DigitalWallet',
  Chip = 'Chip',
  MagStripe = 'MagStripe',
  ManualKeyEntry = 'ManualKeyEntry',
}

const cardPresentOptionsLabels: Record<CardPresentOptions, string> = {
  [CardPresentOptions.DigitalWallet]: 'Digital Wallet',
  [CardPresentOptions.Chip]: 'Chip',
  [CardPresentOptions.MagStripe]: 'Mag Stripe',
  [CardPresentOptions.ManualKeyEntry]: 'Manual Key Entry',
};

export enum ATMFleetOptions {
  All = 'All',
  ATM1 = 'ATM1',
  ATM2 = 'ATM2',
}

export enum SegmentationFilters {
  ActivityTypeFilter = 'ActivityTypeFilter',
  CardTypeFilter = 'CardTypeFilter',
  NetworkFilter = 'NetworkFilter',
  CreditNetworkFilter = 'CreditNetworkFilter',
  TransactionTypeFilter = 'TransactionTypeFilter',
  ATMNetworkFilter = 'ATMNetworkFilter',
  ATMFleetFilter = 'ATMFleetFilter',
}

const SF = SegmentationFilters;

const activityTypeFilter = selectFilter({
  id: '222001',
  label: 'Activity Type',
  name: SF.ActivityTypeFilter,
  defaultOption: valueToOption(ActivityTypeOptions.POS),
  options: enumToOptions(ActivityTypeOptions),
});

const cardTypeFilter = selectFilter({
  id: '222002',
  name: SF.CardTypeFilter,
  label: 'Card Type',
  defaultOption: valueToOption(CardTypeOptions.All),
  options: enumToOptions(CardTypeOptions),
});

const atmNetworkFilter = selectFilter({
  id: '222004',
  label: 'ATM Network',
  name: SF.ATMNetworkFilter,
  defaultOption: valueToOption(ATMNetworkOptions.Plus),
  options: enumToOptions(displayableATMNetworkOptions),
});

const atmFleetFilter = selectFilter({
  id: '222005',
  label: 'ATM Fleet',
  name: SF.ATMFleetFilter,
  defaultOption: valueToOption(ATMFleetOptions.All),
  options: enumToOptions(ATMFleetOptions),
});

const transactionTypeFilter = selectFilter({
  id: '222006',
  label: 'Transaction Type',
  name: SF.TransactionTypeFilter,
  defaultOption: valueToOption(TransactionTypeOptions.All),
  options: Object.entries(transactionTypeOptionsLabels).map(
    ([value, label]) => ({
      label,
      value,
    })
  ),
});

export const transactionTypePosFilter = selectFilter({
  id: '222007',
  label: 'Transaction Type',
  name: SF.TransactionTypeFilter,
  defaultOption: {
    ...valueToOption(transactionTypePosOptionsLabels.CardPresent),
    isParent: true,
  },
  options: [
    {
      ...valueToOption(transactionTypePosOptionsLabels.CardPresent),
      isParent: true,
    },
    ...Object.entries(cardPresentOptionsLabels).map(([value, label]) => ({
      label,
      value,
      parentValue: transactionTypePosOptionsLabels.CardPresent,
    })),
    valueToOption(transactionTypePosOptionsLabels.CardNotPresent),
    valueToOption(transactionTypePosOptionsLabels.Recurring),
  ],
});

const creditNetworkFilter = selectFilter({
  id: '222008',
  label: 'Network',
  name: SF.CreditNetworkFilter,
  defaultOption: valueToOption(CreditNetworkOptions.All),
  options: enumToOptions(displayableCreditNetworkOptions),
});

const networkFilter = selectFilter({
  id: '222009',
  label: 'Network',
  name: SF.NetworkFilter,
  defaultOption: valueToOption(NetworkOptions.All),
  options: enumToOptions(displayableNetworkOptions),
});

// sub filters
export enum BehavioralFilters {
  SpendFrequency = 'SpendFrequency',
  TripSegment = 'TripSegment',
  Transactors = 'Transactors',
  Revolvers = 'Revolvers',
  Inactive = 'Inactive',
  Paydowns = 'Paydowns',
}

export enum BehavioralDebitFilters {
  SpendFrequency = 'SpendFrequency',
}

export enum TransactorsOptions {
  NeedsValues = 'NeedsValues',
}

export enum SpendFrequencyOptions {
  NeedsValues = 'NeedsValues',
}

export enum TripSegmentOptions {
  Transactor = 'Transactor',
  Revolver = 'Revolver',
  Inactive = 'Inactive',
  Paydowns = 'Paydowns',
}

export enum DemographicFilters {
  Age = 'Age',
  Gender = 'Gender',
}

export enum AgeOptions {
  Custom = 'Custom',
  EighteenToTwentyFour = 'EighteenToTwentyFour',
  TwentyFiveToThirtyFour = 'TwentyFiveToThirtyFour',
  ThirtyFiveToFortyFour = 'ThirtyFiveToFortyFour',
  FortyFiveToFiftyFour = 'FortyFiveToFiftyFour',
  FiftyFiveToSixtyFour = 'FiftyFiveToSixtyFour',
  SixtyFiveToSeventyFour = 'SixtyFiveToSeventyFour',
  SeventyFiveOrOlder = 'SeventyFiveOrOlder',
}

const displayableAgeOptions = changeKeys(AgeOptions, {
  Custom: 'Custom',
  EighteenToTwentyFour: '18-24',
  TwentyFiveToThirtyFour: '25-34',
  ThirtyFiveToFortyFour: '35-44',
  FortyFiveToFiftyFour: '45-54',
  FiftyFiveToSixtyFour: '55-64',
  SixtyFiveToSeventyFour: '65-74',
  SeventyFiveOrOlder: '75 years or older',
});

export enum GenderOptions {
  NeedsValues = 'NeedsValues',
}

export enum LongevityFilters {
  MemberType = 'MemberType',
  Churn = 'Churn',
}

export enum MemberTypeOptions {
  Champion = 'Champion',
  Loyal = 'Loyal',
  Stagnant = 'Stagnant',
  Stalled = 'Stalled',
}

export enum ChurnOptions {
  NeedsValues = 'NeedsValues',
}

export enum GeographicalFilters {
  Region = 'Region',
  State = 'State',
  City = 'City',
}

export enum RegionOptions {
  NeedsValues = 'NeedsValues',
}

export enum StateOptions {
  NeedsValues = 'NeedsValues',
}

export enum CityOptions {
  NeedsValues = 'NeedsValues',
}

export enum PromotionsFilters {
  ActivePromotions = 'ActivePromotions',
  PastPromotions = 'PastPromotions',
}

export enum ActivePromotionsOptions {
  NeedsValues = 'NeedsValues',
}

export enum PastPromotionsOptions {
  NeedsValues = 'NeedsValues',
}

export const age = selectFilter({
  id: '222012',
  label: 'Age',
  name: DemographicFilters.Age,
  options: enumToOptions(displayableAgeOptions),
});

const gender = selectFilter({
  id: '222013',
  label: 'Gender',
  name: DemographicFilters.Gender,
  options: enumToOptions(GenderOptions),
});

const memberType = selectFilter({
  id: '222014',
  label: 'Member Type',
  name: LongevityFilters.MemberType,
  options: enumToOptions(MemberTypeOptions),
});

const churn = selectFilter({
  id: '222016',
  label: 'Churn',
  name: LongevityFilters.Churn,
  options: enumToOptions(ChurnOptions),
});

const region = selectFilter({
  id: '222017',
  label: 'Region',
  name: GeographicalFilters.Region,
  options: enumToOptions(RegionOptions),
});

const state = selectFilter({
  id: '222018',
  label: 'State',
  name: GeographicalFilters.State,
  options: enumToOptions(StateOptions),
});

const city = selectFilter({
  id: '222019',
  label: 'City',
  name: GeographicalFilters.City,
  options: enumToOptions(CityOptions),
});

const spendFrequency = selectFilter({
  id: '222021',
  label: 'Spend Frequency',
  name: BehavioralFilters.SpendFrequency,
  options: enumToOptions(SpendFrequencyOptions),
});

const tripSegment = selectFilter({
  id: '222022',
  label: 'TRIP Segments',
  name: BehavioralFilters.TripSegment,
  options: enumToOptions(TripSegmentOptions),
});

// TODO: this could be a type union if more types are needed
export type SegmentationFilterType = SelectFilter<string>;

const SS = SegmentationSubtypes;

export const segmentationFilters = (() => {
  const filters = {
    cardTypeFilter,
    networkFilter,
    creditNetworkFilter,
    activityTypeFilter,
    atmNetworkFilter,
    atmFleetFilter,
    transactionTypeFilter,
    transactionTypePosFilter,
  };

  const filtersById = byId(R.values(filters));

  const behavioralDebit = {
    spendFrequency,
  };

  const behavioral = {
    spendFrequency,
    tripSegment,
  };

  const demographic = {
    age,
    gender,
  };

  const longevity = {
    memberType,
    churn,
  };

  const geographical = {
    region,
    state,
    city,
  };

  const subfilters = {
    [SS.Behavioral]: behavioral,
    [SS.BehavioralDebit]: behavioralDebit,
    [SS.Demographic]: demographic,
    [SS.Longevity]: longevity,
    [SS.Geographical]: geographical,
  };

  return {
    filters,
    filtersById,
    subfilters,
  };
})();

export const cardTypeToSegmentationType = (
  cardType?: string
): SegmentationTypes => {
  const { Holistic, Debit, Credit } = SegmentationTypes;

  switch (cardType) {
    case CardTypeOptions.Credit:
      return Credit;
    case CardTypeOptions.Debit:
      return Debit;
    default:
      return Holistic;
  }
};

export const subtypeFiltersFor = (
  subtype: SegmentationSubtypes
): Record<string, SelectFilter<string>> => {
  const { subfilters } = segmentationFilters;

  return subfilters[subtype];
};

export const segmentationSubtypesFor = (
  givenSegmentationType: SegmentationTypes
) => {
  const appropriateSubtypes =
    givenSegmentationType === SegmentationTypes.Debit
      ? R.omit([SS.Behavioral], SegmentationSubtypes)
      : R.omit([SS.BehavioralDebit], SegmentationSubtypes);

  return R.values(appropriateSubtypes);
};

export const labelForSubtype = (subtype: SegmentationSubtypes) => {
  if (subtype === SS.BehavioralDebit) {
    return 'Behavioral';
  }

  return subtype;
};
