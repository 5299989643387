import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import styled from '@emotion/styled';

import { Link } from './Link';

interface Segment {
  name: string;
  path?: string;
}

interface BreadcrumbsProps {
  segments?: Segment[];
}

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
`;

const StyledText = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor.text1};
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ segments, children }) => {
  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      {children ||
        (segments &&
          segments.map((segment) =>
            segment.path ? (
              <StyledLink key={segment.path} to={segment.path}>
                {segment.name}
              </StyledLink>
            ) : (
              <StyledText key={segment.name}>{segment.name}</StyledText>
            )
          ))}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
