import styled from '@emotion/styled';
import {
  CheckboxProps as MUICheckboxProps,
  FormControlLabel as MUIFormControlLabel,
} from '@mui/material';

import Checkbox from './CheckboxBase';

export interface CheckboxProps extends MUICheckboxProps {
  label: string;
}

const StyledFormControlLabel = styled(MUIFormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
  }

  &.MuiFormControlLabel-root .MuiTypography-body1 {
    color: ${({ theme }) => theme.textColor.text1};
    font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
    margin-left: 8px;
    padding-bottom: 3px;
  }
`;

const CheckboxWithLabel: React.FC<CheckboxProps> = ({
  label,
  ...checkboxProps
}) => {
  return (
    <StyledFormControlLabel
      control={<Checkbox {...checkboxProps} />}
      label={label}
    />
  );
};

export default CheckboxWithLabel;
