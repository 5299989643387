import { ReportRole, User } from '../../types';
import { makeId } from '../../util/ids';
import Logo_COOP_Black from '../../assets/images/Logo_COOP_Black.svg';

const RR = ReportRole;

export const makeCollaborator = ({
  user,
  reportRole,
}: {
  user: User;
  reportRole?: ReportRole;
}) => {
  return {
    id: makeId({ entity: 'Collaborator' }),
    user,
    reportRole: reportRole || RR.Viewer,
  };
};

export const dummyUsers: Record<User['id'], User> = {
  COOP: {
    id: '1',
    avatar: Logo_COOP_Black,
    name: 'CO-OP',
    email: 'co-op@example.com',
  },
  You: {
    id: '2',
    name: 'Jill Franklin (you)',
    email: 'jill@co-op.org',
  },
  Sonika: {
    id: '3',
    name: 'Sonika Patel',
    email: 'sonika@co-op.org',
  },
  Sally: {
    id: '4',
    name: 'Sally Forth',
    email: 'sally.forth@co-op.org',
  },
  Siddharth: {
    id: '5',
    name: 'Siddharth Anand',
    email: 'siddharth.anand@co-op.org',
  },
  Alex: {
    id: '6',
    name: 'Alex Smith',
    email: 'alex.smith@co-op.org',
  },
  Tom: {
    id: '7',
    name: 'Tom Illmensee',
    email: 'tom@co-op.org',
  },
  Leo: {
    id: '8',
    name: 'Leo Maros',
    email: 'leo.maros@co-op.org',
  },
  Chris: {
    id: '9',
    name: 'Chris Chandler',
    email: 'chris@co-op.org',
  },
  Mark: {
    id: '10',
    name: 'Mark Li',
    email: 'mark@example.com',
  },
  Irene: {
    id: '11',
    name: 'Irene Montalban',
    email: 'irene@co-op.org',
  },
  JD: {
    id: '12',
    name: 'JD Wolk',
    email: 'jd@co-op.org',
  },
};

const U = dummyUsers;

export const justCOOP = () => {
  return {
    [U.COOP.id]: makeCollaborator({
      user: U.COOP,
      reportRole: RR.Owner,
    }),
  };
};

export const justYou = () => {
  return {
    [U.You.id]: makeCollaborator({
      user: U.You,
      reportRole: RR.Owner,
    }),
  };
};

export const dummyTransactionAnalysisReportCollaborators = [
  {
    id: '222001',
    user: U.You,
    reportRole: ReportRole.Owner,
  },
  {
    id: '222002',
    user: U.Sonika,
    reportRole: ReportRole.Viewer,
  },
];
