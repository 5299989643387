import React from 'react';
import { Box } from 'rebass';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { WidgetTheme } from '../../../types';

interface Props {
  widgetTheme: WidgetTheme;
}

interface ColorBlockProps {
  background: string;
}

const ColorBlock = styled.div<ColorBlockProps>`
  background: ${({ background }) => background};
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.borders.default.radius};
`;

const WidgetThemeColorBlocks: React.FC<Props> = ({ widgetTheme }) => {
  const theme = useTheme();
  const colors = Object.values(theme.widgetTheme[widgetTheme]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 1,
        gridAutoFlow: 'column',
      }}
    >
      {colors.map((color) => (
        <ColorBlock key={color} background={color} />
      ))}
    </Box>
  );
};

export default WidgetThemeColorBlocks;
