import { array, object, string } from 'yup';

import { WidgetType } from '../../../types';

export const editWidgetValidation = object().shape({
  name: string().required('Required'),
  type: string().required('Required'), // TODO: proper type
  theme: string().required('Required'), // TODO: proper type
  size: string().required('Required'), // TODO: proper type

  compareTo: string().notRequired(), // TODO: proper type
  brokenDownBy: string().notRequired(), // TODO: proper type

  value: string().when('type', {
    is: (value: string) =>
      value === WidgetType.LineChart ||
      value === WidgetType.Callout ||
      value === WidgetType.BarChart,
    then: string().required('Required'), // TODO: proper type
    otherwise: string().notRequired(),
  }),
  valueMetric: string().when('type', {
    is: (value: string) =>
      value === WidgetType.LineChart ||
      value === WidgetType.Callout ||
      value === WidgetType.BarChart,
    then: string().required('Required'), // TODO: proper type
    otherwise: string().notRequired(),
  }),
  secondaryValue: string().when('type', {
    is: (value: string) =>
      value === WidgetType.LineChart || value === WidgetType.BarChart,
    then: string().required('Required'), // TODO: proper type
    otherwise: string().notRequired(),
  }),
  secondaryValueMetric: string().when('type', {
    is: WidgetType.LineChart,
    then: string().required('Required'), // TODO: proper type
    otherwise: string().notRequired(),
  }),

  filters: array().of(
    object().shape({
      attribute: string().required('Required'),
      operator: string().required('Required'),
      values: array().min(1, 'Select at least 1 option').required('Required'),
    })
  ),
});
