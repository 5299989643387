import React, { createElement } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { WidgetEditOR } from '../../../assets/images';
import {
  barChartSecondaryValueOptions,
  brokenDownOptions,
  compareOptions,
  widgetMainValueOptions,
  widgetXAxisValueOptions,
} from '../../../data/widget/widgetDisplayData';
import { WidgetType, WidgetValueOptions } from '../../../types';
import {
  Button,
  Dropdown,
  ErrorTextWithContainer,
  MenuItem,
  Paragraph,
} from '../../atoms';

interface ValueAndMetricGroupProps {
  label: string;
  mainValueName: string;
  mainValuePlaceholder?: string;
  metricName?: string;
  metricPlaceholder?: string;
  valueOptions: WidgetValueOptions;
  resetFiltersOnChange?: boolean;
}

interface Props {
  widgetType: WidgetType;
}

interface HiddenButtonProps {
  hidden: boolean;
}

interface ClearButtonProps extends HiddenButtonProps {
  onClick: () => void;
}

interface ComparisonLabelTextProps {
  disabled: boolean;
}

const ValueDropdown = styled(Dropdown)`
  width: 100%;
  min-width: 200px;
`;

const ValueAndMetricGroup: React.FC<ValueAndMetricGroupProps> = ({
  label,
  mainValueName,
  mainValuePlaceholder = 'Select value',
  metricPlaceholder = 'Select a metric',
  metricName,
  valueOptions,
  resetFiltersOnChange,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const mainValue = useWatch({
    control,
    name: mainValueName,
  });

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb={3}>
        <Flex alignItems="center" justifyContent="space-between" width={2 / 3}>
          <Paragraph>{label}</Paragraph>

          <Box width="75%">
            <Controller
              name={mainValueName}
              control={control}
              render={({ field: { onChange, ...restOfField } }) => (
                <Dropdown
                  fullWidth
                  placeholder={mainValuePlaceholder}
                  placeholderDisabled
                  onChange={(e) => {
                    onChange(e);
                    if (resetFiltersOnChange) {
                      setValue('filters', []);
                    }
                  }}
                  {...restOfField}
                >
                  {Object.values(valueOptions).map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Dropdown>
              )}
            />

            {errors[mainValueName] && (
              <ErrorTextWithContainer>
                {errors[mainValueName].message}
              </ErrorTextWithContainer>
            )}
          </Box>
        </Flex>

        <Flex width={1 / 3} justifyContent="flex-end" ml={2}>
          {metricName && (
            <Box>
              <Controller
                name={metricName}
                control={control}
                render={({ field: { name, ...restFieldProps } }) => (
                  <ValueDropdown
                    placeholder={metricPlaceholder}
                    placeholderDisabled
                    name={name}
                    {...restFieldProps}
                  >
                    {mainValue &&
                      Object.values(valueOptions[mainValue].metrics).map(
                        (option) => (
                          <MenuItem value={option.value} key={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                  </ValueDropdown>
                )}
              />
              {errors[metricName] && (
                <ErrorTextWithContainer>
                  {errors[metricName].message}
                </ErrorTextWithContainer>
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};

const ComparisonLabelText = styled(Paragraph)<ComparisonLabelTextProps>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ComparisonLabel = styled.label`
  width: 115px;
`;

const ComparisonDropdown = styled(Dropdown)`
  width: 50%;
  min-width: 150px;
`;

const HiddenButton = styled(Button)<HiddenButtonProps>`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const ClearButton: React.FC<ClearButtonProps> = ({ onClick, hidden }) => (
  <HiddenButton variant="text" onClick={onClick} hidden={hidden}>
    Clear
  </HiddenButton>
);

interface ComparisonSectionProps {
  brokenDownDisabled?: boolean;
}

const ComparisonSection: React.FC<ComparisonSectionProps> = ({
  brokenDownDisabled,
}) => {
  const { control, setValue } = useFormContext();
  const [brokenDownByValue, compareToValue] = useWatch({
    control,
    name: ['brokenDownBy', 'compareTo'],
  });

  return (
    <Flex alignItems="center">
      <WidgetEditOR />
      <Box ml={4} width={1}>
        <Flex alignItems="center" justifyContent="space-between" mb={3}>
          <ComparisonLabel htmlFor="broken-down-by-dropdown">
            <ComparisonLabelText
              disabled={brokenDownDisabled || Boolean(compareToValue)}
              as="span"
            >
              Broken down by
            </ComparisonLabelText>
          </ComparisonLabel>

          <Controller
            name="brokenDownBy"
            control={control}
            render={({ field }) => (
              <ComparisonDropdown
                placeholder="Select value (optional)"
                placeholderDisabled
                disabled={brokenDownDisabled || Boolean(compareToValue)}
                id="broken-down-by-dropdown"
                {...field}
              >
                {Object.values(brokenDownOptions).map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </ComparisonDropdown>
            )}
          />

          <ClearButton
            onClick={() => setValue('brokenDownBy', '')}
            hidden={!brokenDownByValue}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <ComparisonLabel htmlFor="compare-dropdown">
            <ComparisonLabelText
              disabled={Boolean(brokenDownByValue)}
              as="span"
            >
              Compare to
            </ComparisonLabelText>
          </ComparisonLabel>

          <Controller
            name="compareTo"
            control={control}
            render={({ field }) => (
              <ComparisonDropdown
                placeholder="Select value (optional)"
                placeholderDisabled
                disabled={Boolean(brokenDownByValue)}
                id="compare-dropdown"
                {...field}
              >
                {Object.values(compareOptions).map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </ComparisonDropdown>
            )}
          />

          <ClearButton
            onClick={() => setValue('compareTo', '')}
            hidden={!compareToValue}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

const LineChartSecondaryOptions: React.FC = () => {
  return (
    <>
      <ValueAndMetricGroup
        label="Value"
        mainValueName="value"
        metricName="valueMetric"
        mainValuePlaceholder="Select a y-axis value"
        valueOptions={widgetMainValueOptions}
        resetFiltersOnChange
      />
      <ValueAndMetricGroup
        label="By"
        mainValueName="secondaryValue"
        metricName="secondaryValueMetric"
        mainValuePlaceholder="Select x-axis value"
        valueOptions={widgetXAxisValueOptions}
      />

      <ComparisonSection />
    </>
  );
};

const CalloutSecondaryOptions: React.FC = () => {
  return (
    <>
      <ValueAndMetricGroup
        label="Value"
        mainValueName="value"
        metricName="valueMetric"
        valueOptions={widgetMainValueOptions}
        resetFiltersOnChange
      />

      <ComparisonSection brokenDownDisabled />
    </>
  );
};

const BarChartSecondaryOptions: React.FC = () => {
  return (
    <>
      <ValueAndMetricGroup
        label="Value"
        mainValueName="value"
        metricName="valueMetric"
        valueOptions={widgetMainValueOptions}
        resetFiltersOnChange
      />

      <ValueAndMetricGroup
        label="By"
        mainValueName="secondaryValue"
        valueOptions={barChartSecondaryValueOptions}
      />

      <ComparisonSection />
    </>
  );
};

const typeToOptions: Record<WidgetType, React.FC> = {
  [WidgetType.LineChart]: LineChartSecondaryOptions,
  [WidgetType.Callout]: CalloutSecondaryOptions,
  [WidgetType.BarChart]: BarChartSecondaryOptions,
  [WidgetType.Table]: LineChartSecondaryOptions, // TODO: add proper component
};

const SetupSecondaryOptions: React.FC<Props> = ({ widgetType }) => {
  return createElement(typeToOptions[widgetType]);
};

export default SetupSecondaryOptions;
