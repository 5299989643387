// //// In-project style options
import { WidgetTheme } from '../../types';

// Breakpoints
const Xs = '500px';
const Sm = '740px';
const Md = '900px';
const Lg = '1080px';
const Xl = '1200px';
const Xxl = '1540px';

// Colors
const white = '#FFFFFF';
const grey2 = '#F4F6F6';
const grey3 = '#D4D9DB';
const grey4 = '#5E6B72';
const grey5 = '#263238';
const black = '#151E22';

const blueA = '#EDF4FF';
const blueB = '#73B3FF';
const blueC = '#1868EB';
const blueD = '#0D3D92';

const tealA = '#E5F5FC';
const tealB = '#97D5F3';
const tealC = '#0176A7';
const tealD = '#014D6D';

const blueBackground = '#EDF4FF';
const blueMedium = '#2979FF';
const blueLight = '#9DC7F9';
const blueDark = '#0D3D92';
const greyLight1 = '#FAFAFA';
const greyLight2 = '#F4F6F6';
const greyLight3 = '#ECEFF1';
const greyLight4 = '#CFD8DC';
const greyDark1 = '#B0BEC5';
const greyDark2 = '#90A4AE';
const greyDark3 = '#78909C';
const greyDark4 = '#566E7A';
const indigoDark = '#304FFE';
const indigoMedium = '#3F3FDF';
const redMedium = '#F50032';
const redDark = '#9A000C';
const greenMedium = '#2DA641';
const orangeMedium = '#ED6A0C';
const yellowMedium = '#FAAB0C';
const limeMedium = '#85C700';
const tealMedium = '#11A0AD';
const violetMedium = '#8A3FD4';
const magentaMedium = '#D42A8D';
const tabGradient =
  'linear-gradient(90deg, #FFFFFF 0%, rgba(244, 246, 246, 0) 100%)';

// Fonts
const roboto = 'Roboto';
const arial = 'arial';
const sansSerif = 'sans-serif';

const fontSize10 = '0.6875rem';
const fontSize20 = '0.75rem';
const fontSize30 = '0.8125rem';
const fontSize40 = '0.875rem';
const fontSize50 = '1rem';
const fontSize60 = '1.0625rem';
const fontSize70 = '1.375rem';
const fontSize80 = '1.5rem';
const fontSize90 = '1.75rem';
const fontSize100 = '2.125rem';
const fontSize110 = '3rem';

const textColor = {
  text1: white,
  text2: grey2,
  text3: grey3,
  text4: blueA,
  default: white,
  primaryButton: black,
  secondaryButton: blueC,
  link: blueB,
  rowHover: blueB,
  icon: white,
  emphasis: greyDark4,
  onDark: white,
  disabled: greyLight1,
  empty: greyDark2,
  action: blueMedium,
};

const avatarColor = {
  indigo: indigoMedium,
  red: redMedium,
  green: greenMedium,
  orange: orangeMedium,
  yellow: yellowMedium,
  lime: limeMedium,
  teal: tealMedium,
  violet: violetMedium,
  magenta: magentaMedium,
};

const backgroundColor = {
  background1: black,
  background2: grey5,
  element1: grey4,
  element2: grey3,
  default: grey2,
  lightest: black,
  darkest: white,
  mid: greyLight2,
  midAlt: greyLight3,
  dark: greyLight4,
  verticalTab: tabGradient,
  radioButton: {
    unchecked: grey3,
    checked: blueC,
    focus: blueD,
  },
  button: {
    default: blueB,
    focus: blueA,
    tertiary: {
      default: blueA,
      focus: blueC,
    },
  },
  iconButton: {
    primary: {
      default: grey5,
      active: grey3,
      hover: grey4,
    },
    secondary: {
      default: 'transparent',
      active: grey3,
      hover: grey5,
    },
  },
  line: greyLight4,
  notification: yellowMedium,
  rowHover: grey4,
};

const widgetShadow = '0px 2px 10px #151E22;';
const contentShadow = '0px 20px 40px #151E22;';

//  DARK MODE THEME END ---------------------------------

const fontFamily = {
  default: `${roboto}, ${arial}, ${sansSerif}`,
};

const fontSize = {
  body: fontSize50,
  h1: fontSize110,
  h2: fontSize100,
  h3: fontSize80,
  h4: fontSize70,
  h5: fontSize40,
  h6: fontSize20,
  nav: fontSize60,
  caption1: fontSize20,
  avatar: fontSize30,
  tableHeader: fontSize20,
};

const borders = {
  default: {
    attrs: '1px solid',
    radius: '5px',
    radiusNumber: 5, // For libraries that require numbers
  },
  table: `1px solid ${greyDark1}`,
  input: 'none',
  tooltip: {
    color: blueLight,
  },
  checkbox: {
    unchecked: `1.5px solid ${grey4}`,
    checked: `1.5px solid ${tealC}`,
    disabled: `1.5px solid ${grey3}`,
  },
};

const icon = {
  size: '20',
};

const inputYPadding = '4px';
const inputXPadding = '13px';
const padding = {
  input: {
    all: `${inputYPadding} ${inputXPadding}`,
    x: inputXPadding,
    y: inputYPadding,
  },
};

// TODO: replace with proper theme colors
const widgetTheme = {
  [WidgetTheme.Theme1]: {
    main: greenMedium,
    second: blueMedium,
    third: indigoDark,
  },
  [WidgetTheme.Theme2]: {
    main: orangeMedium,
    second: blueMedium,
    third: indigoDark,
  },
};

// Shadows

// ///// Actual theme + supporting fns

const mediaQuery = (value: string) => {
  return `@media (min-width: ${value})`;
};

export const Theme = {
  breakpoints: [Xs, Sm, Md, Lg, Xl, Xxl],
  textColor,
  avatarColor,
  backgroundColor,
  fontFamily,
  fontSize,
  icon,
  padding,
  widgetTheme,
  colors: {
    black,
    white,
    blueBackground,
    blueMedium,
    blueDark,
    tealA,
    tealB,
    tealC,
    tealD,
    grey2,
    grey3,
    grey4,
    grey5,
    greyLight1,
    greyLight2,
    greyLight3,
    greyLight4,
    greyDark1,
    greyDark2,
    greyDark3,
    greyDark4,
    redMedium,
    redDark,
    greenMedium,
    indigoDark,
    orangeMedium,
  },
  borders,
  shadows: {
    widgetShadow,
    contentShadow,
  },
  fonts: {
    families: {
      roboto,
      arial,
      sansSerif,
    },
    sizes: {
      fontSize10,
      fontSize20,
      fontSize30,
      fontSize40,
      fontSize50,
      fontSize60,
      fontSize70,
      fontSize80,
      fontSize90,
      fontSize100,
      fontSize110,
    },
  },
  mediaQueries: {
    xs: mediaQuery(Xs),
    sm: mediaQuery(Sm),
    md: mediaQuery(Md),
    lg: mediaQuery(Lg),
    xl: mediaQuery(Xl),
    xxl: mediaQuery(Xxl),
  },
};

export const ThemeDark = {
  breakpoints: [Xs, Sm, Md, Lg, Xl, Xxl],
  textColor: {
    text1: white,
    text2: grey2,
    text3: grey3,
    text4: blueA,
    default: white,
    primaryButton: black,
    secondaryButton: blueC,
    link: blueB,
    rowHover: blueB,
    icon: white,
    emphasis: greyDark4,
    onDark: white,
    disabled: greyLight1,
    empty: greyDark2,
    action: blueMedium,
  },
  avatarColor,
  backgroundColor: {
    background1: black,
    background2: grey5,
    element1: grey4,
    element2: grey3,
    default: grey2,
    lightest: black,
    darkest: white,
    mid: greyLight2,
    midAlt: greyLight3,
    dark: greyLight4,
    verticalTab:
      'linear-gradient(90deg, #151E22 0%, rgba(244, 246, 246, 0) 100%)',
    radioButton: {
      unchecked: grey3,
      checked: blueC,
      focus: blueD,
    },
    button: {
      default: blueB,
      focus: blueA,
      tertiary: {
        default: blueA,
        focus: blueC,
      },
    },
    iconButton: {
      primary: {
        default: grey5,
        active: grey3,
        hover: grey4,
      },
      secondary: {
        default: 'transparent',
        active: grey3,
        hover: grey5,
      },
    },
    line: greyLight4,
    notification: yellowMedium,
    rowHover: grey4,
  },
  fontFamily,
  fontSize,
  icon,
  padding,
  widgetTheme,
  colors: {
    black,
    white,
    blueBackground,
    blueMedium,
    blueDark,
    grey2,
    grey3,
    grey4,
    grey5,
    greyLight1,
    greyLight2,
    greyLight3,
    greyLight4,
    greyDark1,
    greyDark2,
    greyDark3,
    greyDark4,
    redMedium,
    redDark,
    greenMedium,
    indigoDark,
    orangeMedium,
  },
  borders,
  shadows: {
    widgetShadow: '0px 2px 10px #151E22;',
    contentShadow: '0px 20px 40px #151E22;',
  },
  fonts: {
    families: {
      roboto,
      arial,
      sansSerif,
    },
    sizes: {
      fontSize10,
      fontSize20,
      fontSize30,
      fontSize40,
      fontSize50,
      fontSize60,
      fontSize70,
      fontSize80,
      fontSize90,
      fontSize100,
      fontSize110,
    },
  },
  mediaQueries: {
    xs: mediaQuery(Xs),
    sm: mediaQuery(Sm),
    md: mediaQuery(Md),
    lg: mediaQuery(Lg),
    xl: mediaQuery(Xl),
    xxl: mediaQuery(Xxl),
  },
};

export const ThemeLight = {
  breakpoints: [Xs, Sm, Md, Lg, Xl, Xxl],
  textColor: {
    text1: black,
    text2: grey5,
    text3: grey4,
    text4: blueD,
    default: black,
    primaryButton: white,
    secondaryButton: blueC,
    link: blueC,
    rowHover: blueC,
    icon: grey4,
    emphasis: greyDark4,
    onDark: white,
    disabled: greyLight1,
    empty: greyDark2,
    action: blueMedium,
  },
  avatarColor,
  backgroundColor: {
    background1: white,
    background2: grey2,
    element1: grey3,
    element2: grey4,
    default: grey2,
    lightest: white,
    darkest: black,
    mid: greyLight2,
    midAlt: greyLight3,
    dark: greyLight4,
    verticalTab:
      'linear-gradient(90deg, #FFFFFF 0%, rgba(244, 246, 246, 0) 100%)',
    radioButton: {
      unchecked: grey3,
      checked: blueC,
      focus: blueD,
    },
    button: {
      default: tealC,
      focus: blueD,
      tertiary: {
        default: blueA,
        focus: blueC,
      },
    },
    iconButton: {
      primary: {
        default: white,
        active: grey4,
        hover: grey3,
      },
      secondary: {
        default: 'transparent',
        active: grey4,
        hover: grey2,
      },
    },
    line: greyLight4,
    notification: yellowMedium,
    rowHover: blueA,
  },
  fontFamily,
  fontSize,
  icon,
  padding,
  widgetTheme,
  colors: {
    black,
    white,
    blueBackground,
    blueMedium,
    blueDark,
    grey2,
    grey3,
    grey4,
    grey5,
    greyLight1,
    greyLight2,
    greyLight3,
    greyLight4,
    greyDark1,
    greyDark2,
    greyDark3,
    greyDark4,
    redMedium,
    redDark,
    greenMedium,
    indigoDark,
    orangeMedium,
    tealA,
  },
  borders,
  shadows: {
    widgetShadow: '0px 2px 10px rgba(176, 190, 197, 0.5)',
    contentShadow: '0px 20px 40px #E7EBEE;',
  },
  fonts: {
    families: {
      roboto,
      arial,
      sansSerif,
    },
    sizes: {
      fontSize10,
      fontSize20,
      fontSize30,
      fontSize40,
      fontSize50,
      fontSize60,
      fontSize70,
      fontSize80,
      fontSize90,
      fontSize100,
      fontSize110,
    },
  },
  mediaQueries: {
    xs: mediaQuery(Xs),
    sm: mediaQuery(Sm),
    md: mediaQuery(Md),
    lg: mediaQuery(Lg),
    xl: mediaQuery(Xl),
    xxl: mediaQuery(Xxl),
  },
};

export type ThemeType = typeof Theme;
export type ThemeColors = keyof typeof Theme.colors;
export interface HasTheme {
  theme: ThemeType;
}
