// only add bare HTML elemnts like body, h1, p, etc or add overrides for MaterialUI in this file.
import { css } from '@emotion/react';

import { HasTheme } from '../settings/Theme';

export const Base = ({ theme }: HasTheme) => css`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }

  html,
  body,
  li {
    height: auto;
  }

  html,
  body,
  #root {
    height: 100%;
    position: relative;
  }

  body {
    margin: 0;
    font-family: ${theme.fontFamily.default};
    color: ${theme.textColor.default};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input,
  textarea,
  select {
    -webkit-appearance: none;
  }

  a {
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }

  .visually-hidden {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }
`;

// Add global MUI overrides here
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export const MUI = ({ theme }: HasTheme) => css``;
