import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from '../data/hooks';
import { getNotifications } from '../data/notifications/notificationsSlice';

const RouteChange = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch, location.pathname]);

  return null;
};

export default RouteChange;
