import { motion } from 'framer-motion';
import React from 'react';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';

import { useAppDispatch } from '../../data/hooks';
import { clearNotificationsById } from '../../data/notifications/notificationsSlice';
import { Notification, NotificationCategory } from '../../types';
import { formatDate } from '../../util/dates';
import {
  Card,
  Date,
  H5,
  NotificationDot,
  UnstyledButton,
  UnstyledLink,
} from '../atoms';
import { IconDecline } from '../../assets/images';
import { paths } from '../../routing/paths';

interface ItemCardProps {
  leftBorder?: boolean;
}

interface Props extends ItemCardProps {
  notification: Notification;
  onClose?: () => void;
}

const ItemCard = styled(Card)<ItemCardProps>`
  background: ${({ theme }) => theme.backgroundColor.background1};
  width: 280px;
  overflow: hidden;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  .notificationIcon {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    color: ${({ theme }) => theme.textColor.icon};
    opacity: 0;
  }
  :hover {
    background: ${({ theme }) => theme.backgroundColor.background2};
    .notificationIcon {
      opacity: 1;
    }
  }
`;

const categoryTitle: Record<NotificationCategory, string> = {
  [NotificationCategory.Business]: 'Business Alert',
  [NotificationCategory.Scheduled]: 'Scheduled Report',
  [NotificationCategory.Shared]: 'Shared Reports',
};

const NotificationTitle = styled(H5)`
  color: ${({ theme }) => theme.textColor.text2};
`;

const NotificationBody = styled.p`
  color: ${({ theme }) => theme.textColor.text1};
`;

const NotificationMenuItem: React.FC<Props> = ({ notification, onClose }) => {
  const dispatch = useAppDispatch();

  const onRemoveClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    dispatch(clearNotificationsById([notification.id]));
    if (onClose) {
      onClose();
    }
  };

  const renderNotificationFullTitle = () => {
    // TODO: make the text bolding dynamic in the future
    // atm, we're just hardcoding the bold html inside the string
    // that we get from the dummy data
    return (
      <H5
        /* eslint-disable-line react/no-danger */ dangerouslySetInnerHTML={{
          __html: notification.longTitle,
        }}
      />
    );
  };

  return (
    <motion.div
      layout
      key={notification.id}
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: -10, opacity: 0 }}
      transition={{ ease: 'easeOut', duration: 0.5 }}
    >
      <UnstyledLink to={paths.report(notification.resource.id)}>
        <ItemCard p="16px">
          <Flex alignItems="center">
            <NotificationTitle fontWeight="normal">
              {categoryTitle[notification.category]}
            </NotificationTitle>
            <Box mx={1} />
            <NotificationDot />
            <Box flex="1" />
            <UnstyledButton
              type="button"
              onClick={onRemoveClick}
              aria-label="Clear notificiation"
            >
              <Flex width="15px" height="auto" className="notificationIcon">
                <IconDecline />
              </Flex>
            </UnstyledButton>
          </Flex>
          <Box my={3} />
          <NotificationBody>{renderNotificationFullTitle()}</NotificationBody>
          <Box my={3} />
          <Date>{formatDate({ date: notification.date })}</Date>
        </ItemCard>
      </UnstyledLink>
    </motion.div>
  );
};

export default NotificationMenuItem;
