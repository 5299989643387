import React from 'react';
import { HiPlusCircle } from 'react-icons/hi';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { NewWidgetGraphic } from '../../../assets/images';
import { Button, H4, Paragraph } from '../../atoms';

const Container = styled(Flex)`
  border-color: ${({ theme }) => theme.backgroundColor.button.default};
  border-style: dashed;
  border-width: 2px;
  padding: 60px 70px 60px 60px;
`;

const LeftContainer = styled(Box)`
  width: 50%;
`;

const RightContainer = styled(Flex)`
  width: 50%;
  justify-content: flex-end;
`;

const WidgetGraphic = styled.img`
  width: 65%;
  height: auto;
`;

const ButtonText = styled.span`
  padding-left: 10px;
`;

interface Props {
  onAddNewWidgetClick: () => void;
}
const AddFirstWidget: React.FC<Props> = ({ onAddNewWidgetClick }) => {
  return (
    <>
      <Container>
        <LeftContainer>
          <H4>Build your custom report with widgets!</H4>
          <Box py={3} />
          <Paragraph>
            Reports are made up of various widgets. These widgets are fully
            customizable and provide a great way to see large amounts of data at
            a glance. Start building your report with your first widget.
          </Paragraph>
          <Box py={3} />

          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onAddNewWidgetClick}
          >
            <HiPlusCircle />
            <ButtonText>Add New Widget</ButtonText>
          </Button>
        </LeftContainer>
        <RightContainer>
          <WidgetGraphic
            src={NewWidgetGraphic}
            alt="Graphic of a widget coming out of a page"
          />
        </RightContainer>
      </Container>
    </>
  );
};

export default AddFirstWidget;
