import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import styled from '@emotion/styled';

import { arbitraryWidgetData } from '../../../data/widget/dummyWidgetData';
import { ReportWidget, Widget, WidgetSize } from '../../../types';
import { dummyWidgetDataOffset } from '../../../util/widget';
import { Box, Card, Paragraph } from '../../atoms';
import { ReportWidget as ReportWidgetComponent } from '../../molecules';

const InnerCard = styled(Card)`
  background: ${({ theme }) => theme.backgroundColor.background1};
  box-shadow: none;
`;

const StyledCardBody = styled.div`
  padding: 25px;
`;

const EditWidgetPreview: React.FC = () => {
  const { control } = useFormContext();

  const [
    name,
    type,
    theme,
    value,
    valueMetric,
    secondaryValue,
    secondaryValueMetric,
  ] = useWatch({
    control,
    name: [
      'name',
      'type',
      'theme',
      'value',
      'valueMetric',
      'secondaryValue',
      'secondaryValueMetric',
    ],
  });

  const widget: Widget = {
    id: '1',
    name,
    type,
    theme,
    value,
    valueMetric,
    secondaryValue,
    secondaryValueMetric,
    // TODO: make dynamic once ready
    columns: ['Transactions', 'Type', 'Spend'],
    size: WidgetSize.Small,
  };

  const reportWidget: Omit<ReportWidget, 'layout'> = {
    reportId: 'preview',
    widget,
    // TODO: use scripted widget data?
    data: arbitraryWidgetData(
      widget,
      dummyWidgetDataOffset(value, valueMetric)
    ),
  };

  const renderPreviewContent = () => {
    if (!name || !type || !theme || !value || !valueMetric) {
      return <Paragraph>Please fill in more information for preview</Paragraph>;
    }

    return (
      <ReportWidgetComponent
        reportWidget={reportWidget}
        preview
        // TODO: should this be conditional type?
        // instead of dummy function
        onEditWidgetSubmit={() => {}}
      />
    );
  };

  return (
    <div>
      <Paragraph>Preview</Paragraph>
      <Box mb={3} />
      <InnerCard>
        <StyledCardBody>{renderPreviewContent()}</StyledCardBody>
      </InnerCard>
    </div>
  );
};

export default EditWidgetPreview;
