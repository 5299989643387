import * as R from 'ramda';

import {
  BarChartData,
  CalloutData,
  CompareToType,
  LineChartData,
  Widget,
  WidgetData,
  WidgetType as WT,
} from '../../types';
import { toNumber } from '../../util/ids';
import { loadScript } from '../scripts/scriptLoader';
import { WidgetDataArgs } from '../scripts/types';
import { makePoints } from '../lineChartCurves';

const dummyCalloutData: CalloutData[] = [
  {
    main: '$100,334',
    compare: '11.4% over last period',
  },
  {
    main: '$220,134',
    compare: '7.8% over last period',
  },
  {
    main: '$173,550',
    compare: '1.7% over last period',
  },
  {
    main: '$122,220',
    compare: '21.6% over last period',
  },
  {
    main: '$56,350',
    compare: '2.4% over last period',
  },
  {
    main: '$60,134',
    compare: '5.4% over last period',
  },
];

const dummyBarChartData: BarChartData[] = [
  {
    bars: [
      {
        name: 'Debit 642334',
        value: 66530,
      },
      {
        name: 'Credit 642334',
        value: 94730,
      },
    ],
  },
  {
    bars: [
      {
        name: 'Debit 642334',
        value: 61230,
      },
      {
        name: 'Credit 642334',
        value: 90770,
      },
    ],
  },
  {
    bars: [
      {
        name: 'Debit 642334',
        value: 61200,
      },
      {
        name: 'Credit 642334',
        value: 85000,
      },
    ],
  },
  {
    bars: [
      {
        name: 'Debit 642334',
        value: 59201,
      },
      {
        name: 'Credit 642334',
        value: 82842,
      },
    ],
  },
  {
    bars: [
      {
        name: 'Debit 642334',
        value: 59123,
      },
      {
        name: 'Credit 642334',
        value: 81932,
      },
    ],
  },
];

const dummySlope = (offset: number): number => {
  // These are just arbitary values
  // to give a more realistic looking line chart
  if (offset < 5) {
    return 2;
  } else if (offset < 15) {
    return 20;
  } else if (offset < 20) {
    return 70;
  } else if (offset < 25) {
    return -70;
  } else if (offset < 30) {
    return 20;
  } else if (offset < 40) {
    return 30;
  } else if (offset < 45) {
    return -25;
  } else if (offset < 50) {
    return 10;
  } else if (offset < 55) {
    return -20;
  } else if (offset < 65) {
    return -50;
  } else if (offset < 70) {
    return 50;
  } else if (offset < 75) {
    return -55;
  } else if (offset < 80) {
    return -30;
  } else if (offset < 85) {
    return 40;
  } else if (offset < 90) {
    return 20;
  } else if (offset < 100) {
    return -20;
  } else if (offset < 100) {
    return -20;
  } else if (offset < 105) {
    return 20;
  } else if (offset < 110) {
    return -10;
  } else if (offset < 115) {
    return 30;
  } else if (offset < 125) {
    return -30;
  } else if (offset < 140) {
    return 40;
  } else if (offset < 145) {
    return -30;
  } else if (offset < 150) {
    return 60;
  }

  return 10;
};

const dummyMainLineChartData = R.range(0, 150).reduce<LineChartData>(
  (acc, value, idx) => {
    // The constants here are arbitary
    // to give a more realistic looking line chart
    const date = new Date(2020, 9, 1);
    const prevY: number = acc[idx - 1]?.y || 2000;
    const point = {
      x: new Date(date.setDate(date.getDate() + value)),
      y: Math.round(dummySlope(value) + prevY),
    };
    return [...acc, point];
  },
  []
);

// The slice ranges are arbitary here
// mainly so we can show some visual change
// when switching b/t data sets
const dummyLineChartsData = [
  dummyMainLineChartData,
  dummyMainLineChartData.slice(0, 130),
  dummyMainLineChartData.slice(0, 120),
  dummyMainLineChartData.slice(0, 110),
  dummyMainLineChartData.slice(0, 90),
];

const dummyTableData = [
  {
    rows: [
      {
        id: '1',
        Transactions: 'Card Present',
        Type: 'Card Present',
        // TODO: not sure how to have "complex" data represented in "spend"
        // Will certain column types have more sub-options?
        Spend: '1.4% over last period',
      },
      {
        id: '2',
        Transactions: 'Card Entry',
        Type: 'Mag Stripe',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.4% over last period',
      },
      {
        id: '3',
        Transactions: 'Recurring Payment',
        Type: 'Not Recurring',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.4% over last period',
      },
      {
        id: '4',
        Transactions: 'Digital Wallet',
        Type: 'Non Wallet',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.4% over last period',
      },
    ],
  },
  {
    rows: [
      {
        id: '1',
        Transactions: 'Card Present',
        Type: 'Card Present',
        // TODO: not sure how to have "complex" data represented in "spend"
        // Will certain column types have more sub-options?
        Spend: '1.5% over last period',
      },
      {
        id: '2',
        Transactions: 'Card Entry',
        Type: 'Mag Stripe',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
      {
        id: '3',
        Transactions: 'Recurring Payment',
        Type: 'Not Recurring',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
      {
        id: '4',
        Transactions: 'Digital Wallet',
        Type: 'Non Wallet',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
    ],
  },
  {
    rows: [
      {
        id: '1',
        Transactions: 'Card Present',
        Type: 'Card Present',
        // TODO: not sure how to have "complex" data represented in "spend"
        // Will certain column types have more sub-options?
        Spend: '1.2% over last period',
      },
      {
        id: '2',
        Transactions: 'Card Entry',
        Type: 'Mag Stripe',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.2% over last period',
      },
      {
        id: '3',
        Transactions: 'Recurring Payment',
        Type: 'Not Recurring',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.2% over last period',
      },
      {
        id: '4',
        Transactions: 'Digital Wallet',
        Type: 'Non Wallet',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.2% over last period',
      },
    ],
  },
  {
    rows: [
      {
        id: '1',
        Transactions: 'Card Present',
        Type: 'Card Present',
        // TODO: not sure how to have "complex" data represented in "spend"
        // Will certain column types have more sub-options?
        Spend: '1.5% over last period',
      },
      {
        id: '2',
        Transactions: 'Card Entry',
        Type: 'Mag Stripe',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
      {
        id: '3',
        Transactions: 'Recurring Payment',
        Type: 'Not Recurring',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
      {
        id: '4',
        Transactions: 'Digital Wallet',
        Type: 'Non Wallet',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.5% over last period',
      },
    ],
  },
  {
    rows: [
      {
        id: '1',
        Transactions: 'Card Present',
        Type: 'Card Present',
        // TODO: not sure how to have "complex" data represented in "spend"
        // Will certain column types have more sub-options?
        Spend: '1.1% over last period',
      },
      {
        id: '2',
        Transactions: 'Card Entry',
        Type: 'Mag Stripe',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.1% over last period',
      },
      {
        id: '3',
        Transactions: 'Recurring Payment',
        Type: 'Not Recurring',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.1% over last period',
      },
      {
        id: '4',
        Transactions: 'Digital Wallet',
        Type: 'Non Wallet',
        // TODO: not sure how to have "complex" data represented in "spend"
        Spend: '1.1% over last period',
      },
    ],
  },
];

const dummyData = (widgetId: string, offset?: number) => <DataType>(
  dataArr: DataType[]
) => {
  const index = (Math.abs(toNumber(widgetId)) + (offset || 0)) % dataArr.length;
  return dataArr[index];
};

const getArbitraryLineChartData = (widget: Widget, offset: number) => {
  const baseChartData = dummyData(widget.id, offset)(dummyLineChartsData);
  const allLineChartData = [baseChartData];

  if (widget.compareTo === CompareToType.PriorPeriod) {
    // TODO make more dynamic if necessary
    const priorPeriodData = makePoints({
      xScale: 0.07,
      yScale: 0.03,
      seed: 45,
      keyPoints: [
        baseChartData[0],
        baseChartData[Math.round(baseChartData.length / 3)],
        baseChartData[Math.round(baseChartData.length / 3) * 2],
        baseChartData[baseChartData.length - 1],
      ],
    });
    allLineChartData.push(priorPeriodData);
  }

  return allLineChartData;
};

export const arbitraryWidgetData = (
  widget: Widget,
  offset: number
): WidgetData | undefined => {
  const getFrom = dummyData(widget.id, offset);

  switch (widget.type) {
    case WT.Callout:
      return getFrom(dummyCalloutData);
    case WT.BarChart:
      return getFrom(dummyBarChartData);
    case WT.LineChart:
      return getArbitraryLineChartData(widget, offset);
    case WT.Table:
      return getFrom(dummyTableData);
    default:
      return undefined;
  }
};

export const widgetData = ({
  widget,
  offset,
  report,
  filters,
  segmentationFilters,
  additionalFilters,
  detailedSegmentationFilters,
}: WidgetDataArgs): WidgetData | undefined => {
  const script = loadScript();
  const scriptedWidgetData = script?.widgetData({
    widget,
    offset,
    report,
    filters,
    segmentationFilters,
    additionalFilters,
    detailedSegmentationFilters,
  });

  return scriptedWidgetData || arbitraryWidgetData(widget, offset);
};

const dummyWidgetRowData = {
  'CARD NUMBER': 'XXXX XXXX XXXX XXXX',
  'TRAN ACTIVITY': 'Denial',
  'ACTION CODE': '111-Invalid Card Number',
  'FUNCTION CODE': '182-Card',
  'TRANSACTION TYPE': '00000010000-Purchase Auth',
  MCC: '5999-Misc',
  'DEVICE ID': 'Z290001',
  'CARD ACCEPT NAME': 'AMAZON RETAIL LLC',
};

export const widgetDetailData = Array(20).fill(dummyWidgetRowData);
