import styled from '@emotion/styled';

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.textColor.default};
  line-height: 1.5;
`;

export const Caption1 = styled.span`
  font-size: ${({ theme }) => theme.fontSize.caption1};
`;

export const Headline = styled.h4`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize50};
`;

export const Date = styled(Caption1)`
  color: ${({ theme }) => theme.textColor.text2};
`;
