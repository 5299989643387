import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import { HiX, HiBadgeCheck } from 'react-icons/hi';

import WelcomeBackSlider from './WelcomeBackSlider';
import { paths } from '../../../routing/paths';
import { Logo_COOPIC, Logo_COOPIC_White } from '../../../assets/images';
import {
  Card,
  CardBody,
  CardDivider,
  Link,
  Caption1,
  Paragraph,
  Button,
  Modal,
  IconButton,
} from '../../atoms';
import { ModalProps } from '../../atoms/Modal';
import { useUserSettings } from '../../../hooks/useUserSettings';

const Logo = styled.img`
  height: 40px;
`;

const FeatureContainer = styled(Flex)`
  padding: 20px;
  width: 100%;
`;

const WelcomeHeader = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.h2};
  white-space: pre-wrap;
  word-break: keep-all;
  hyphens: unset;
`;

const Footer = styled(Box)`
  display: block;
  background: transparent;
  width: 100%;
  margin-top: 20px;
`;

const Content = styled(Box)`
  width: 1200px;
`;

const RightCard = styled(Card)`
  height: 100%;
  background: ${({ theme }) => theme.backgroundColor.background2};
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VerticalRule = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.backgroundColor.line};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NewHeader = styled(Paragraph)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textColor.link};
  font-weight: bold;
`;

const CheckboxText = styled(Caption1)`
  color: ${({ theme }) => theme.textColor.text3};
  padding-right: 15px;
`;

const TutorialBody = styled(CardBody)`
  background: ${({ theme }) => theme.backgroundColor.background2};
`;

const WelcomeBack: React.FC<Omit<ModalProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const { isDark } = useUserSettings();
  return (
    <Modal onClose={onClose} {...props}>
      <Content>
        <Card>
          <CardBody padding="small">
            <Flex justifyContent="space-between" alignItems="center">
              <Logo
                src={isDark ? Logo_COOPIC_White : Logo_COOPIC}
                alt="CO-OP Insights Center"
              />
              <IconButton
                icon={HiX}
                aria-controls="Close modal"
                onClick={(e) => onClose && onClose(e, 'backdropClick')}
                active={false}
                variant="secondary"
                size="small"
              />
            </Flex>
            <Box
              sx={{
                display: 'grid',
                gridGap: 5,
                gridTemplateColumns: ['1fr', '1fr', '1fr', '6fr 5fr'],
              }}
            >
              <Box px={4} pt={3}>
                <Box my={2} />
                <WelcomeHeader>Welcome back,</WelcomeHeader>
                <WelcomeHeader>
                  <b>Jessica</b>
                </WelcomeHeader>

                <Box py={3} />
                <Flex flexDirection="column">
                  <Paragraph>
                    <b>Since you&apos;ve been gone:</b>
                  </Paragraph>
                  <Box py={2} />
                  <Flex>
                    <VerticalRule mr={3} />
                    <Box>
                      <Box py={1}>
                        <Paragraph>
                          You have 6 new notifications.{' '}
                          <StyledLink to={paths.notifications()}>
                            See notifications
                          </StyledLink>
                        </Paragraph>
                      </Box>
                      <Box py={1}>
                        <Paragraph>
                          2 reports have been shared with you.{' '}
                          <StyledLink to={paths.reports()}>
                            View Shared Reports
                          </StyledLink>
                        </Paragraph>
                      </Box>
                      <Box py={1}>
                        <Paragraph>
                          Another metric here.{' '}
                          <StyledLink to={paths.reports()}>
                            Link here
                          </StyledLink>
                        </Paragraph>
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
              <RightCard leftBorder>
                <CardBody padding="small">
                  <NewHeader>
                    <HiBadgeCheck /> <Box mx={1} />
                    What&apos;s New
                  </NewHeader>
                  <Box my={3} />
                  <CardDivider />
                  <Box my={3} />
                  <Paragraph>
                    Ever wanted to segment your data by age group, geography, or
                    gender? Well you don&apos;t have to wait any longer.
                    Insights Center has added <b>Segmentation</b> capabilities!
                  </Paragraph>
                  <br />
                  <Paragraph>
                    At the top of you reports you will notice segementation
                    filters that can be edited and applied at any time.
                  </Paragraph>
                </CardBody>
              </RightCard>
            </Box>
            <Box my={3} />
          </CardBody>
          <CardDivider />
          <TutorialBody padding="small">
            <Box my={3} />
            <Box>
              <Box px={4}>
                <Paragraph>
                  <b>Tutorials</b>
                </Paragraph>
              </Box>
              <FeatureContainer>
                <WelcomeBackSlider />
              </FeatureContainer>
            </Box>
            <Footer>
              <Flex justifyContent="space-between">
                <Button
                  variant="text"
                  size="small"
                  backgroundOnHover="transparent"
                >
                  Contact Us
                </Button>
                <Flex>
                  <form>
                    <Checkbox
                      color="primary"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                    <CheckboxText>Turn off welcome screen</CheckboxText>
                    <Button type="button" onClick={onClose}>
                      Close
                    </Button>
                  </form>
                </Flex>
              </Flex>
            </Footer>
          </TutorialBody>
        </Card>
      </Content>
    </Modal>
  );
};

export default WelcomeBack;
