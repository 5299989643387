import { pick } from 'ramda';

import { CompareToType, Widget, WidgetId } from '../../../types';
import { EditWidgetFormValues } from './editWidgetTypes';

const parseCompareTo = (compareToVal?: string) => {
  if (!compareToVal) {
    return undefined;
  }

  // TODO: add PeersByRegion if it's ever needed
  switch (compareToVal) {
    case 'prior':
      return CompareToType.PriorPeriod;
    default:
      return undefined;
  }
};

export const transformFormToWidget = (
  id: WidgetId,
  formData: EditWidgetFormValues
): Partial<Widget> => {
  const {
    name,
    value,
    type,
    valueMetric,
    secondaryValue,
    secondaryValueMetric,
    compareTo: givenCompareTo,
    size,
    theme,
    filters,
  } = formData;
  const compareTo = parseCompareTo(givenCompareTo);

  return {
    id,
    name,
    value,
    valueMetric: valueMetric || undefined,
    secondaryValue,
    secondaryValueMetric: secondaryValueMetric || undefined,
    compareTo,
    size,
    type,
    theme,
    filters,
  };
};

export const transformDataToForm = (widget?: Widget): EditWidgetFormValues => {
  if (!widget) {
    return {
      name: '',
      filters: [],
    };
  }

  const { name, type, size, theme, filters } = widget;
  const valueOptions = pick(
    ['value', 'valueMetric', 'secondaryValue', 'secondaryValueMetric'],
    widget
  );

  return {
    name,
    type,
    size,
    theme,
    ...valueOptions,
    filters: filters || [],
  };
};
