import { WidgetType as WT } from '../../../types';
import { findWidget, REPORTS } from '../util';
import { dummyReportWidgetsData as transactionAnalysisData } from '../../report/dummyReportData';

const { transactionAnalysis } = REPORTS;

const data = transactionAnalysisData(transactionAnalysis.id);

export const totalSpendCallout = findWidget({
  name: 'Total Spend',
  type: WT.Callout,
  reportWidgets: data,
});

export const totalVolumeCallout = findWidget({
  name: 'Total Volume',
  type: WT.Callout,
  reportWidgets: data,
});

export const interchangeCallout = findWidget({
  name: 'Interchange',
  type: WT.Callout,
  reportWidgets: data,
});

export const totalActiveCardsCallout = findWidget({
  name: 'Total Active Cards',
  type: WT.Callout,
  reportWidgets: data,
});

export const totalSpendLineChart = findWidget({
  name: 'Total Spend',
  type: WT.LineChart,
  reportWidgets: data,
});

export const interchangeLineChart = findWidget({
  name: 'Interchange',
  type: WT.LineChart,
  reportWidgets: data,
});
