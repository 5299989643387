import React, { useState } from 'react';

import { HiChevronDown } from 'react-icons/hi';
import { Box, Flex } from 'rebass';

import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Select as OrigMUISelect, SelectProps } from '@mui/material';

import { HasTheme } from '../../styles/settings/Theme';
import { Button, ButtonProps } from './Buttons';
import MenuItem from './MenuItem';
import {
  Checkbox,
  HorizontalOrigin as Horizontal,
  Menu,
  VerticalOrigin as Vertical,
} from './MUIReExports';
import PillLabel from './PillLabel';

export const PLACEHOLDER_VALUE = '';

export const isPlaceholder = (value: string) => {
  return value === PLACEHOLDER_VALUE;
};

interface StyledSelectProps {
  fullWidth?: boolean;
  filter?: boolean;
}

const filterColor = ({ filter, theme }: StyledSelectProps & HasTheme) => css`
  ${filter
    ? theme.backgroundColor.background1
    : theme.backgroundColor.background2}
`;

// TODO: We shouldn't need to use hard type coercion for ThemeType here...
const MUISelect = styled(OrigMUISelect)<StyledSelectProps>`
  &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${filterColor};
  }
  &.MuiSelect-root {
    color: ${({ theme }) => theme.textColor.default};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  }
  .MuiOutlinedInput-input {
    padding: 10px 16px;
    background: ${filterColor};
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.backgroundColor.button.default};
    padding: 2px 2px 2px 2px;
    margin-top: 2px;
    margin-left: 5px;
  }

  .MuiSelect-select {
    :focus {
      background: ${filterColor};
    }
  }
  .MuiInputBase-input {
    color: ${({ theme }) => theme.textColor.default};
  }

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.backgroundColor.button.default};
    border-width: 1px;
  }
`;

export interface DropdownProps<ValueType>
  extends SelectProps,
    StyledSelectProps {
  placeholderDisabled?: boolean;
  value?: ValueType | ValueType[];
}

export const Dropdown: React.FC<DropdownProps<string>> = ({
  placeholder,
  placeholderDisabled,
  value: givenValue,
  children,
  ...props
}) => {
  const theme = useTheme();

  const menuProps = {
    anchorOrigin: {
      vertical: Vertical.bottom,
      horizontal: Horizontal.left,
    },
    transformOrigin: {
      vertical: Vertical.top,
      horizontal: Horizontal.left,
    },
    getContentAnchorEl: null,
    PaperProps: {
      sx: {
        marginTop: '5px',
        backgroundColor: theme.backgroundColor.background1,
        color: theme.textColor.text1,
        '&.MuiPaper-rounded': {
          borderRadius: '5px',
        },
        '&.MuiPaper-elevation8': {
          boxShadow: theme.shadows.widgetShadow,
        },
      },
    },
  };
  return (
    <MUISelect
      {...props}
      displayEmpty
      value={givenValue || PLACEHOLDER_VALUE}
      variant="outlined"
      MenuProps={menuProps}
      IconComponent={HiChevronDown}
    >
      {placeholder && (
        <MenuItem value={PLACEHOLDER_VALUE} disabled={placeholderDisabled}>
          <em>{placeholder}</em>
        </MenuItem>
      )}
      {children}
    </MUISelect>
  );
};

export interface DropdownItem {
  label: string;
  value: string;
  parentValue?: string;
}

interface LabelledDropdownProps extends DropdownProps<string> {
  dropdownItems: DropdownItem[];
  label: string;
}

interface LabelledDropdownValueProps {
  label: string;
  value: string;
}

export const LabelledDropdownValue: React.FC<LabelledDropdownValueProps> = ({
  label,
  value,
}) => {
  return (
    <Flex>
      <PillLabel>{label}</PillLabel>
      <Box mr={[2]} />
      {value}
    </Flex>
  );
};

export const LabelledDropdown: React.FC<LabelledDropdownProps> = ({
  dropdownItems,
  label,
  name,
  ...rest
}) => {
  return (
    <Dropdown
      aria-label={label}
      renderValue={(value) => (
        <LabelledDropdownValue
          label={label}
          value={
            dropdownItems.find((item) => item.value === value)?.label || ''
          }
        />
      )}
      {...rest}
    >
      {dropdownItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Dropdown>
  );
};

export const MultiselectCheckboxDropdown: React.FC<LabelledDropdownProps> = ({
  dropdownItems,
  label,
  name,
  value: selectedValues,
  ...rest
}) => {
  const renderValue = (value: unknown) => {
    const selections = value as string[];

    const valueText = (selectionLength: number) => {
      switch (selectionLength) {
        case 0:
          return '';
        case 1:
          return selections[0];
        default:
          return `${selections.length} selected`;
      }
    };

    return (
      <LabelledDropdownValue
        label={label}
        value={valueText(selections.length)}
      />
    );
  };

  return (
    <Dropdown
      multiple
      aria-label={label}
      renderValue={renderValue}
      value={selectedValues}
      {...rest}
    >
      {dropdownItems.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          <Box pl={item.parentValue ? 4 : 0}>
            <Checkbox
              color="primary"
              checked={selectedValues?.includes(item.value)}
            />
            {item.label}
          </Box>
        </MenuItem>
      ))}
    </Dropdown>
  );
};

interface DropdownBaseProps {
  className?: string;
  renderComponent: (
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    open: boolean
  ) => React.ReactNode;
  renderItems?: (closeMenu: () => void) => React.ReactNode;
}

export const DropdownBase: React.FC<DropdownBaseProps> = ({
  className,
  children,
  renderComponent,
  renderItems,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClicked = (event: React.MouseEvent<HTMLElement>) => {
    // Prevent the row click handler from triggering when this
    // button is clicked
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClosed = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    closeMenu();
  };

  return (
    <div className={className}>
      {renderComponent(onClicked, open)}

      <Menu
        // TODO: replace with menu.tsx in UI library
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onClosed}
        anchorOrigin={{
          vertical: Vertical.bottom,
          horizontal: Horizontal.right,
        }}
        transformOrigin={{
          vertical: Vertical.top,
          horizontal: Horizontal.right,
        }}
      >
        {children || (renderItems && renderItems(closeMenu))}
      </Menu>
    </div>
  );
};

interface ButtonDropdownProps
  extends Omit<DropdownBaseProps, 'renderComponent'> {
  buttonProps?: ButtonProps;
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  children,
  buttonProps,
  renderItems,
  ...props
}) => {
  return (
    <DropdownBase
      {...props}
      renderItems={renderItems}
      renderComponent={(onClick) => (
        <Button {...buttonProps} onClick={onClick}>
          {children}
        </Button>
      )}
    />
  );
};
