import { pick } from 'ramda';

import {
  QueryMetric,
  SelectOption,
  WidgetFilterOptions,
  WidgetType,
  WidgetValueOptions,
} from '../../types';

export const widgetTypeTitles: Record<WidgetType, string> = {
  [WidgetType.BarChart]: 'Bar Chart',
  [WidgetType.LineChart]: 'Line Chart',
  [WidgetType.Table]: 'Table',
  [WidgetType.Callout]: 'Callout',
};

const metricOptions: Record<QueryMetric, SelectOption<QueryMetric>> = {
  [QueryMetric.Average]: {
    value: QueryMetric.Average,
    label: 'Average',
  },
  [QueryMetric.Sum]: {
    value: QueryMetric.Sum,
    label: 'Sum',
  },
  [QueryMetric.Day]: {
    value: QueryMetric.Day,
    label: 'Day',
  },
  [QueryMetric.Hour]: {
    value: QueryMetric.Hour,
    label: 'Hour',
  },
};

const mainMetrics = pick([QueryMetric.Average, QueryMetric.Sum], metricOptions);
const xAxisMetrics = pick([QueryMetric.Hour, QueryMetric.Day], metricOptions);

export const widgetMainValueOptions: WidgetValueOptions = {
  spend: {
    value: 'spend',
    label: 'Spend',
    metrics: mainMetrics,
  },
  volume: {
    value: 'volume',
    label: 'Volume',
    metrics: mainMetrics,
  },
  interchange: {
    value: 'interchange',
    label: 'Interchange',
    metrics: mainMetrics,
  },
  cards: {
    value: 'cards',
    label: 'Cards',
    metrics: mainMetrics,
  },
  debit: {
    value: 'debit',
    label: 'Debit',
    metrics: mainMetrics,
  },
};

export const widgetXAxisValueOptions: WidgetValueOptions = {
  time: {
    value: 'time',
    label: 'Time',
    metrics: xAxisMetrics,
  },
};

export const barChartSecondaryValueOptions: WidgetValueOptions = {
  bin: {
    value: 'bin',
    label: 'BIN',
    metrics: {},
  },
  merchant: {
    value: 'merchant',
    label: 'Merchant',
    metrics: {},
  },
};

export const brokenDownOptions: Record<string, SelectOption> = {
  dummy: {
    value: 'dummy',
    label: 'Dummy',
  },
};

export const compareOptions: Record<string, SelectOption> = {
  prior: {
    value: 'prior',
    label: 'Prior Period',
  },
};

const operators1: SelectOption[] = [
  {
    value: 'Is one of',
    label: 'Is one of',
  },
];

export const widgetFilterOptions = (value: string): WidgetFilterOptions => {
  switch (value) {
    default:
      return {
        mcc: {
          value: 'mcc',
          label: 'MCC',
          operators: operators1,
          values: [
            {
              value: 'airlines',
              label: 'Airlines',
            },
            {
              value: 'grocery_stores',
              label: 'Grocery Stores',
            },
            {
              value: 'restaurants',
              label: 'Restaurants',
            },
            {
              value: 'delivery_services',
              label: 'Delivery Services',
            },
          ],
        },
        cardStatus: {
          value: 'cardStatus',
          label: 'Card Status',
          operators: operators1,
          values: [
            {
              value: 'active',
              label: 'Active',
            },
          ],
        },
      };
  }
};
