import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { paths } from '../../../routing/paths';
import { reportingFormValidation } from '../welcome/reportingFormValidation';
import { Card, Button, Input, FormControlLabel, Paragraph } from '../../atoms';

const ChangePassword = styled(Paragraph)`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSize.caption1};
  color: ${({ theme }) => theme.textColor.link};
  margin-top: -30px;
  margin-left: 15px;
  cursor: pointer;
`;

const ProfileCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  width: 1000px;
  box-shadow: none;
  border-left: 1px solid ${({ theme }) => theme.backgroundColor.element2};
  border-radius: 0;
`;

const ProfileInput = styled(Input)`
  width: 350px;
  margin-bottom: 2rem;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.backgroundColor.background2};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    align-items: flex-start;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ProfileEdit: React.FC = () => {
  const history = useHistory();

  return (
    <Flex>
      <Box>
        <ProfileCard>
          <Box py={[4, 5]} px={[3, 5]}>
            <Box mb={[3]} />
            <Formik
              initialValues={{ reportingAreas: [] }}
              validationSchema={reportingFormValidation}
              onSubmit={
                /* eslint-disable @typescript-eslint/no-unused-vars */
                (values, { setSubmitting }) => {
                  // Fill in real form submission once ready
                  history.push(paths.reports());
                  /* eslint-enable @typescript-eslint/no-unused-vars */
                }
              }
            >
              {({ isSubmitting }) => (
                <>
                  <Form>
                    <Grid>
                      <StyledFormControlLabel
                        control={
                          <ProfileInput
                            name="reportingAreas"
                            defaultValue="Jill"
                            color="primary"
                          />
                        }
                        // TODO: label should re-use a ui text component
                        label="First Name"
                        labelPlacement="top"
                      />
                      <StyledFormControlLabel
                        control={
                          <ProfileInput
                            name="reportingAreas"
                            defaultValue="Franklin"
                            color="primary"
                          />
                        }
                        label="Last Name"
                        labelPlacement="top"
                      />
                      <StyledFormControlLabel
                        control={
                          <ProfileInput
                            name="reportingAreas"
                            defaultValue="Fraud Analysis"
                            color="primary"
                          />
                        }
                        label="Role"
                        labelPlacement="top"
                      />
                      <StyledFormControlLabel
                        control={
                          <ProfileInput
                            name="reportingAreas"
                            defaultValue="Parliament Credit Union"
                            color="primary"
                          />
                        }
                        label="Company"
                        labelPlacement="top"
                      />
                      <StyledFormControlLabel
                        control={
                          <ProfileInput
                            name="reportingAreas"
                            defaultValue="jill@coop.com"
                            color="primary"
                          />
                        }
                        label="Email"
                        labelPlacement="top"
                      />
                      <div>
                        <StyledFormControlLabel
                          control={
                            <ProfileInput
                              name="reportingAreas"
                              defaultValue="••••••••••••"
                              color="primary"
                              endAdornment={
                                <InputAdornment position="end">
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              }
                            />
                          }
                          label="Password"
                          labelPlacement="top"
                        />
                        <ChangePassword>Change Password</ChangePassword>
                      </div>
                    </Grid>
                  </Form>

                  <Box mt={5}>
                    <Flex justifyContent="flex-end" alignItems="center">
                      <Button type="submit" disabled={isSubmitting}>
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        </ProfileCard>
      </Box>
    </Flex>
  );
};

export default ProfileEdit;
