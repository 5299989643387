import { SelectOption } from './reportFilters';

export enum WidgetType {
  BarChart = 'BarChart',
  LineChart = 'LineChart',
  Table = 'Table',
  Callout = 'Callout',
}

export enum WidgetTheme {
  Theme1 = 'Theme1',
  Theme2 = 'Theme2',
}

export enum WidgetSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

// TODO: add proper types
export interface WidgetFilter {
  attribute: string;
  operator: string;
  values: string[];
}

interface WidgetCommonFields {
  id: string;
  name: string;
  type: WidgetType;
  theme: WidgetTheme;
  size: WidgetSize;
  filters?: WidgetFilter[];
}

export type WidgetId = WidgetCommonFields['id'];

// TODO: add proper types
export type QueryField = string;
export type ByFieldWithTime = string;
export type ByFieldWithBIN = string;
export type ByFieldAll = ByFieldWithTime | ByFieldWithBIN;

export enum QueryMetric {
  Sum = 'sum',
  Average = 'average',
  Hour = 'hour',
  Day = 'day',
}

export enum CompareToType {
  PeersByRegion = 'PeersByRegion',
  PriorPeriod = 'PriorPeriod',
}

export interface CalloutWidget extends WidgetCommonFields {
  value: QueryField;
  valueMetric?: QueryMetric;
  compareTo?: CompareToType;
}

export interface CalloutData {
  main: string;
  compare: string;
}

export interface BarChartWidget extends WidgetCommonFields {
  value: QueryField;
  valueMetric: QueryMetric;
  secondaryValue: QueryField;
  compareTo?: CompareToType;
}
interface BarData {
  value: number;
  name: string;
}
export interface BarChartData {
  bars: BarData[];
}

export interface TableWidget extends WidgetCommonFields {
  columns: string[];
  compareTo?: CompareToType;
}

export interface TableData {
  rows: Record<string, string>[];
}

export interface LineChartWidget extends WidgetCommonFields {
  value: QueryField;
  valueMetric: QueryMetric;
  secondaryValue: ByFieldWithTime;
  secondaryValueMetric: QueryMetric;
  compareTo?: CompareToType;
}

interface LineChartPoint<X = Date, Y = number> {
  x: X;
  y: Y;
}

export type LineChartData<X = Date, Y = number> = LineChartPoint<X, Y>[];

export type Widget =
  | CalloutWidget
  | LineChartWidget
  | TableWidget
  | BarChartWidget;

export type WidgetData =
  | CalloutData
  | LineChartData[]
  | TableData
  | BarChartData;

export interface WidgetValueOption extends SelectOption<QueryField> {
  metrics: Partial<Record<QueryMetric, SelectOption<QueryMetric>>>;
}

export type WidgetValueOptions = Record<QueryField, WidgetValueOption>;

export interface WidgetFilterOptionSet extends SelectOption {
  operators: SelectOption[];
  values: SelectOption[];
}

export type WidgetFilterOptions = Record<string, WidgetFilterOptionSet>;
