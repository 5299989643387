import * as R from 'ramda';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Flex, FlexProps } from 'rebass';

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';

import { dummyUsers } from '../../../../data/users/dummyUserData';
import { ReportAlert, User } from '../../../../types';
import {
  Button,
  Dropdown,
  ErrorTextWithContainer,
  Input,
  MenuItem,
  Paragraph,
} from '../../../atoms';
import AutoCompleteContainer from '../AutoCompleteContainer';
import EmailToAutocomplete from '../EmailToAutoComplete';
import { setAlertModalValidation } from './setAlertModalValidation';
import { SetAlertFormValues } from './types';

const fullWidth = () => 'width: 100%';

export const LargeInput = styled(Input)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  ${fullWidth}
`;

export const AmountInput = styled(Input)`
  background: ${({ theme }) => theme.backgroundColor.background2};
`;

export const LargeDropdown = styled(Dropdown)`
  ${fullWidth}
`;

interface LabelledFieldProps extends FlexProps {
  labelFor: string;
  label: string;
}

const LabelledField: React.FC<LabelledFieldProps> = ({
  labelFor,
  label,
  children,
  ...props
}) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      <Box width={1 / 4}>
        <label htmlFor={labelFor}>
          <Paragraph>{label}</Paragraph>
        </label>
      </Box>

      <Box width={3 / 4}>{children}</Box>
    </Flex>
  );
};

const allUsers = R.values(dummyUsers);

const conditionFields = [
  {
    label: 'Interchange',
    value: 'interchange',
  },
  {
    label: 'Volume',
    value: 'volume',
  },
  {
    label: 'Spend',
    value: 'spend',
  },
];

const operatorFields = [
  {
    label: 'is less than',
    value: 'less',
  },
  {
    label: 'is greater than',
    value: 'greater',
  },
  {
    label: 'is equal to',
    value: 'equal',
  },
];

interface SetAlertProps {
  onSubmit: (data: SetAlertFormValues) => void;
  onCancel: () => void;
  existingAlert?: ReportAlert;
}

const SetAlertForm: React.FC<SetAlertProps> = ({
  existingAlert,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(() => {
    if (existingAlert) {
      return existingAlert;
    }

    return {
      title: '',
      emailTo: [],
    };
  }, [existingAlert]);

  const methods = useForm<SetAlertFormValues>({
    resolver: yupResolver(setAlertModalValidation),
    defaultValues,
  });

  const { handleSubmit, setValue, control } = methods;

  const handleEmailToChanged = (_event: unknown, newUsers: User[]) => {
    setValue('emailTo', newUsers, { shouldValidate: true });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LabelledField labelFor="alert-title" label="Alert Name" mb={3}>
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <LargeInput
                  type="text"
                  id="alert-title"
                  placeholder="Enter a name"
                  defaultValue={existingAlert?.title}
                  {...field}
                />
                {error && (
                  <ErrorTextWithContainer>
                    {error.message}
                  </ErrorTextWithContainer>
                )}
              </>
            )}
          />
        </LabelledField>

        <LabelledField
          labelFor="emailTo"
          label="Email to..."
          autoComplete="off"
          type="text"
        >
          <Controller
            name="emailTo"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <>
                <AutoCompleteContainer width={1} pb={2}>
                  <EmailToAutocomplete
                    chosenUsers={value}
                    users={allUsers}
                    onChange={handleEmailToChanged}
                  />
                </AutoCompleteContainer>

                {error && (
                  <ErrorTextWithContainer>
                    {error.message}
                  </ErrorTextWithContainer>
                )}
              </>
            )}
          />
        </LabelledField>

        <Box mt={4} />
        <Paragraph>Send Alert If</Paragraph>
        <Box mt={2} />
        <Box
          sx={{
            display: 'grid',
            gridGap: 3,
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          <Controller
            name="conditionField"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <Dropdown
                  fullWidth
                  placeholder="Select a value"
                  placeholderDisabled
                  {...field}
                >
                  {conditionFields.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Dropdown>

                {error && (
                  <ErrorTextWithContainer>
                    {error.message}
                  </ErrorTextWithContainer>
                )}
              </Box>
            )}
          />

          <Controller
            name="conditionOperator"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <Dropdown
                  fullWidth
                  placeholder="Select operator"
                  placeholderDisabled
                  {...field}
                >
                  {operatorFields.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Dropdown>

                {error && (
                  <ErrorTextWithContainer>
                    {error.message}
                  </ErrorTextWithContainer>
                )}
              </Box>
            )}
          />
          <Controller
            name="conditionAmount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <AmountInput
                  type="number"
                  placeholder="Enter amount"
                  defaultValue={existingAlert?.conditionAmount}
                  {...field}
                />
                {error && (
                  <ErrorTextWithContainer>
                    {error.message}
                  </ErrorTextWithContainer>
                )}
              </Box>
            )}
          />
        </Box>

        <Box mt={4} />

        <Flex justifyContent="flex-end">
          <Button variant="text" onClick={() => onCancel()}>
            Cancel
          </Button>

          <Box mr={3} />

          <Button type="submit">
            {existingAlert ? 'Update Alert' : 'Create Alert'}
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default SetAlertForm;
