import React from 'react';
import { IconType } from 'react-icons';
import { Flex } from 'rebass';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

interface Props
  extends ButtonProps,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  icon: IconType;
}

interface ButtonProps {
  active: boolean;
  variant: 'primary' | 'secondary';
  size?: 'small' | 'medium';
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  padding: ${({ size }) => (size === 'small' ? '5px' : '10px')};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.default.radius};
  background: ${({ variant, active, theme }) =>
    theme.backgroundColor.iconButton[variant][active ? 'active' : 'default']};

  &:hover {
    background: ${({ variant, theme }) =>
      theme.backgroundColor.iconButton[variant].hover};
  }
  &:target {
    background: ${({ variant, theme }) =>
      theme.backgroundColor.iconButton[variant].active};
  }
`;

const CustomIconButton: React.FC<Props> = ({
  active,
  icon,
  variant,
  size = 'medium',
  ...rest
}) => {
  const IconTagName = icon;
  const theme = useTheme();

  return (
    <StyledButton active={active} variant={variant} size={size} {...rest}>
      <Flex alignContent="center" justifyContent="center">
        <IconTagName
          color={active ? theme.textColor.primaryButton : theme.textColor.icon}
        />
      </Flex>
    </StyledButton>
  );
};

export default CustomIconButton;
