import React from 'react';

import styled from '@emotion/styled';

import { SharedBranchIframeImage } from '../../assets/images';

const StyledImage = styled.img`
  display: block;
`;

const IframeReport = () => {
  return <StyledImage src={SharedBranchIframeImage} alt="Iframe report" />;
};

export default IframeReport;
