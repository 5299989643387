import 'minireset.css';
import 'modern-normalize/modern-normalize.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'rc-time-picker/assets/index.css';

import React from 'react';

import { css, Global, withTheme } from '@emotion/react';

import { Base, MUI } from './elements/Base';
import { HasTheme } from './settings/Theme';

const GlobalStyles = withTheme(({ theme }: HasTheme) => (
  <Global
    styles={css`
      ${Base({ theme })}
      ${MUI({ theme })}
    `}
  />
));

export default GlobalStyles;
