import * as R from 'ramda';

import { hasSameValues } from '../../util/collections';
import {
  DetailedSegmentationFilters,
  FilterType,
  FilterTypeOptions as Filters,
  SegmentationFilters,
  SelectFilterSelection,
  SelectFilter,
  Widget,
  WidgetFilter,
} from '../../types';

interface HasId {
  id: string;
}

export interface ReportFilterContext {
  report: HasId;
  filters?: Filters;
  // additionalFilters: ... TODO: Not needed for demo so I'm skipping for now
  segmentationFilters?: SegmentationFilters;
  detailedSegmentationFilters?: DetailedSegmentationFilters;
  widget?: Widget;
}

//
// /////// Report
//
interface ReportMatcherArgs {
  reportId: string;
}

const ReportMatcher = ({ reportId }: ReportMatcherArgs) => ({
  __type: 'ReportMatcher',
  reportId,
  isOpen: () => ({ report }: ReportFilterContext) => {
    if (!report) {
      return false;
    }
    return report.id === reportId;
  },
});

export const report = (reportId: ReportMatcherArgs['reportId']) =>
  ReportMatcher({ reportId });

//
// /////// "Regular" Filters
//
interface FilterMatcherArgs {
  filterType: FilterType;
}

// TODO: make this work w/ custom date range start/end
const FilterMatcher = ({ filterType }: FilterMatcherArgs) => ({
  __type: 'FilterMatcher',
  filterType,
  hasValue: (value: string) => ({ filters }: ReportFilterContext) => {
    if (!filters) {
      return false;
    }
    const filterValue = filters[filterType];
    return filterValue === value;
  },
  // hasAnyValue: () => ({ filters }: ReportFilterContext & HasFilters) => {
  //   // TODO:
  // },
});

export const filter = (filterType: FilterMatcherArgs['filterType']) =>
  FilterMatcher({ filterType });

//
// /////// Segmentation Filters
//
interface SegmentationFilterMatcherArgs {
  segmentationFilterId: string | SelectFilter<string>;
}

const matchingSegmentationFilter = (
  givenCheckFilter: string | HasId,
  segmentationFilters: SegmentationFilters
) => {
  const checkFilter =
    typeof givenCheckFilter === 'string'
      ? { id: givenCheckFilter }
      : givenCheckFilter;
  return segmentationFilters[checkFilter.id];
};

const SegmentationFilterMatcher = ({
  segmentationFilterId,
}: SegmentationFilterMatcherArgs) => ({
  __type: 'SegmentationFilterMatcher',
  segmentationFilterId,
  hasValue: (value: string) => ({
    segmentationFilters,
    widget,
  }: ReportFilterContext) => {
    if (!segmentationFilters || !widget) {
      return false;
    }

    // TODO: figure out how to make this work for non-SelectFilterSelection
    // segmentation filters
    const segmentationFilter = matchingSegmentationFilter(
      segmentationFilterId,
      segmentationFilters
    );

    if (!segmentationFilter) {
      return false;
    }

    if (Array.isArray(segmentationFilter)) {
      // XXX: this is sort of a hack - ideally we'd match against the _real_ values of the type rather than converting strings to "values". This also breaks if the label and value aren't
      // just different by the inclusion of spaces
      const removeSpaces = (s: string) => s.replace(/ /g, '');
      const values = R.map(
        (s) => removeSpaces(R.prop('value', s)),
        segmentationFilter
      );
      return R.includes(value, values);
    }

    return (segmentationFilter as SelectFilterSelection).value === value;
  },
});

export const segmentationFilter = (
  segmentationFilterId: SegmentationFilterMatcherArgs['segmentationFilterId']
) => SegmentationFilterMatcher({ segmentationFilterId });

//
// /////// Detailed Segmentation Filters
//
// TODO: figure out how to refactor this + SegmentationFilters above?
interface DetailedSegmentationFilterMatcherArgs {
  detailedSegmentationFilterId: string | SelectFilter<string>;
}

const matchingDetailedSegmentationFilter = (
  givenCheckFilter: string | HasId,
  detailedSegmentationFilters: DetailedSegmentationFilters
) => {
  const checkFilter =
    typeof givenCheckFilter === 'string'
      ? { id: givenCheckFilter }
      : givenCheckFilter;
  return detailedSegmentationFilters[checkFilter.id];
};

const DetailedSegmentationFilterMatcher = ({
  detailedSegmentationFilterId,
}: DetailedSegmentationFilterMatcherArgs) => ({
  __type: 'DetailedSegmentationFilterMatcher',
  detailedSegmentationFilterId,
  hasValue: (value: string) => ({
    detailedSegmentationFilters,
  }: ReportFilterContext) => {
    if (!detailedSegmentationFilters) {
      return false;
    }

    // TODO: figure out how to make this work for non-SelectFilterSelection
    // segmentation filters
    const detailedSegmentationFilter = matchingDetailedSegmentationFilter(
      detailedSegmentationFilterId,
      detailedSegmentationFilters
    ) as SelectFilterSelection | undefined;

    if (!detailedSegmentationFilter) {
      return false;
    }

    return detailedSegmentationFilter.value === value;
  },
});

export const detailedSegmentationFilter = (
  detailedSegmentationFilterId: DetailedSegmentationFilterMatcherArgs['detailedSegmentationFilterId']
) => DetailedSegmentationFilterMatcher({ detailedSegmentationFilterId });

//
// /////// Widgets
//
interface WidgetMatcherArgs {
  widgetId: string; // TODO: allow passing widget too
}

const checkFilterMatches = (
  { operator, attribute, values }: Partial<WidgetFilter>,
  filters: WidgetFilter[]
) => {
  const foundField = R.find((givenFilter) => {
    const operatorMatches = operator ? givenFilter.operator === operator : true;
    const attributeMatches = attribute
      ? givenFilter.attribute === attribute
      : true;
    const hasMatchingValues = values
      ? hasSameValues(values, givenFilter.values)
      : true;

    return operatorMatches && attributeMatches && hasMatchingValues;
  }, filters);

  return !!foundField;
};

const WidgetFilterMatcher = ({ filterName }: { filterName: string }) => ({
  __type: 'WidgetFilterMatcher',
  filterName,
  including: ({ operator, values }: Partial<WidgetFilter>) => ({
    widget,
  }: ReportFilterContext) => {
    if (!widget || !widget.filters) {
      return false;
    }

    return checkFilterMatches(
      { operator, values, attribute: filterName },
      widget.filters
    );
  },
});

const WidgetMatcher = ({ widgetId }: WidgetMatcherArgs) => ({
  __type: 'WidgetMatcher',
  widgetId,
  exists: () => () => true,
  hasFilter: (filterName: string) => {
    return WidgetFilterMatcher({ filterName });
  },
});

export const widget = (widgetId: WidgetMatcherArgs['widgetId']) =>
  WidgetMatcher({ widgetId });
