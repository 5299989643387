import React, { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { HiChevronDown, HiChevronUp, HiSearch } from 'react-icons/hi';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';
import Switch from '@mui/material/Switch';

import { FilterType, FilterTypeOptions } from '../../types';
import {
  Button,
  Card,
  CheckboxGroup,
  FilterTag,
  Input,
  MultiCheckboxGroup,
  MultiselectCheckboxList,
  MultiselectCheckboxListBase,
  RadioSelect,
  RadioSelectList,
} from '../atoms';
import { VerticalTab } from '../molecules';

enum FilterMenu {
  Institutions = 'Institutions',
  Transactions = 'Transactions',
}

interface CheckboxItem {
  label: string;
  value: string;
}
interface DropdownItem {
  label: string;
  value: string;
}

type GroupValuesType = {
  [groupTitle: string]: {
    [rowTitle: string]: string[];
  };
};

type SetGroupValuesType = (
  value: GroupValuesType | ((prevValue: GroupValuesType) => GroupValuesType)
) => void;

interface ReportFiltersProps {
  filters: FilterTypeOptions;
  dateTypeDropdownItems: Record<string, DropdownItem>;
  dummyBinFilterData: CheckboxItem[];
  dummyMCCFilterData: CheckboxItem[];
  dummyDigitalWalletFilterData: CheckboxItem[];
  dummyInstitutions: CheckboxItem[];
  cardTypeOptions: CheckboxItem[];
  activityTypeOptions: CheckboxItem[];
  networkOptions: CheckboxItem[];
  transactionDetails: CheckboxGroup[];
  groupValues: GroupValuesType;
  setGroupValues: SetGroupValuesType;
  handleDateTypeChange: (selectedValues: string) => void;
  handleBinChange: (selectedValues: string[]) => void;
  handleNetworkChange: (selectedValues: string[]) => void;
  handleCardTypeChange: (selectedValues: string[]) => void;
  handleActivityChange: (selectedValues: string) => void;
  handleMCCChange: (selectedValues: string[]) => void;
  handleDigitalWalletChange: (selectedValues: string[]) => void;
  handleInstitutionChange: (selectedValues: string[]) => void;
  handleGroupChange: (
    groupTitle: string,
    rowTitle: string,
    values: string[],
    isChecked: boolean
  ) => void;
  applyFilters: () => void;
  closeWithoutChanges: () => void;
}

const FilterBar = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize40};
`;

const Line = styled.hr`
  background-color: ${({ theme }) => theme.backgroundColor.element1};
  flex-grow: 1;
  border: none;
  margin: 0 10px;
  height: 1px;
`;

const FilterLine = styled.hr`
  background-color: ${({ theme }) => theme.backgroundColor.element1};
  width: 100%;
  border: none;
  margin: 20px 0;
  height: 1px;
`;

const FilterContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
`;

const ReportFilterContainer = styled(Card)`
  display: flex;
  min-height: 500px;
  overflow: unset;
`;

const FilterSection = styled(Flex)`
  flex-grow: 2;
  flex-shrink: 0;
  flex-direction: row;
  padding: 30px 0 60px;
  width: 270px;
`;
const SummarySection = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.background2};
  padding: 12px 40px 40px 40px;
  width: 420px;
  position: relative;
`;

const SummaryTitle = styled.div`
  color: ${({ theme }) => theme.textColor.text3};
  font-size: ${({ theme }) => theme.fontSize.h5};
  margin: 12px 0 40px;
  text-align: center;
`;

const SelectedFiltersTallWrap = styled.div`
  height: calc(100% - 62px - 48px - 12px);
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 36px;
`;

const SelectedFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize40};
`;

const AppliedFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 8px 8px 8px 0;
  align-items: flex-start;
  border-right: 1px solid ${({ theme }) => theme.backgroundColor.element1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize40};

  > *:last-child {
    padding-right: 8px;
  }
`;

const AppliedFilterSummaryContainer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const AppliedFilterTitle = styled.div`
  font-weight: bold;
`;

const AppliedFilterCount = styled.div`
  color: ${({ theme }) => theme.textColor.text3};
  padding: 0 8px;
`;

const StyledFilterTitle = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 20.94px;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  padding-right: 12px;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    width: 0;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ButtonContainer = styled(Flex)`
  position: absolute;
  bottom: 0;
  margin: 12px 0 40px;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 80px);
`;

const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.backgroundColor.background2};
  width: 250px;
  color: ${({ theme }) => theme.textColor.text1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize40};
  margin-bottom: 8px;
  margin-left: 4px;
`;

const DisabledVerticalTab = styled(VerticalTab)`
  pointer-events: none;
  color: #ccc;
`;

const ReportFilters: React.FC<ReportFiltersProps> = ({
  filters,
  dateTypeDropdownItems,
  dummyBinFilterData,
  dummyMCCFilterData,
  dummyDigitalWalletFilterData,
  dummyInstitutions,
  cardTypeOptions,
  networkOptions,
  activityTypeOptions,
  transactionDetails,
  handleDateTypeChange,
  handleActivityChange,
  handleBinChange,
  handleMCCChange,
  handleDigitalWalletChange,
  handleGroupChange,
  handleInstitutionChange,
  handleCardTypeChange,
  handleNetworkChange,
  groupValues,
  setGroupValues,
  applyFilters,
  closeWithoutChanges,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const togglePreviewCollapse = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  const [selectedMenu, setSelectedMenu] = useState<FilterMenu>(
    FilterMenu.Transactions
  );

  const handleCloseWithoutChanges = () => {
    toggleCollapse();
    closeWithoutChanges();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleApplyFilters = () => {
    toggleCollapse();
    applyFilters();
    if (!isPreviewOpen) {
      togglePreviewCollapse();
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const appliedFilterCount = () => {
    let count = -1;

    // Removes 1 from count for the date range, temp fix until date range is figured out

    Object.keys(filters).forEach((filterType) => {
      const filterTypeKey = filterType as FilterType;
      if (filters[filterTypeKey] && filters[filterTypeKey].length > 0) {
        count += 1;
      }
    });

    // Count for group values
    Object.entries(groupValues).forEach(([, rows]) => {
      const isAnyRowSelected = Object.values(rows).some(
        (values) => values.length > 0
      );
      if (isAnyRowSelected) count += 1;
    });

    return count;
  };

  const conditionalFilterTags = (
    fType: keyof typeof FilterType,
    max: number
  ) => {
    const fCount = filters[fType].length;
    if (fCount >= max || fCount === 0) {
      return <FilterTag key="all">All</FilterTag>;
    }

    // TODO-POST: assert better
    return (filters[fType] as string[]).map((v) => (
      <FilterTag key={v}>{v}</FilterTag>
    ));
  };

  const PreviewFilters = () => (
    <Flex flexDirection="row" flexWrap="wrap">
      {/* {filters[FilterType.Institutions].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>Institutions:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.Institutions].map((institutionValue) => {
              const item = dummyInstitutions.find(
                (data) => data.value === institutionValue
              );
              return (
                <FilterTag key={institutionValue}>
                  {item ? item.label : institutionValue}
                </FilterTag>
              );
            })}
          </TagContainer>
        </AppliedFilterContainer>
      )} */}
      <AppliedFilterContainer>
        <StyledFilterTitle>Activity Type:</StyledFilterTitle>
        <TagContainer>
          {(() => {
            const activityValue = filters[FilterType.ActivityType];
            const item = activityTypeOptions.find(
              (data) => data.value === activityValue
            );
            return (
              <FilterTag key={activityValue}>
                {item ? item.label : activityValue}
              </FilterTag>
            );
          })()}
        </TagContainer>
      </AppliedFilterContainer>
      <AppliedFilterContainer>
        <StyledFilterTitle>Date Type:</StyledFilterTitle>
        <TagContainer>
          {(() => {
            const item = dateTypeDropdownItems[filters[FilterType.DateType]];
            return (
              <FilterTag key={filters[FilterType.DateType]}>
                {item ? item.label : filters[FilterType.DateType]}
              </FilterTag>
            );
          })()}
        </TagContainer>
      </AppliedFilterContainer>
      {filters[FilterType.CardType].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>BIN Type:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.CardType].length === cardTypeOptions.length ? (
              <FilterTag key="all">All</FilterTag>
            ) : (
              filters[FilterType.CardType].map((cardTypeValue) => (
                <FilterTag key={cardTypeValue}>{cardTypeValue}</FilterTag>
              ))
            )}
          </TagContainer>
        </AppliedFilterContainer>
      )}
      {filters[FilterType.Network].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>Network:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.Network].length === networkOptions.length ? (
              <FilterTag key="all">All</FilterTag>
            ) : (
              filters[FilterType.Network].map((networkValue) => (
                <FilterTag key={networkValue}>{networkValue}</FilterTag>
              ))
            )}
          </TagContainer>
        </AppliedFilterContainer>
      )}
      {filters[FilterType.BIN].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>BIN:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.BIN].length === dummyBinFilterData.length ? (
              <FilterTag key="all">All</FilterTag>
            ) : (
              filters[FilterType.BIN].map((binValue) => (
                <FilterTag key={binValue}>{binValue}</FilterTag>
              ))
            )}
          </TagContainer>
        </AppliedFilterContainer>
      )}
      {filters[FilterType.MCCGroup].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>MCC Group:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.MCCGroup].length ===
            dummyMCCFilterData.length ? (
              <FilterTag key="all">All</FilterTag>
            ) : (
              filters[FilterType.MCCGroup].map((mccValue) => {
                const item = dummyMCCFilterData.find(
                  (data) => data.value === mccValue
                );
                return (
                  <FilterTag key={mccValue}>
                    {item ? item.label : mccValue}
                  </FilterTag>
                );
              })
            )}
          </TagContainer>
        </AppliedFilterContainer>
      )}
      {filters[FilterType.DigitalWallet].length > 0 && (
        <AppliedFilterContainer>
          <StyledFilterTitle>Digital Wallet:</StyledFilterTitle>
          <TagContainer>
            {filters[FilterType.DigitalWallet].length ===
            dummyDigitalWalletFilterData.length ? (
              <FilterTag key="all">All</FilterTag>
            ) : (
              filters[FilterType.DigitalWallet].map((walletValue) => {
                const item = dummyDigitalWalletFilterData.find(
                  (data) => data.value === walletValue
                );
                return (
                  <FilterTag key={walletValue}>
                    {item ? item.label : walletValue}
                  </FilterTag>
                );
              })
            )}
          </TagContainer>
        </AppliedFilterContainer>
      )}
      {Object.entries(groupValues).map(([groupTitle, rows]) => {
        // Check if any row in the group has selected values
        const isAnyRowSelected = Object.values(rows).some(
          (values) => values.length > 0
        );

        // Only render the container if at least one row in the group has selected values
        if (isAnyRowSelected) {
          return (
            <AppliedFilterContainer key={groupTitle}>
              <StyledFilterTitle>{groupTitle}:</StyledFilterTitle>
              <TagContainer>
                {Object.entries(rows).map(([rowTitle, values]) => {
                  const totalItems = transactionDetails
                    .find((group) => group.groupTitle === groupTitle)
                    ?.rows.find((row) => row.rowTitle === rowTitle)
                    ?.checkboxItems.length;

                  const allSelected = values.length === totalItems;

                  return (
                    <React.Fragment key={rowTitle}>
                      {allSelected ? (
                        <FilterTag key="all">{`All ${rowTitle}s`}</FilterTag>
                      ) : (
                        values.map((value) => {
                          const label =
                            transactionDetails
                              .find((group) => group.groupTitle === groupTitle)
                              ?.rows.find((row) => row.rowTitle === rowTitle)
                              ?.checkboxItems.find(
                                (item) => item.value === value
                              )?.label || value;
                          return <FilterTag key={value}>{label}</FilterTag>;
                        })
                      )}
                    </React.Fragment>
                  );
                })}
              </TagContainer>
            </AppliedFilterContainer>
          );
        }
        return null; // Return null if no rows are selected in this group
      })}
    </Flex>
  );

  const VerticalTabGroups = () => (
    <Box flex={['0 1 230px']} paddingRight="12px">
      {/* <VerticalTab
        active={selectedMenu === FilterMenu.Institutions}
        onClick={() => setSelectedMenu(FilterMenu.Institutions)}
      >
        Institutions
      </VerticalTab> */}
      <VerticalTab
        active={selectedMenu === FilterMenu.Transactions}
        onClick={() => setSelectedMenu(FilterMenu.Transactions)}
      >
        Transactions
      </VerticalTab>
      {/* If kept for future, add 'disabled' prop to the molecule */}
      <DisabledVerticalTab active={false}>Members</DisabledVerticalTab>
      <DisabledVerticalTab active={false}>Programs</DisabledVerticalTab>
    </Box>
  );

  const ReportFiltersSummary = () => (
    <SummarySection>
      <SummaryTitle>Filter Selection Preview</SummaryTitle>
      <SelectedFiltersTallWrap>
        <StickyContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>Activity Type:</StyledFilterTitle>
            <TagContainer>
              {(() => {
                const activityValue = filters[FilterType.ActivityType];
                const item = activityTypeOptions.find(
                  (data) => data.value === activityValue
                );
                return (
                  <FilterTag key={activityValue}>
                    {item ? item.label : activityValue}
                  </FilterTag>
                );
              })()}
            </TagContainer>
          </SelectedFilterContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>Date Type:</StyledFilterTitle>
            <TagContainer>
              {(() => {
                const item =
                  dateTypeDropdownItems[filters[FilterType.DateType]];
                return (
                  <FilterTag key={filters[FilterType.DateType]}>
                    {item ? item.label : filters[FilterType.DateType]}
                  </FilterTag>
                );
              })()}
            </TagContainer>
          </SelectedFilterContainer>

          {/* filters[FilterType.Institutions].length > 0 && (
            <SelectedFilterContainer>
              <StyledFilterTitle>Institutions:</StyledFilterTitle>
              <TagContainer>
                {filters[FilterType.Institutions].map((institutionValue) => {
                  const item = dummyInstitutions.find(
                    (data) => data.value === institutionValue
                  );
                  return (
                    <FilterTag key={institutionValue}>
                      {item ? item.label : institutionValue}
                    </FilterTag>
                  );
                })}
              </TagContainer>
            </SelectedFilterContainer>
          ) */}
          <SelectedFilterContainer>
            <StyledFilterTitle>BIN Type:</StyledFilterTitle>
            <TagContainer>
              {conditionalFilterTags(
                FilterType.CardType,
                cardTypeOptions.length
              )}
            </TagContainer>
          </SelectedFilterContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>Network:</StyledFilterTitle>
            <TagContainer>
              {conditionalFilterTags(FilterType.Network, networkOptions.length)}
            </TagContainer>
          </SelectedFilterContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>BIN:</StyledFilterTitle>
            <TagContainer>
              {conditionalFilterTags(FilterType.BIN, dummyBinFilterData.length)}
            </TagContainer>
          </SelectedFilterContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>MCC Group:</StyledFilterTitle>
            <TagContainer>
              {conditionalFilterTags(
                FilterType.MCCGroup,
                dummyMCCFilterData.length
              )}
            </TagContainer>
          </SelectedFilterContainer>
          <SelectedFilterContainer>
            <StyledFilterTitle>Digital Wallet:</StyledFilterTitle>
            <TagContainer>
              {conditionalFilterTags(
                FilterType.DigitalWallet,
                dummyDigitalWalletFilterData.length
              )}
            </TagContainer>
          </SelectedFilterContainer>
          {Object.entries(groupValues).map(([groupTitle, rows]) => {
            // Check if any row in the group has selected values
            /* const isAnyRowSelected = Object.values(rows).some(
              (values) => values.length > 0
            ); */

            return (
              <SelectedFilterContainer key={groupTitle}>
                <StyledFilterTitle>{groupTitle}:</StyledFilterTitle>
                <TagContainer>
                  {Object.entries(rows).map(([rowTitle, values]) => {
                    const totalItems = transactionDetails
                      .find((group) => group.groupTitle === groupTitle)
                      ?.rows.find((row) => row.rowTitle === rowTitle)
                      ?.checkboxItems.length;

                    const allSelected = values.length === totalItems;
                    const noneSelected = values.length === 0;

                    return (
                      <React.Fragment key={rowTitle}>
                        {allSelected || noneSelected ? (
                          <FilterTag key="all">{`All ${rowTitle}s`}</FilterTag>
                        ) : (
                          values.map((value) => {
                            const label =
                              transactionDetails
                                .find(
                                  (group) => group.groupTitle === groupTitle
                                )
                                ?.rows.find((row) => row.rowTitle === rowTitle)
                                ?.checkboxItems.find(
                                  (item) => item.value === value
                                )?.label || value;
                            return <FilterTag key={value}>{label}</FilterTag>;
                          })
                        )}
                      </React.Fragment>
                    );
                  })}
                </TagContainer>
              </SelectedFilterContainer>
            );

            return null; // Return null if no rows are selected in this group
          })}
        </StickyContainer>
      </SelectedFiltersTallWrap>
      <ButtonContainer>
        <Button variant="outlined" onClick={handleCloseWithoutChanges} small>
          Close Without Changes
        </Button>
        <Button onClick={handleApplyFilters} small>
          Apply Filters
        </Button>
      </ButtonContainer>
    </SummarySection>
  );

  return (
    <motion.div>
      <FilterBar>
        <AppliedFilterSummaryContainer onClick={togglePreviewCollapse}>
          <AppliedFilterTitle>Applied Filters</AppliedFilterTitle>{' '}
          <AppliedFilterCount>({appliedFilterCount()})</AppliedFilterCount>
          {isPreviewOpen ? (
            <HiChevronUp color="primary" fontSize="inherit" />
          ) : (
            <HiChevronDown color="primary" fontSize="inherit" />
          )}
        </AppliedFilterSummaryContainer>
        <Line />
        <div>
          <Switch onChange={toggleCollapse} checked={isOpen} color="primary" />
          Edit Report Filters
        </div>
      </FilterBar>
      <AnimatePresence>
        {isPreviewOpen && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
          >
            <PreviewFilters />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div>
            <ReportFilterContainer>
              <FilterSection>
                <VerticalTabGroups />
                {selectedMenu === FilterMenu.Transactions ? (
                  <AnimatePresence>
                    <FilterContainer>
                      <RadioSelect
                        options={activityTypeOptions}
                        label="Activity Type"
                        value={filters[FilterType.ActivityType]}
                        onChange={handleActivityChange}
                        disabled
                      />
                      <FilterLine />
                      <RadioSelectList
                        options={dateTypeDropdownItems}
                        label="Date Type"
                        value={filters[FilterType.DateType]}
                        onChange={handleDateTypeChange}
                      />
                      <FilterLine />

                      <MultiselectCheckboxList
                        checkboxItems={cardTypeOptions}
                        label="BIN Type"
                        value={filters[FilterType.CardType]}
                        onChange={handleCardTypeChange}
                        includeAllOption
                      />
                      <FilterLine />
                      <MultiselectCheckboxList
                        checkboxItems={networkOptions}
                        label="Networks"
                        value={filters[FilterType.Network]}
                        onChange={handleNetworkChange}
                        includeAllOption
                      />
                      <FilterLine />
                      <MultiselectCheckboxList
                        value={filters[FilterType.BIN]}
                        onChange={handleBinChange}
                        checkboxItems={dummyBinFilterData}
                        includeAllOption
                        label="BINs"
                      />
                      <FilterLine />
                      <MultiselectCheckboxList
                        value={filters[FilterType.MCCGroup]}
                        onChange={handleMCCChange}
                        checkboxItems={dummyMCCFilterData}
                        includeAllOption
                        label="MCC Group"
                      />
                      <FilterLine />
                      <MultiselectCheckboxList
                        value={filters[FilterType.DigitalWallet]}
                        onChange={handleDigitalWalletChange}
                        checkboxItems={dummyDigitalWalletFilterData}
                        includeAllOption
                        label="Digital Wallet"
                      />
                      <FilterLine />
                      <MultiCheckboxGroup
                        title="Transaction Details"
                        groups={transactionDetails}
                        handleGroupChange={handleGroupChange}
                        groupValues={groupValues}
                        setGroupValues={setGroupValues}
                      />
                    </FilterContainer>
                  </AnimatePresence>
                ) : selectedMenu === FilterMenu.Institutions ? (
                  <FilterContainer>
                    <StyledInput
                      type="text"
                      placeholder="Search an Institution"
                      startAdornment={
                        <InputAdornment position="start">
                          <HiSearch />
                        </InputAdornment>
                      }
                    />
                    <MultiselectCheckboxListBase
                      value={filters[FilterType.Institutions]}
                      onChange={handleInstitutionChange}
                      checkboxItems={dummyInstitutions}
                    />
                  </FilterContainer>
                ) : (
                  <div />
                )}
              </FilterSection>
              <ReportFiltersSummary />
            </ReportFilterContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ReportFilters;
