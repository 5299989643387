import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { Box, Flex } from 'rebass';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tabs } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

import { widgetTypeTitles } from '../../../data/widget/widgetDisplayData';
import { Widget, WidgetSize, WidgetTheme, WidgetType } from '../../../types';
import {
  widgetSizeDisabled,
  widgetTypeSizeOptions,
} from '../../../util/widget';
import {
  Button,
  Card,
  CardBody,
  CardDivider,
  Dropdown,
  ErrorTextWithContainer,
  IconButton,
  Input,
  MenuItem,
  Modal,
  Paragraph,
  RadioButton,
  Tab,
  TabPanel,
} from '../../atoms';
import { ModalProps } from '../../atoms/Modal';
import EditWidgetFilters from './EditWidgetFilters';
import EditWidgetPreview from './EditWidgetPreview';
import { EditWidgetFormValues } from './editWidgetTypes';
import { transformDataToForm } from './editWidgetUtils';
import { editWidgetValidation } from './editWidgetValidation';
import SetupSecondaryOptions from './SetupSecondaryOptions';
import WidgetSizePreview from './WidgetSizePreview';
import WidgetThemeColorBlocks from './WidgetThemeColorBlocks';

const Container = styled(Box)`
  width: 1200px;
`;

const SecondaryContent = styled(Box)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InnerCard = styled(Card)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  box-shadow: none;
`;

const WidgetSizeContainer = styled(Flex)`
  background: ${({ theme }) => theme.backgroundColor.background1};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  padding: 25px;
`;

const SmallDropdown = styled(Dropdown)`
  width: 50%;
`;

const LargeInput = styled(Input)`
  width: 75%;
  background: ${({ theme }) => theme.backgroundColor.background2};
  .MuiInputBase-input,
  .MuiInput-input,
  .Mui-focused,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.backgroundColor.background2};
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
  }
`;

const SecondaryTitle = styled(Paragraph)`
  padding: 0px 30px;
`;

const SaveContainer = styled(Flex)`
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 25px;
`;

const ErrorBoxTwo = styled(ErrorTextWithContainer)`
  bottom: -18px;
`;

const ThemeDropdown = styled(Dropdown)`
  width: 200px;
  div {
    display: flex;
    > * {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
`;

const EditWidgetCardBody = styled(CardBody)`
  min-height: 450px;
  max-height: 450px;
  overflow-y: auto;
  overflow: -moz-scrollbars-vertical;
`;

interface Props extends Omit<ModalProps, 'children' | 'onSubmit'> {
  newWidget?: boolean;
  widget?: Widget;
  onClose: () => void;
  onSubmit: (data: EditWidgetFormValues) => void;
}

const sizesRadioData = [
  {
    label: 'S',
    'aria-label': 'Small',
    id: 'small-size',
    value: WidgetSize.Small,
  },
  {
    label: 'M',
    'aria-label': 'Medium',
    id: 'medium-size',
    value: WidgetSize.Medium,
  },
  {
    label: 'L',
    'aria-label': 'Large',
    id: 'large-size',
    value: WidgetSize.Large,
  },
];

const EditWidgetModal: React.FC<Props> = ({
  newWidget,
  widget,
  onClose: givenOnClose,
  onSubmit: givenOnSubmit,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const methods = useForm<EditWidgetFormValues>({
    defaultValues: transformDataToForm(widget),
    resolver: yupResolver(editWidgetValidation),
  });

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { isSubmitSuccessful, isSubmitting, errors },
  } = methods;

  const watchType = watch('type');

  // https://react-hook-form.com/api/useform/reset
  // Recommended to reset with useEffect hook, rather than onsubmit callback
  useEffect(() => {
    if (isSubmitSuccessful && newWidget) {
      reset();
    }
  }, [isSubmitSuccessful, newWidget, reset]);

  const onSubmit: SubmitHandler<EditWidgetFormValues> = (formData) => {
    givenOnSubmit(formData);
    givenOnClose();
  };

  const handleTabChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setCurrentTab(newValue);
  };

  const handleClose: ModalProps['onClose'] = () => {
    reset();
    givenOnClose();
  };

  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    // Fix bug w/ react-grid-layout drag underneath modal
    // https://github.com/react-grid-layout/react-grid-layout/issues/869
    event.stopPropagation();
  };

  const Theme = useTheme();

  const StyledTabs = withStyles({
    root: {
      backgroundColor: Theme.backgroundColor.background2,
      color: Theme.textColor.text2,
    },
    indicator: {
      backgroundColor: Theme.backgroundColor.button.default,
    },
  })(Tabs);

  const StyledTab = withStyles(() =>
    createStyles({
      root: {
        textTransform: 'none',
        '&:hover': {
          color: Theme.backgroundColor.button.default,
          opacity: 1,
        },
        '&.Mui-selected': {
          color: Theme.backgroundColor.button.default,
          backgroundColor: Theme.backgroundColor.background1,
          fontWeight: 'bold',
        },
        '&:focus': {
          color: Theme.backgroundColor.button.default,
        },
      },
      selected: {},
    })
  )(Tab);

  return (
    <Modal onClose={handleClose} onMouseDown={onMouseDown} {...props}>
      <Container>
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex>
                <Box width="650px">
                  <CardBody padding="medium">
                    <Paragraph>{newWidget ? 'New' : 'Edit'} Widget</Paragraph>
                  </CardBody>
                  <CardDivider />

                  <StyledTabs
                    variant="fullWidth"
                    value={currentTab}
                    onChange={handleTabChange}
                    aria-label="Widget settings tabs"
                  >
                    <StyledTab disableRipple label="1. Set up" />
                    <StyledTab disableRipple label="2. Filters" />
                  </StyledTabs>
                  <CardDivider style={{ position: 'relative' }} />

                  <EditWidgetCardBody>
                    <TabPanel value={currentTab} index={0}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <label htmlFor="widget-name">
                          <Paragraph>Widget Name</Paragraph>
                        </label>

                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <LargeInput
                              type="text"
                              id="widget-name"
                              {...field}
                            />
                          )}
                        />
                      </Flex>

                      {errors.name && (
                        <ErrorTextWithContainer>
                          {errors.name.message}
                        </ErrorTextWithContainer>
                      )}

                      <Box mb={3} />

                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        pt={1}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          width={1}
                        >
                          <label htmlFor="widget-type">
                            <Paragraph>Widget Type</Paragraph>
                          </label>
                          <Controller
                            name="type"
                            control={control}
                            render={({
                              field: { onChange, ...restFieldProps },
                            }) => (
                              <SmallDropdown
                                placeholder="Select a type"
                                placeholderDisabled
                                id="widget-type"
                                onChange={(e) => {
                                  onChange(e);
                                  const newType = e.target.value as WidgetType;
                                  setValue(
                                    'size',
                                    widgetTypeSizeOptions[newType][0]
                                  );
                                  setValue('value', '');
                                  setValue('valueMetric', '');
                                  setValue('secondaryValue', '');
                                  setValue('secondaryValueMetric', '');
                                }}
                                {...restFieldProps}
                              >
                                {Object.values(WidgetType).map((type) => (
                                  <MenuItem value={type} key={type}>
                                    {widgetTypeTitles[type]}
                                  </MenuItem>
                                ))}
                              </SmallDropdown>
                            )}
                          />
                        </Flex>
                        {errors.type && (
                          <ErrorBoxTwo>{errors.type.message}</ErrorBoxTwo>
                        )}
                        <Flex
                          alignItems="center"
                          width={1}
                          justifyContent="space-between"
                        >
                          <label htmlFor="widget-theme">
                            <SecondaryTitle>Theme</SecondaryTitle>
                          </label>
                          <Controller
                            name="theme"
                            control={control}
                            render={({ field }) => (
                              <ThemeDropdown
                                placeholder="Select color theme"
                                placeholderDisabled
                                id="widget-theme"
                                {...field}
                              >
                                {Object.values(WidgetTheme).map((theme) => (
                                  <MenuItem value={theme} key={theme}>
                                    <WidgetThemeColorBlocks
                                      widgetTheme={theme}
                                    />
                                  </MenuItem>
                                ))}
                              </ThemeDropdown>
                            )}
                          />
                          {errors.theme && (
                            <ErrorBoxTwo>{errors.theme.message}</ErrorBoxTwo>
                          )}
                        </Flex>
                      </Flex>

                      <Box py={[3]}>
                        <CardDivider />
                      </Box>

                      {watchType && (
                        <SetupSecondaryOptions widgetType={watchType} />
                      )}
                      <Flex
                        justifyContent="center"
                        alignItems="flex-end"
                        pt={3}
                      >
                        <Button
                          bold
                          variant="text"
                          onClick={() => setCurrentTab(1)}
                        >
                          Go to Filters
                        </Button>
                      </Flex>
                    </TabPanel>

                    <TabPanel value={currentTab} index={1}>
                      <EditWidgetFilters />
                    </TabPanel>
                  </EditWidgetCardBody>
                </Box>
                <SecondaryContent width="550px">
                  <CardBody padding="medium">
                    <Flex justifyContent="flex-end">
                      <IconButton
                        icon={HiX}
                        aria-controls="Close modal"
                        onClick={() => handleClose({}, 'escapeKeyDown')}
                        active={false}
                        variant="secondary"
                        size="small"
                      />
                    </Flex>

                    <InnerCard>
                      <CardBody>
                        <Controller
                          name="size"
                          control={control}
                          render={({ field: { value, ...restFieldProps } }) => (
                            <Flex justifyContent="center">
                              <WidgetSizeContainer>
                                <Flex
                                  flexDirection="column"
                                  justifyContent="space-between"
                                >
                                  <Paragraph>Widget Size</Paragraph>

                                  <Box
                                    sx={{
                                      display: 'grid',
                                      gridGap: 3,
                                      gridAutoFlow: 'column',
                                    }}
                                  >
                                    {sizesRadioData.map((size) => (
                                      <RadioButton
                                        key={size.id}
                                        label={size.label}
                                        aria-label={size['aria-label']}
                                        id={size.id}
                                        value={size.value}
                                        checked={size.value === value}
                                        disabled={widgetSizeDisabled(
                                          size.value,
                                          watchType
                                        )}
                                        {...restFieldProps}
                                      />
                                    ))}
                                  </Box>
                                </Flex>

                                <Box ml={[4]} width="80px">
                                  <WidgetSizePreview size={value} />
                                </Box>
                              </WidgetSizeContainer>
                            </Flex>
                          )}
                        />
                      </CardBody>
                    </InnerCard>

                    <EditWidgetPreview />

                    <Box mb={3} />
                    <SaveContainer>
                      <Button type="submit" disabled={isSubmitting}>
                        {newWidget ? 'Create' : 'Save'}
                      </Button>
                    </SaveContainer>
                  </CardBody>
                </SecondaryContent>
              </Flex>
            </form>
          </FormProvider>
        </Card>
      </Container>
    </Modal>
  );
};

export default EditWidgetModal;
