import * as R from 'ramda';
import { HiDotsVertical } from 'react-icons/hi';
import { DropdownBase, IconButton } from '../../atoms';
import { ReportMenuItems } from '../../organisms';
import { MenuItems } from '../../organisms/ReportMenuItems';

const ReportActions: React.FC = () => {
  const onMenuItemClicked = R.curry(
    (
      closeMenu: () => void,
      _menuItem: MenuItems,
      event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      event.stopPropagation();

      // TODO: real implementation would probably dispatch based on the clicked item
      closeMenu();
    }
  );

  return (
    <DropdownBase
      renderItems={(closeMenu) => (
        <ReportMenuItems
          closeMenu={closeMenu}
          onMenuItemClick={onMenuItemClicked}
        />
      )}
      renderComponent={(onClick, open) => (
        <IconButton
          icon={HiDotsVertical}
          aria-controls="Report actions"
          onClick={onClick}
          active={open}
          variant="secondary"
          size="small"
        />
      )}
    />
  );
};

export default ReportActions;
