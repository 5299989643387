import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { dummyReportsData } from '../../data/reports/dummyReportsData';
import { widgetDetailData } from '../../data/widget/dummyWidgetData';
import { paths } from '../../routing/paths';
import {
  Breadcrumbs,
  Button,
  H3,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../atoms';
import { Layout } from '../templates';

// const FullWidthTableContainer = styled(TableContainer)`
//   height: 75%;
//   overflow-x: initial;
// `;

// const FullWidthTable = styled(Table)``;

// const EmptyTableCell = styled(TableCell)`
//   width: 65px;
// `;

// padding: 5px 20px !important;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.backgroundColor.rowHover};
  }
`;

const DataTableCell = styled(TableCell)``;

const DUMMY_REPORT_ID = '1';

const getWidgetData = (): Record<string, string>[] => {
  return widgetDetailData;
};

const getReport = () => {
  return dummyReportsData[DUMMY_REPORT_ID];
};

const WidgetData: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const reportWidget = dummyReportsData[DUMMY_REPORT_ID].widgets[id];
  const widgetData = getWidgetData();
  const report = getReport();

  return (
    <Layout>
      <Flex mb={3}>
        <Breadcrumbs
          segments={[
            { name: 'Reports', path: paths.reports() },
            { name: report.name, path: paths.report(report.id) },
            { name: reportWidget.widget.name, path: undefined },
          ]}
        />
      </Flex>
      <Flex justifyContent="space-between" mb={4}>
        <H3>{report.name} [Source Data]</H3>

        <Flex>
          <Button component={Link} to={paths.report(report.id)} variant="text">
            Back to Report
          </Button>
          <Box mr={[3]} />
          <Button>Export Data</Button>
        </Flex>
      </Flex>

      <Table
        aria-labelledby={`${reportWidget.widget.name} table`}
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {Object.keys(widgetData[0]).map((header) => (
              <TableCell align="left" key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {widgetData.map((row) => (
            <StyledTableRow hover key={row.id}>
              {Object.entries(row).map(([key, cell]) => (
                <DataTableCell align="left" key={key}>
                  {cell}
                </DataTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Layout>
  );
};

export default WidgetData;
