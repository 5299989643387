import styled from '@emotion/styled';

import { H5 } from './Headers';

const PillLabel = styled(H5)`
  text-transform: uppercase;
  font-weight: bold;
`;

export default PillLabel;
