import React from 'react';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { useAppDispatch } from '../../../data/hooks';
import {
  clearNotificationsById,
  useNotifications,
} from '../../../data/notifications/notificationsSlice';
import { Notification } from '../../../types';
import { Button, Headline, NotificationIcon } from '../../atoms';

import { NotificationMenuItem } from '../../molecules';

const Container = styled(Box)`
  background: ${({ theme }) => theme.backgroundColor.background2};
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.4s ease;
  overflow: -moz-scrollbars-vertical;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.borders.default.radius};
    background-color: ${({ theme }) => theme.backgroundColor.element1};
  }
`;

const HeaderContainer = styled(Flex)`
  overflow: hidden;
  white-space: nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
  min-width: 300px;
  margin-top: 16px;
`;

const NotificationsMenu = () => {
  const notifications: Record<string, Notification> = useNotifications();

  const dispatch = useAppDispatch();

  const clearAll = () => {
    dispatch(clearNotificationsById(Object.keys(notifications)));
  };

  return (
    <Container p="8px">
      <HeaderContainer>
        <Flex alignItems="center" verticalAlign="center">
          <NotificationIcon />
          <Box mx={1} />
          <Headline>Notifications</Headline>
        </Flex>

        <Button
          variant="text"
          backgroundOnHover="transparent"
          bold
          size="small"
          onClick={() => clearAll()}
        >
          Clear All
        </Button>
      </HeaderContainer>
      <AnimateSharedLayout>
        <AnimatePresence>
          {Object.values(notifications).map((notification) => (
            <Box mb="8px" key={notification.id}>
              <NotificationMenuItem notification={notification} />
            </Box>
          ))}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Container>
  );
};

export default NotificationsMenu;
