import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Grid,
  Label,
  Legend as DevExtremeLegend,
  Series,
  SeriesTemplate,
  Size,
  Tick,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';
import { Box } from 'rebass';
import * as R from 'ramda';
import { useTheme } from '@emotion/react';

import {
  ChartValueFormatOption,
  LineChartData,
  ValueTextFn,
} from '../../types';
import { ColorPalette } from '../../types/common';
import { Caption1, H4 } from '../atoms';

interface LineChartProps {
  data: LineChartData[];
  colorPalette: ColorPalette;
  tooltipTitle: string;
  xAxisLabel?: ValueTextFn;
  yAxisLabel?: ValueTextFn;
  xValueTooltipFormat?: ChartValueFormatOption;
  yValueTooltipFormat?: ChartValueFormatOption;
}

// https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/tooltip/#contentComponent
interface ContentRenderInfo {
  valueText: string;
  argumentText: string;
}
interface TooltipProps extends ContentRenderInfo {
  title: string;
}

const TooltipTemplate: React.FC<TooltipProps> = ({
  title,
  valueText,
  argumentText,
}: TooltipProps) => {
  return (
    <div>
      <Box>
        <Caption1>{title}</Caption1>
      </Box>

      <Box>
        <Caption1>{argumentText}</Caption1>
      </Box>

      <H4>{valueText}</H4>
    </div>
  );
};

const chartify = (dataArr: LineChartData[]) => {
  // Adds all line chart point arrays into a single
  // array of points where each point is distinguished
  // via "series" key, ie index in the initial array.
  //
  // We do this so SeriesTemplate will be able
  // to create a line for each set of data
  return R.flatten(
    dataArr.map((data, i) => {
      return R.map(({ x, y }) => ({ x, y, series: i }), data);
    })
  );
};

const LineChart: React.FC<LineChartProps> = ({
  data,
  colorPalette,
  tooltipTitle,
  xAxisLabel,
  yAxisLabel,
  xValueTooltipFormat,
  yValueTooltipFormat,
  children,
}) => {
  const theme = useTheme();

  const renderTooltip = (info: ContentRenderInfo) => (
    <TooltipTemplate {...info} title={tooltipTitle} />
  );

  const dataSource = chartify(data);

  return (
    <Chart dataSource={dataSource} palette={colorPalette}>
      <CommonAxisSettings color={theme.backgroundColor.line}>
        <Tick visible={false} />
      </CommonAxisSettings>
      <ArgumentAxis valueMarginsEnabled minValueMargin={0.04}>
        <Label customizeText={xAxisLabel} />
      </ArgumentAxis>
      <ValueAxis>
        <Label customizeText={yAxisLabel} />
        <Grid visible={false} />
      </ValueAxis>
      <DevExtremeLegend visible={false} />
      <Size height={200} />

      <CommonSeriesSettings
        argumentField="x"
        valueField="y"
        type="line"
        point={{ visible: false }}
      />

      <SeriesTemplate nameField="series" />

      <Tooltip
        enabled
        contentRender={renderTooltip}
        shadow={{ blur: 0, offsetY: 0 }}
        border={{ color: theme.borders.tooltip.color }}
        cornerRadius={theme.borders.default.radiusNumber}
        format={yValueTooltipFormat}
        argumentFormat={xValueTooltipFormat}
      />
      <Series />

      {children}
    </Chart>
  );
};

export default LineChart;
