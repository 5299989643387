import { Box, BoxProps } from 'rebass';

import styled from '@emotion/styled';

interface FullBGBoxProps extends BoxProps {
  bgImg: string;
  position: string;
}

const FullBGBox = styled(Box)<FullBGBoxProps>`
  background-image: url(${({ bgImg }) => bgImg});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-position: ${({ position }) => position};
  background-color: ${({ theme }) => theme.backgroundColor.background2};
`;

export default FullBGBox;
