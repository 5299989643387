import { AnimatePresence, motion } from 'framer-motion';
import * as R from 'ramda';
import React from 'react';
import { Box, Flex } from 'rebass';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { IconDecline } from '../../../assets/images';

import { useAppDispatch } from '../../../data/hooks';
import {
  clearNotificationsById,
  useNotificationsGroupedByReport,
} from '../../../data/notifications/notificationsSlice';
import { Notification, ReportId } from '../../../types';
import { formatDate } from '../../../util/dates';
import {
  Button,
  Card,
  CardBody,
  Date,
  H5,
  Headline,
  NotificationIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  UnstyledButton,
} from '../../atoms';

const NotificationTableCell = styled(TableCell)`
  &.MuiTableCell-body {
    border-left: none;
    &:first-of-type {
      padding-left: 77px;
    }
  }
`;

const MotionCard = motion(Card);

const MotionRow = motion(TableRow);

const MotionTable = motion(Table);

const MotionCardBody = motion(CardBody);

interface Props {
  reportId: ReportId;
}

const ReportNotifications: React.FC<Props> = ({ reportId }) => {
  const notifications: Notification[] =
    useNotificationsGroupedByReport()[reportId] || [];

  const dispatch = useAppDispatch();
  const theme = useTheme();

  if (notifications.length === 0) {
    return null;
  }

  const clearNotification = (notification: Notification) => {
    dispatch(clearNotificationsById([notification.id]));
  };

  const clearAll = () => {
    dispatch(clearNotificationsById(R.map(R.prop('id'), notifications)));
  };

  return (
    <>
      <MotionCard
        layout
        initial={{ y: 0, opacity: 1 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ x: -30, opacity: 0 }}
        leftBorder
        leftBorderColor={theme.backgroundColor.notification}
      >
        <MotionCardBody layout="position">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <NotificationIcon />
              <Box mx="12px" />
              <Headline>Notifications</Headline>
            </Flex>
            <Button
              variant="text"
              backgroundOnHover="transparent"
              bold
              size="small"
              onClick={() => clearAll()}
            >
              Clear All
            </Button>
          </Flex>
        </MotionCardBody>
        <TableContainer>
          <MotionTable
            layout
            aria-labelledby="Notifications table"
            size="small"
          >
            <TableHead className="visually-hidden">
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <AnimatePresence>
                {notifications.map((notification) => (
                  <MotionRow
                    layout="position"
                    key={notification.id}
                    initial={{ y: 0, opacity: 1 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ x: -30, opacity: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                  >
                    <NotificationTableCell>
                      <H5 fontWeight="bold">{notification.title}</H5>
                    </NotificationTableCell>
                    <NotificationTableCell>
                      <H5 as="p">{notification.message}</H5>
                    </NotificationTableCell>
                    <NotificationTableCell>
                      <Date>{formatDate({ date: notification.date })}</Date>
                    </NotificationTableCell>
                    <NotificationTableCell>
                      <UnstyledButton
                        type="button"
                        onClick={() => clearNotification(notification)}
                        aria-label="Clear notificiation"
                      >
                        <Flex width="15px" height="15px" pt="6px">
                          <IconDecline />
                        </Flex>
                      </UnstyledButton>
                    </NotificationTableCell>
                  </MotionRow>
                ))}
              </AnimatePresence>
            </TableBody>
          </MotionTable>
        </TableContainer>
      </MotionCard>
      <Box mb={4} />
    </>
  );
};

export default ReportNotifications;
