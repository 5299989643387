import { getTime } from 'date-fns';

import {
  Notification,
  NotificationCategory,
  NotificationResourceType,
} from '../../types';

export const dummyNotificationsData: Record<string, Notification> = {
  '1': {
    id: '1',
    title: 'Your scheduled report is ready to view',
    longTitle:
      'Your scheduled <b>“Transaction Analysis”</b> report is ready to view',
    date: getTime(new Date()),
    message: 'You were alerted because your scheduled report is ready to view',
    category: NotificationCategory.Scheduled,
    resource: {
      id: '26',
      type: NotificationResourceType.Report,
    },
  },
  '2': {
    id: '2',
    title: 'Mary has made changes to your report',
    longTitle:
      '<b>Mary</b> has made changes to your <b>"Card Usage Analysis"</b> report',
    date: getTime(new Date()),
    message:
      'You were alerted because Mary made changes to your Transaction Type filter',
    category: NotificationCategory.Shared,
    resource: {
      id: '2',
      type: NotificationResourceType.Report,
    },
  },
  '3': {
    id: '3',
    title: 'Interchange dropped below $150,000!',
    longTitle:
      'Interchange dropped below $150,000 on <b>“Transaction Volume by Tiers”</b>',
    date: getTime(new Date()),
    message: 'You were alerted because Interchange is less than $150,000',
    category: NotificationCategory.Business,
    resource: {
      id: '2',
      type: NotificationResourceType.Report,
    },
  },
  '4': {
    id: '4',
    title: 'Total Spend went above $60,000!',
    longTitle: 'Total Spend went above $60,000 on <b>“Management”</b>',
    date: getTime(new Date()),
    message: 'You were alerted because Total Spend went above $60,000',
    category: NotificationCategory.Business,
    resource: {
      id: '6',
      type: NotificationResourceType.Report,
    },
  },
  '5': {
    id: '5',
    title: 'Interchange dropped below $125,000!',
    longTitle:
      'Interchange dropped below $125,000 on <b>“Card Usage Analysis”</b>',
    date: getTime(new Date()),
    message: 'You were alerted because Interchange is less than $125,000',
    category: NotificationCategory.Business,
    resource: {
      id: '8',
      type: NotificationResourceType.Report,
    },
  },
  '6': {
    id: '6',
    title: 'Interchange dropped below $200,000!',
    longTitle:
      'Interchange dropped below $200,000 on <b>“Campaign Analysis”</b>',
    date: getTime(new Date()),
    message: 'You were alerted because Interchange is less than $200,000',
    category: NotificationCategory.Business,
    resource: {
      id: '9',
      type: NotificationResourceType.Report,
    },
  },
  '7': {
    id: '7',
    title: 'CO-OP has shared “Management” with you',
    longTitle: '<b>CO-OP</b> has shared <b>“Management”</b> report with you',
    date: getTime(new Date()),
    message: 'CO-OP has shared “Management” with you',
    category: NotificationCategory.Shared,
    resource: {
      id: '6',
      type: NotificationResourceType.Report,
    },
  },
};
