import React from 'react';
import { Flex } from 'rebass';

interface Props {
  message: string;
}

const FourOhFour: React.FC<Props> = ({ message }) => (
  <Flex
    flexDirection="column"
    justifyContent="space-around"
    alignItems="center"
    height="100%"
  >
    <h2>{message || 'Page not Found'}</h2>
  </Flex>
);

export default FourOhFour;
