// Add any 'mixins' or global functions that are relevant to styles only

import { css } from '@emotion/react';

import { HasTheme } from './Theme';

export const styleUtils = {
  pillContainerStyle: ({ theme }: HasTheme) => css`
    background: ${theme.backgroundColor.background1};
    border-radius: ${theme.borders.default.radius};
    border: ${theme.borders.input};
    padding: ${theme.padding.input.all};
  `,
  linkStyle: ({ theme }: HasTheme) => css`
    color: ${theme.textColor.link};
    cursor: pointer;
    text-decoration: underline;
    &:visited {
      color: ${theme.textColor.link};
    }
  `,
};
