import React from 'react';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { Paragraph } from '../atoms';

interface Item {
  color: string;
  text: string;
}

interface Props {
  items: Item[];
}

interface DotProps {
  color: string;
}

const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const Legend: React.FC<Props> = ({ items }) => {
  return (
    <Flex>
      {items.map((item) => (
        <Flex key={item.text} alignItems="center" mr={[4]}>
          <Dot color={item.color} />
          <Box mr={[2]} />
          <Paragraph>{item.text}</Paragraph>
        </Flex>
      ))}
    </Flex>
  );
};

export default Legend;
