import { motion } from 'framer-motion';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { HiViewGrid, HiViewList, HiSearch } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

import { useAppDispatch } from '../../../data/hooks';
import { dummyNotificationsData } from '../../../data/notifications/dummyNotificationsData';
import { useNotificationsGroupedByReport } from '../../../data/notifications/notificationsSlice';
import ReportGroups, {
  keyToDisplayName,
  ownerName,
} from '../../../data/reports/reportGroups';
import {
  getReports,
  useLoading,
  useReports,
} from '../../../data/reports/reportsSlice';
import { paths } from '../../../routing/paths';
import { Report, ReportGroupId } from '../../../types';
import { mapKvpValues } from '../../../util/collections';
import { NEW_REPORT_ID } from '../../../util/report';
import {
  Button,
  Dropdown,
  H3,
  IconButton,
  Input,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  CardDivider,
} from '../../atoms';
import { NotificationMenuItem } from '../../molecules';
import { Layout } from '../../templates';
import ReportsGrid from './ReportsGrid';
import ReportsTable from './ReportsTable';
import WelcomeBack from '../welcome/WelcomeBack';

const RG = ReportGroups.standardIds;

const ReportContainer = styled(Box)`
  background: ${({ theme }) => theme.backgroundColor.background1};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  box-shadow: ${({ theme }) => theme.shadows.widgetShadow};
`;

const SecondaryNavContainer = styled(Box)`
  background: ${({ theme }) => theme.backgroundColor.background1};
  border-radius: ${({ theme }) => theme.borders.default.radius};
`;

enum ViewType {
  List = 'List',
  Grid = 'Grid',
}

const ReportsIndex: React.FC = () => {
  const [view, setView] = useState<ViewType>(ViewType.List);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [currentGroup, setCurrentGroup] = useState<ReportGroupId>(
    RG.FrequentlyUsed
  );

  const [currentTab, setCurrentTab] = useState<number>(0);

  const loading = useLoading();
  const currentGroupReports = useReports()[currentGroup] || [];
  const notificationsByReport = useNotificationsGroupedByReport();
  const reports: Report[] = R.map((groupReport) => {
    return {
      ...groupReport.report,
      notifications: notificationsByReport[groupReport.report.id],
    };
  }, currentGroupReports);
  const owners = R.uniq(R.map(ownerName, reports));
  const categories = R.uniq(R.map(R.prop('category'), reports));

  const onSideSelectionClicked = (groupKey: string) => {
    setCurrentGroup(groupKey);
    setCurrentTab(Number(groupKey) - 1);
  };

  useEffect(() => {
    dispatch(getReports({ group: currentGroup }));
  }, [currentGroup, dispatch]);

  const onReportClicked = (reportId: string) => {
    history.push(paths.report(reportId));
  };

  const onNewReportClicked = () => {
    history.push(paths.report(NEW_REPORT_ID));
  };

  // TODO: just for prototype
  const [dummyToastOpen, setDummyToastOpen] = useState(false);

  const handleToastClose = () => {
    setDummyToastOpen(false);
  };
  const theme = useTheme();

  const StyledTabs = withStyles({
    root: {},
    indicator: {
      backgroundColor: theme.backgroundColor.button.default,
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.backgroundColor.darkest,
      },
    },
  })(Tabs);

  const StyledTab = withStyles(() =>
    createStyles({
      root: {
        textTransform: 'none',
        fontSize: theme.fontSize.h5,
        '&:hover': {
          color: theme.backgroundColor.button.default,
          opacity: 1,
        },
        '&.Mui-selected': {
          color: theme.backgroundColor.button.default,
          fontWeight: 'bold',
        },
        '&:focus': {
          color: theme.backgroundColor.button.default,
        },
      },
      selected: {},
    })
  )(Tab);

  // TODO: END: just for prototype

  const [welcomeBackModalOpen, setWelcomeBackModalOpen] = useState(false);

  const handleWelcomeBackClick = () => {
    setWelcomeBackModalOpen(true);
  };
  const handleWelcomeBackModalClose = () => {
    setWelcomeBackModalOpen(false);
  };

  return (
    <Layout>
      {/* TODO: just for prototype */}
      <WelcomeBack
        open={welcomeBackModalOpen}
        onClose={handleWelcomeBackModalClose}
      />
      <Snackbar
        open={dummyToastOpen}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <NotificationMenuItem
          onClose={handleToastClose}
          notification={dummyNotificationsData['1']}
          leftBorder
        />
      </Snackbar>
      {/* TODO: end just for prototype */}

      <Flex justifyContent="space-between">
        <H3 // TODO: just for prototype
          onClick={() => setDummyToastOpen(true)}
        >
          Reports
        </H3>

        <Button onClick={onNewReportClicked}>New Report</Button>
      </Flex>

      <Flex my={[4]} justifyContent="space-between">
        <Input
          type="text"
          placeholder="Search reports"
          startAdornment={
            <InputAdornment position="start">
              <HiSearch color={theme.textColor.icon} />
            </InputAdornment>
          }
        />

        <Flex>
          <Dropdown filter placeholder="All Categories" placeholderDisabled>
            {categories.map((category) => (
              <MenuItem key={category}>{keyToDisplayName(category)}</MenuItem>
            ))}
          </Dropdown>
          <Box mr={[3]} />
          <Dropdown filter placeholder="All Creators" placeholderDisabled>
            {owners.map((owner) => (
              <MenuItem onClick={handleWelcomeBackClick} key={owner}>
                {owner}
              </MenuItem>
            ))}
          </Dropdown>
          <Box mr={[3]} />
          <IconButton
            variant="primary"
            icon={HiViewList}
            active={view === ViewType.List}
            onClick={() => setView(ViewType.List)}
          />
          <Box mr={[3]} />
          <IconButton
            variant="primary"
            icon={HiViewGrid}
            active={view === ViewType.Grid}
            onClick={() => setView(ViewType.Grid)}
          />
        </Flex>
      </Flex>
      <Flex>
        <ReportContainer flex="1">
          <SecondaryNavContainer>
            <StyledTabs
              value={currentTab}
              indicatorColor="primary"
              textColor="inherit"
            >
              {R.values(
                mapKvpValues(
                  (groupKey, groupId) => (
                    <StyledTab
                      disableRipple
                      key={groupKey}
                      textColor="inherit"
                      onClick={() => onSideSelectionClicked(groupId as string)}
                      label={ReportGroups.standardNames[groupKey]}
                    />
                  ),
                  ReportGroups.standardIds
                )
              )}
            </StyledTabs>
            <CardDivider />
          </SecondaryNavContainer>
          {loading ? (
            <Flex justifyContent="center">
              <Box p={5}>
                <H3>Loading...</H3>
              </Box>
            </Flex>
          ) : view === ViewType.List ? (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.5,
                },
              }}
            >
              <ReportsTable onRowClicked={onReportClicked} reports={reports} />
            </motion.div>
          ) : (
            <ReportsGrid reports={reports} />
          )}
        </ReportContainer>
      </Flex>
    </Layout>
  );
};

export default ReportsIndex;
