import React from 'react';
import { Box } from 'rebass';

import styled from '@emotion/styled';

import { WidgetSize } from '../../../types';

interface Props {
  size?: WidgetSize;
}

interface ItemProps {
  active: boolean;
}

const Container = styled.div`
  border-radius: ${({ theme }) => theme.borders.default.radius};
  border: ${({ theme }) => theme.borders.table};
  background: ${({ theme }) => theme.backgroundColor.default};
`;

const Header = styled.div`
  height: 12px;
  border-bottom: ${({ theme }) => theme.borders.table};
`;

const Item = styled(Box)<ItemProps>`
  background: ${({ theme, active }) =>
    active
      ? theme.backgroundColor.radioButton.checked
      : theme.backgroundColor.element1};
  height: 20px;
`;

const WidgetSizePreview: React.FC<Props> = ({ size }) => {
  return (
    <Container>
      <Header />
      <Box
        p={['6px']}
        sx={{
          display: 'grid',
          gridGap: '5px',
          gridTemplateColumns: '1fr 1fr 1fr',
        }}
      >
        <Item
          active={size === WidgetSize.Small}
          sx={{ gridColumn: '1/ span 1' }}
        />
        <Item
          active={size === WidgetSize.Medium}
          sx={{ gridColumn: '2/ span 2' }}
        />
        <Item
          active={size === WidgetSize.Large}
          sx={{ gridColumn: '1/ span 3' }}
        />
      </Box>
    </Container>
  );
};

export default WidgetSizePreview;
