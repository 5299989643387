import * as R from 'ramda';

import { ScenarioContextType, ScenarioDataType, Runnable } from './types';

const testFnFromRunnable = <Context = ScenarioContextType>(
  test: Runnable<Context>
) => {
  return typeof test === 'function' ? test : test.run;
};

// ///////// Scenario
//

export class Scenario<Context = ScenarioContextType, Data = ScenarioDataType> {
  name: string;

  test: Runnable<Context>;

  getData: () => Data;

  constructor(name: string, test: Runnable<Context>, getData: () => Data) {
    this.name = name;
    this.test = test;
    this.getData = getData;
  }

  run(context: Context) {
    const testFn = testFnFromRunnable(this.test);

    return testFn(context) ? this : undefined;
  }
}

export const scenario = <
  Context = ScenarioContextType,
  Data = ScenarioDataType
>(
  name: string,
  test: Runnable<Context>,
  getData: () => Data
) => new Scenario<Context, Data>(name, test, getData);

//
// ///////// And
//
interface AndArgs<Context = ScenarioContextType> {
  testFns: Runnable<Context>[];
}

export const And = <Context = ScenarioContextType>({
  testFns,
}: AndArgs<Context>) => ({
  __type: 'And' as const,
  testFns,
  run: (context: Context) => {
    return R.reduce(
      (acc, test) => {
        if (!acc) {
          return false;
        }
        const testFn = testFnFromRunnable(test);
        return acc && testFn(context);
      },
      true,
      testFns
    );
  },
});

export const and = <Context = ScenarioContextType>(
  ...testFns: Runnable<Context>[]
) => And({ testFns });

//
// ///////// Or
//
// TODO: refactor out common stuff w/ And?
interface OrArgs<Context = ScenarioContextType> {
  testFns: Runnable<Context>[];
}

export const Or = <Context = ScenarioContextType>({
  testFns,
}: OrArgs<Context>) => ({
  __type: 'Or' as const,
  testFns,
  run: (context: Context) => {
    return R.reduce(
      (acc, test) => {
        const testFn = testFnFromRunnable(test);
        return acc || testFn(context);
      },
      false,
      testFns
    );
  },
});

export const or = <Context = ScenarioContextType>(
  ...testFns: Runnable<Context>[]
) => Or({ testFns });
// TODO: end

// TODO: revise type for widgetData?
export const useData = (data: Record<string, unknown>) => () => data;

export const check = scenario;
export const when = and;
export const either = or;
