import * as R from 'ramda';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Notification, NotificationResourceType } from '../../types';
import { call } from '../../util/mockApi';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';
import { dummyNotificationsData } from './dummyNotificationsData';

export interface NotificationsSliceState {
  loading?: boolean;
  notifications: Record<string, Notification>;
}

const initialState: NotificationsSliceState = {
  notifications: {},
};

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async () => {
    const notifications = dummyNotificationsData;
    const data = { notifications };
    const response = await call({ data });

    return response.data;
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotificationsById: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        notifications: R.omit(action.payload, state.notifications),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        const { payload } = action;

        state.loading = undefined;

        state.notifications = payload.notifications;
      })
      .addCase(getNotifications.rejected, (_state, action) => {
        console.error(action);
      });
  },
});

// Selectors + hooks using them
const _notifications = (state: RootState) => state.notifications.notifications;
const _loading = (state: RootState) => state.notifications.loading;
const selectNotificationsGroupedByReport = (state: RootState) => {
  const allNotifications: Notification[] = Object.values(
    state.notifications.notifications
  );
  const reportNotifications = allNotifications.filter((notification) =>
    Boolean(notification.resource.type === NotificationResourceType.Report)
  );

  return R.groupBy(
    (notification) => notification.resource.id,
    reportNotifications
  );
};

export const useNotifications = () => {
  return useAppSelector(_notifications);
};

export const useNotificationsGroupedByReport = () => {
  return useAppSelector(selectNotificationsGroupedByReport);
};

export const useLoading = () => {
  return useAppSelector(_loading);
};

export const { clearNotificationsById } = notificationsSlice.actions;

export default notificationsSlice.reducer;
