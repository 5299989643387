import { useState } from 'react';

import { HasId } from '../../../util/ids';
import { CardModalProps } from '../../organisms/CardModal';

enum ModalStates {
  Index = 'Index',
  Form = 'Form',
}

const useSetActionsModal = <T extends HasId>(
  onClose: CardModalProps['onClose']
) => {
  const [currentState, setCurrentState] = useState(ModalStates.Index);
  const [itemCurrentlyEditing, setItemCurrentlyEditing] = useState<T>();

  const reset = () => {
    setItemCurrentlyEditing(undefined);
    setCurrentState(ModalStates.Index);
  };

  const handleClose = () => {
    reset();
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  const switchToForm = () => {
    setCurrentState(ModalStates.Form);
  };

  const handleEdit = (item: T) => {
    setItemCurrentlyEditing(item);
    switchToForm();
  };

  return {
    showIndex: currentState === ModalStates.Index,
    switchToForm,
    itemCurrentlyEditing,
    handleClose,
    handleEdit,
    reset,
  };
};

export default useSetActionsModal;
