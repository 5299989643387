import * as R from 'ramda';

import styled from '@emotion/styled';

import { User } from '../../types';
import { ColorPalette } from '../../types/common';
import ColoredCircle, {
  ColoredCircleProps as OrigColoredCircleProps,
} from './ColoredCircle';

const AvatarImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  fill: ${({ theme }) => theme.backgroundColor.darkest};
`;

type ColoredCircleProps = Pick<
  OrigColoredCircleProps,
  'width' | 'height' | 'fontSize'
>;

interface UserAvatarProps {
  user: User;
  circleProps?: ColoredCircleProps;
  indexInSeries: number;
  seriesOffset?: number;
  palette: ColorPalette;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  palette,
  circleProps,
  indexInSeries,
  seriesOffset,
}) => {
  const { avatar, name } = user;

  if (avatar) {
    return <AvatarImg src={avatar} alt={name} />;
  }

  const letter = name[0];
  const color =
    palette[(indexInSeries + (seriesOffset || 0)) % R.length(palette)];

  return (
    <ColoredCircle {...circleProps} color={color}>
      <span>{letter}</span>
    </ColoredCircle>
  );
};

export default UserAvatar;
