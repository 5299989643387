import { Flex, Box } from 'rebass';
import { HiTrendingUp, HiTrendingDown } from 'react-icons/hi';

import { Paragraph } from './Text';

interface TrendLineProps {
  direction: 'up' | 'down';
  text: string;
}

const TrendLine: React.FC<TrendLineProps> = ({ direction, text }) => {
  return (
    <Flex alignItems="center">
      {direction === 'up' ? <HiTrendingUp /> : <HiTrendingDown />}
      <Box mr={[2]} />
      <Paragraph>{text}</Paragraph>
    </Flex>
  );
};

export default TrendLine;
