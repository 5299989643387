// TODO: use our own styling
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import * as R from 'ramda';
import React from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Box } from 'rebass';

import styled from '@emotion/styled';

import { ReportWidget, WidgetId } from '../../types';
import { ReportWidget as ReportWidgetComponent } from '../molecules';
import { EditWidgetFormValues } from './EditWidgetModal/editWidgetTypes';

const ReactGridLayout = styled(WidthProvider(RGL))`
  margin-top: -16px;
  margin-left: -30px;
  margin-right: -30px;
  .react-grid-placeholder {
    background: ${({ theme }) => theme.backgroundColor.element2};
    transition-duration: 100ms;
    z-index: 2;
    border-radius: ${({ theme }) => theme.borders.default.radius};
  }
  .react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid ${({ theme }) => theme.backgroundColor.element2};
    border-bottom: 2px solid ${({ theme }) => theme.backgroundColor.element2};
  }
`;

interface WidgetGridProps {
  onLayoutChange: (layout: RGL.Layout[]) => void;
  widgets: Record<string, ReportWidget>;
  onEditWidgetSubmit: (
    widgetId: WidgetId
  ) => (formData: EditWidgetFormValues) => void;
}

const WidgetGrid: React.FC<WidgetGridProps> = ({
  onLayoutChange,
  widgets,
  onEditWidgetSubmit,
}) => {
  const gridProps = {
    isDraggable: true,
    isResizable: true,
    isBounded: true,
    rowHeight: 200,
    margin: [30, 30] as [number, number],
    cols: 3,
    autoSize: true,
    layout: R.values(widgets).map((widget) => widget.layout),
  };

  const renderReportWidgets = (reportWidgets: ReportWidget[]) => {
    return R.map((reportWidget) => {
      const { layout, widget } = reportWidget;

      return (
        <Box key={widget.id} data-grid={layout}>
          <ReportWidgetComponent
            reportWidget={reportWidget}
            onEditWidgetSubmit={onEditWidgetSubmit(widget.id)}
          />
        </Box>
      );
    }, reportWidgets);
  };

  return (
    <ReactGridLayout onLayoutChange={onLayoutChange} {...gridProps}>
      {renderReportWidgets(R.values(widgets))}
    </ReactGridLayout>
  );
};

export default WidgetGrid;
