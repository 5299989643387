import { AnimatePresence, motion } from 'framer-motion';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import * as R from 'ramda';
import React, { useRef, useState } from 'react';
import { HiHome, HiUserCircle } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import Switch from '@mui/material/Switch';

import {
  IC_Icon_Bell_v3_DARK,
  IC_Icon_Bell_v3_LIGHT,
  IC_Logo_Animation,
  IC_Logo_Animation_Reverse,
  IC_Logo_Animation_Reverse_White,
  IC_Logo_Animation_White,
  IC_NewLogo_Text_Dark,
  IC_NewLogo_Text_Light,
  IC_NewLogo_Text_Reverse_Dark,
  IC_NewLogo_Text_Reverse_Light,
  ICNewLogoIcon,
  Logo_COOP_Black as LogoCoopBlack,
  MoonIcon,
  SunIcon,
} from '../../../assets/images';
import { useNotifications } from '../../../data/notifications/notificationsSlice';
import { useUserSettings } from '../../../hooks/useUserSettings';
import { paths } from '../../../routing/paths';
import { styleUtils } from '../../../styles/settings';
import { hasNoValues } from '../../../util/collections';
import useOutsideClick from '../../../util/useOutsideClick';
import { NotificationDot, NotificationIcon, UnstyledButton } from '../../atoms';
import NotificationsMenu from './NotificationsMenu';

interface SidebarSectionContainerProps {
  open: boolean;
}

const MainSidebarContainer = styled(Box)<SidebarSectionContainerProps>`
  width: ${({ open }) => (open ? '250px' : '76px')};
  -webkit-transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  height: 100%;
`;

const NotificationMenuContainer = styled(Box)<SidebarSectionContainerProps>`
  width: ${({ open }) => (open ? '310px' : '0')};
  -webkit-transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 100%;
`;

const LayoutContainer = styled(motion.div)``;

const Header = styled.header`
  z-index: 10;
  top: 0;
  height: 100%;
  position: fixed;
  background: ${({ theme }) => theme.backgroundColor.background1};
  box-shadow: ${({ theme }) => theme.shadows.widgetShadow};
  overflow: hidden;
`;

const COOPLogoContainer = styled.div`
  height: 30px;
  margin-left: 6px;
  position: absolute;
`;

const CoopLogo = styled(LogoCoopBlack)`
  height: 100%;
  width: auto;
  margin-left: -2px;
`;

const CoopLogoNew = styled(ICNewLogoIcon)`
  height: 100%;
  width: auto;
  margin-left: -13.5px;
`;

const COOPLogoExpanded = styled(motion.div)`
  height: 30px;
  margin-left: 40px;
  overflow: hidden;
  white-space: nowrap;
  width: 165px;
  position: absolute;
`;

const NewCOOPLogoExpanded = styled(motion.div)`
  height: 30px;
  margin-left: 50px;
  overflow: hidden;
  white-space: nowrap;
  width: 165px;
  position: absolute;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.backgroundColor.darkest};
  #right-line-one,
  #right-line-two,
  #left-line-one,
  #left-line-two,
  #bottom-line {
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    fill: ${({ theme }) => theme.backgroundColor.darkest};
  }

  &:hover #right-line-one,
  &:hover #right-line-two,
  &:hover #left-line-one,
  &:hover #left-line-two,
  &:hover #bottom-line {
    -webkit-transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 1.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    fill: ${({ theme }) => theme.colors.redDark};
  }
`;
const NavItem = styled(motion.span)`
  font-size: ${({ theme }) => theme.fontSize.nav};
  margin-left: 5px;
  vertical-align: 2.5px;
  color: ${({ theme }) => theme.textColor.text1};
  :hover {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }
`;

const NavRecentItem = styled(motion.div)`
  font-size: ${({ theme }) => theme.fontSize.nav};
  margin-left: 5px;
`;

const NavRecent = styled(motion.h5)`
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.textColor.text3};
  font-weight: bold;
`;

const NavItemContainer = styled(Box)`
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.textColor.text1};
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: table;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  &:hover {
    color: ${({ theme }) => theme.backgroundColor.button.default};
    transform: scale(1.05, 1.05) translate3d(0, 0, 0) translateZ(0);
  }
`;

// https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome
// thread about display table among other fixes

const RecentContainer = styled(Box)`
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.textColor.text1};
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const RecentItemContainer = styled(Box)`
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }
`;

const PrimaryNavItemLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  :first-child {
    color: ${({ theme }) => theme.textColor.icon};
    :hover {
      color: ${({ theme }) => theme.backgroundColor.button.default};
    }
  }
`;

const NavItemLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const NavItemButton = styled(UnstyledButton)`
  ${styleUtils.linkStyle}
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;

  :first-child {
    color: ${({ theme }) => theme.textColor.icon};

    :hover {
      color: ${({ theme }) => theme.backgroundColor.button.default};
    }
  }
`;

// to change Lottie animation SVG color
const NotificationItemButton = styled(NavItemButton)`
  :hover g:not(:last-child) path {
    fill: ${({ theme }) => theme.backgroundColor.button.default};
  }
`;

const ArrowBox = styled(Box)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
`;

const NotificationNumber = styled.span`
  text-align: center;
`;

const StyledNotificationIcon = styled(NotificationIcon)`
  vertical-align: center;
`;

const ToggleContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: table;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const Sun = styled(SunIcon)`
  height: 20px;
  width: 20px;
`;

const Moon = styled(MoonIcon)`
  height: 20px;
  width: 20px;
`;

const viewedItemsBaseAnimation = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.81,
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    x: -50,
    transition: {
      delay: 0.3,
      duration: 0.2,
    },
  },
};

const recentlyViewedItemsAnimation = [
  R.mergeDeepRight(viewedItemsBaseAnimation, {
    animate: {
      transition: {
        delay: 0.75,
      },
    },
  }),
  R.mergeDeepRight(viewedItemsBaseAnimation, {
    animate: {
      transition: {
        delay: 0.79,
      },
    },
    exit: {
      transition: {
        delay: undefined,
      },
    },
  }),
  R.mergeDeepRight(viewedItemsBaseAnimation, {
    animate: {
      transition: {
        delay: 0.84,
      },
    },
  }),
  R.mergeDeepRight(viewedItemsBaseAnimation, {
    animate: {
      transition: {
        delay: 0.89,
      },
    },
    exit: {
      transition: {
        delay: 0.2,
        duration: 0.3,
      },
    },
  }),
];

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarInitial, setSidebarInitial] = useState(true);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const miniSidebarRef = useRef(null);
  const headerRef = useRef(null);
  const lottieNotificationRef = useRef<LottieRefCurrentProps>(null);
  const notifications = useNotifications();

  const playNotificationAnimation = () => {
    lottieNotificationRef.current?.play();
  };

  const resetNotificationAnimation = () => {
    lottieNotificationRef.current?.goToAndStop(0);
  };

  useOutsideClick(headerRef, () => {
    setNotificationMenuOpen(false);

    if (sidebarOpen === false) {
      setSidebarInitial(true);
      return;
    }
    setSidebarOpen(false);
  });

  const miniSidebarClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    // Only if empty space is clicked, not child elements
    if (event.target !== miniSidebarRef.current) {
      return;
    }
    if (sidebarOpen) {
      return;
    }

    setSidebarOpen(true);
    setSidebarInitial(false);
  };

  const toggleNotificationsMenu = () => {
    if (notificationMenuOpen === false) {
      setSidebarInitial(true);
    }
    if (notificationMenuOpen === false && sidebarOpen) {
      setSidebarInitial(false);
    }
    setNotificationMenuOpen((open) => !open);
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        delay: 0.6,
        staggerChildren: 0.3,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  };

  const { isDark, isNew, toggleDarkMode } = useUserSettings();

  return (
    <Header ref={headerRef}>
      <Flex height="100%">
        <MainSidebarContainer
          open={sidebarOpen}
          py={[3]}
          px={[3]}
          onClick={miniSidebarClickHandler}
          ref={miniSidebarRef}
        >
          <LogoLink to={paths.reports()}>
            <COOPLogoContainer>
              <CoopLogoNew />
            </COOPLogoContainer>
            {isNew ? (
              <NewCOOPLogoExpanded>
                {sidebarOpen ? (
                  <Lottie
                    animationData={
                      isDark ? IC_NewLogo_Text_Dark : IC_NewLogo_Text_Light
                    }
                    loop={false}
                  />
                ) : sidebarInitial ? (
                  ''
                ) : (
                  <Lottie
                    animationData={
                      isDark
                        ? IC_NewLogo_Text_Reverse_Dark
                        : IC_NewLogo_Text_Reverse_Light
                    }
                    loop={false}
                    initialSegment={[0, 100]}
                  />
                )}
              </NewCOOPLogoExpanded>
            ) : (
              <COOPLogoExpanded>
                {sidebarOpen ? (
                  <Lottie
                    animationData={
                      isDark ? IC_Logo_Animation_White : IC_Logo_Animation
                    }
                    loop={false}
                  />
                ) : sidebarInitial ? (
                  ''
                ) : (
                  <Lottie
                    animationData={
                      isDark
                        ? IC_Logo_Animation_Reverse_White
                        : IC_Logo_Animation_Reverse
                    }
                    loop={false}
                    initialSegment={[0, 100]}
                  />
                )}
              </COOPLogoExpanded>
            )}
          </LogoLink>
          <NavItemContainer mt={['90px']}>
            <PrimaryNavItemLink to={paths.reports()}>
              <HiHome />
              <AnimatePresence exitBeforeEnter initial={false}>
                {sidebarOpen && (
                  <NavItem
                    initial={{
                      opacity: 0,
                      x: -100,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.2,
                        duration: 0.2,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        delay: 0.275,
                        duration: 0.2,
                      },
                    }}
                  >
                    Report Index
                  </NavItem>
                )}
              </AnimatePresence>
            </PrimaryNavItemLink>
          </NavItemContainer>
          <NavItemContainer my={[4]} onMouseEnter={playNotificationAnimation}>
            <NotificationItemButton onClick={() => toggleNotificationsMenu()}>
              <Lottie
                lottieRef={lottieNotificationRef}
                animationData={
                  isDark ? IC_Icon_Bell_v3_DARK : IC_Icon_Bell_v3_LIGHT
                }
                autoplay={false}
                loop={false}
                onComplete={resetNotificationAnimation}
                style={{
                  width: 20,
                  height: 20,
                }}
              />

              <AnimatePresence exitBeforeEnter initial={false}>
                {sidebarOpen && (
                  <>
                    <NavItem
                      initial={{
                        opacity: 0,
                        x: -100,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: 0.25,
                          duration: 0.2,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          delay: 0.2,
                          duration: 0.2,
                        },
                      }}
                    >
                      Notifications
                    </NavItem>
                    <motion.div
                      initial={{
                        opacity: 0,
                        x: -100,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: 0.25,
                          duration: 0.2,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          delay: 0.2,
                          duration: 0.2,
                        },
                      }}
                    >
                      <Box ml="10px" display="inline" />
                      {!hasNoValues(notifications) && (
                        <StyledNotificationIcon width="20px" height="20px">
                          <NotificationNumber>
                            {Object.values(notifications).length}
                          </NotificationNumber>
                        </StyledNotificationIcon>
                      )}
                    </motion.div>
                  </>
                )}
              </AnimatePresence>
            </NotificationItemButton>
          </NavItemContainer>
          <NavItemContainer>
            <PrimaryNavItemLink to="/profile">
              <HiUserCircle />
              <AnimatePresence exitBeforeEnter initial={false}>
                {sidebarOpen && (
                  <NavItem
                    initial={{
                      opacity: 0,
                      x: -100,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.4,
                        duration: 0.2,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      transition: {
                        delay: 0.1,
                        duration: 0.2,
                      },
                    }}
                  >
                    Profile
                  </NavItem>
                )}
              </AnimatePresence>
            </PrimaryNavItemLink>
          </NavItemContainer>
          <AnimatePresence>
            {sidebarOpen && (
              <LayoutContainer
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={list}
              >
                <RecentContainer mt={[5]} mb={[3]}>
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <NavRecent
                      initial={{
                        opacity: 0,
                        x: -100,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: 0.6,
                          duration: 0.2,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        x: -50,
                        transition: {
                          delay: 0.4,
                          duration: 0.2,
                        },
                      }}
                    >
                      Recently Viewed
                    </NavRecent>
                  </AnimatePresence>
                </RecentContainer>
                <RecentItemContainer pl={['5px']}>
                  <NavItemLink to={paths.report('1')}>
                    <AnimatePresence>
                      <NavRecentItem {...recentlyViewedItemsAnimation[0]}>
                        <Flex alignItems="center">
                          <Box flex="1">Transaction Analysis</Box>
                          <Box flex="0 0 auto">
                            <NotificationDot />
                          </Box>
                        </Flex>
                      </NavRecentItem>
                    </AnimatePresence>
                  </NavItemLink>
                </RecentItemContainer>
                <RecentItemContainer my={[3]} pl={['5px']}>
                  <NavItemLink to="/#">
                    <AnimatePresence>
                      <NavRecentItem {...recentlyViewedItemsAnimation[1]}>
                        Shared Branch Daily...
                      </NavRecentItem>
                    </AnimatePresence>
                  </NavItemLink>
                </RecentItemContainer>
                <RecentItemContainer my={[3]} pl={['5px']}>
                  <NavItemLink to="/#">
                    <AnimatePresence>
                      <NavRecentItem {...recentlyViewedItemsAnimation[2]}>
                        <Flex alignItems="center">
                          <Box flex="1">Card Usage Analysis</Box>
                          <Box flex="0 0 auto">
                            <NotificationDot />
                          </Box>
                        </Flex>
                      </NavRecentItem>
                    </AnimatePresence>
                  </NavItemLink>
                </RecentItemContainer>
                <RecentItemContainer my={[3]} pl={['5px']}>
                  <NavItemLink to="/#">
                    <AnimatePresence>
                      <NavRecentItem {...recentlyViewedItemsAnimation[3]}>
                        Transaction Volume by...
                      </NavRecentItem>
                    </AnimatePresence>
                  </NavItemLink>
                </RecentItemContainer>
              </LayoutContainer>
            )}
          </AnimatePresence>
          <ArrowBox>
            <ToggleContainer ref={miniSidebarRef}>
              {/* TODO: replace with ui package Switch component
              TODO: otherwise, add back color style (reverted after removing themeProvider)  */}
              <Switch
                name="reportingAreas"
                value="Day Mode"
                color="primary"
                checked={isDark}
                onChange={toggleDarkMode}
                size="medium"
                icon={<Sun />}
                checkedIcon={<Moon />}
              />
            </ToggleContainer>
          </ArrowBox>
        </MainSidebarContainer>
        <NotificationMenuContainer open={notificationMenuOpen}>
          <NotificationsMenu />
        </NotificationMenuContainer>
      </Flex>
    </Header>
  );
};

export default Sidebar;
