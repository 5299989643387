import { useState, useContext, createContext } from 'react';

interface UserSettings {
  isDark: boolean;
  isNew: boolean;
  toggleDarkMode: () => void;
  toggleNewLogo: () => void;
}

interface UserSettingsProviderProps {
  render?: (settings: UserSettings) => React.ReactNode;
}

const UserSettingsContext = createContext<UserSettings>({
  isDark: false,
  isNew: false,
  toggleDarkMode: () => null, // <- this will be overridden by the Provider's useState setter fn
  toggleNewLogo: () => null,
});

export const useUserSettings = () => useContext(UserSettingsContext);

export const UserSettingsProvider: React.FC<UserSettingsProviderProps> = ({
  render,
}) => {
  const { isDark: givenIsDark, isNew: givenIsNew } = useUserSettings();

  const [isDark, setIsDark] = useState(givenIsDark);
  const [isNew, setIsNew] = useState(givenIsNew);

  const toggleDarkMode = () => {
    setIsDark(!isDark);
  };

  const toggleNewLogo = () => {
    setIsNew(!isNew);
  };

  const userSettings = {
    isDark,
    isNew,
    toggleDarkMode,
    toggleNewLogo,
  };

  return (
    <UserSettingsContext.Provider value={userSettings}>
      {render ? render(userSettings) : null}
    </UserSettingsContext.Provider>
  );
};
