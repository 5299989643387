import * as R from 'ramda';

export function pairsFrom<ValueType = string>(
  items: string[] | Record<string, ValueType>
): [string, ValueType][] {
  return Array.isArray(items)
    ? R.toPairs(R.zipObj(items, items))
    : R.toPairs(items);
}

export const hasNoValues = <A = unknown>(obj: Record<string, A>) => {
  const isEmpty = R.isEmpty(obj);
  const allNil = R.all(R.isNil, R.values(obj));

  return isEmpty || allNil;
};

export const hasSameValues = <A = unknown>(arr1: A[], arr2: A[]) => {
  return R.length(R.intersection(arr1, arr2)) === R.length(arr1);
};

export const uniqueLeft = <A = unknown>(leftGroup: A[], rightGroup: A[]) => {
  // Gets the things from the left group that are NOT in the right group, ie are unique
  return R.reject((thing) => R.includes(thing, rightGroup), leftGroup);
};

export const max = (nums: number[]) => {
  return R.reduce<number, number>(R.max, Number.NEGATIVE_INFINITY, nums);
};

export * from './objects';
