import styled from '@emotion/styled';

export interface ColoredCircleProps {
  color: string;
  width?: string;
  height?: string;
  fontSize?: string;
}

const ColoredCircle = styled.div<ColoredCircleProps>`
  width: ${(props) => props.width || '25px'};
  height: ${(props) => props.height || '25px'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.color};

  color: ${({ theme }) => theme.textColor.onDark};

  font: ${({ theme }) =>
    `${theme.fontSize.avatar} ${theme.fontFamily.default}`};

  font-size: ${(props) => props.fontSize};
`;

export default ColoredCircle;
