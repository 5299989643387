import { makeNoise2D } from 'open-simplex-noise';
import { Noise2D } from 'open-simplex-noise/lib/2d';
import { eachDayOfInterval, isSameDay } from '../util/dates';

interface DatePoint {
  x: Date;
  y: number;
}

interface MakePointsArgs {
  keyPoints: DatePoint[];
  xScale?: number;
  yScale?: number;
  seed?: number;
  deformMin?: number;
  deformMax?: number;
}

interface GetNextYArgs {
  lastY: number;
  xScale: number;
  yScale: number;
  i: number;
  deformMax: number;
  deformMin: number;
  noise2d: Noise2D;
}

const getNextY = ({
  lastY,
  xScale,
  yScale,
  i,
  deformMax,
  deformMin,
  noise2d,
}: GetNextYArgs): number => {
  //
  // noise2d returns a value between -1 and 1 so adding 1 returns a value between 0-2. Dividing by 2 gives a value between 0-1
  //
  const deformationBase = (noise2d(i * xScale, lastY * yScale) + 1) / 2;

  // deformationBase is in the range 0-1.
  //
  // The diff between deformMax and deformMin forms the
  // entire range of acceptable values we want to use as a multiplier for x/y values.
  // Default is 0.9-1.1, ie we only want small changes
  // between 90% & and 110% of the original x or y value so
  // that we don't get huge swings in the graph.
  //
  // Multiplying the 0-1 base value by the "range value" (defaulting to 0.2 in the case where min is 0.9 and max is 1.1), we pick out a deformation value between the deformMin and max to get the "how far between min and max should we actually go" value.
  //
  // We add this "how far between should we go" value and apply it to the deformMin to get the final, actual deformation value
  const deformation = deformationBase * (deformMax - deformMin) + deformMin;

  return deformation * lastY;
};

export const makePoints = ({
  keyPoints,
  xScale: givenXScale,
  yScale: givenYScale,
  seed: givenSeed,
  deformMin: givenDeformMin,
  deformMax: givenDeformMax,
}: MakePointsArgs) => {
  const xScale = givenXScale || 0.07;
  const yScale = givenYScale || 0.03;
  const deformMin = givenDeformMin || 0.9;
  const deformMax = givenDeformMax || 1.1;
  const seed = givenSeed || 42;
  const noise2d = makeNoise2D(seed); // TODO: alternate seeds for each rendering of a graph?

  const start = keyPoints[0].x;
  const end = keyPoints[keyPoints.length - 1].x;
  const days = eachDayOfInterval({ start, end });
  const points: DatePoint[] = [];

  let nextKeyPointIdx = 0;

  // eslint-disable-next-line
  for (let i = 0; i < days.length; i++) {
    const nextKeyPoint = keyPoints[nextKeyPointIdx];
    const lastPoint = points[points.length - 1];
    const date = days[i];

    if (isSameDay(date, nextKeyPoint.x)) {
      points.push(nextKeyPoint);

      /* eslint-disable */
      nextKeyPointIdx = nextKeyPointIdx + 1;
      continue;
      /* eslint-enable */
    }

    // otherwise the point is being generated because
    // it doesn't line up with the same day as one of the keyPoints

    const nextY = getNextY({
      lastY: lastPoint.y,
      xScale,
      yScale,
      i,
      noise2d,
      deformMax,
      deformMin,
    });

    const point = {
      x: date,
      y: nextY,
    };
    points.push(point);
  }
  return points;
};
