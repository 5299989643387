import styled from '@emotion/styled';
import { Tab } from '@mui/material';

const StyledTab = styled(Tab)`
  &.Mui-selected {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }

  &.MuiTab-root {
    text-transform: none;
  }
`;

export default StyledTab;
