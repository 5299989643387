import { getTime } from 'date-fns';

import {
  DateTypeOptions,
  FilterType,
  Report,
  ReportCategory as RC,
  ReportRole,
  ReportsByGroup,
  StandardDateRangeOptions,
} from '../../types';
import { makeReport } from '../report/makeReport';
import {
  CardTypeOptions,
  NetworkOptions,
  segmentationFilters,
} from '../reportFilters/dummySegmentationFiltersData';
import {
  dummyUsers as U,
  justCOOP,
  justYou,
  makeCollaborator,
} from '../users/dummyUserData';
import ReportGroups from './reportGroups';

const { makeGroupReport } = ReportGroups;

const RR = ReportRole;
const SF = segmentationFilters.filters;

const {
  FrequentlyUsed,
  Recommended,
  AllReports,
  Favorites,
} = ReportGroups.standard;

const bigGroup = () => {
  return {
    [U.Sally.id]: makeCollaborator({
      user: U.Sally,
      reportRole: RR.Owner,
    }),
    [U.Sonika.id]: makeCollaborator({
      user: U.Sonika,
    }),
    [U.Siddharth.id]: makeCollaborator({
      user: U.Siddharth,
    }),
    [U.COOP.id]: makeCollaborator({
      user: U.COOP,
    }),
    [U.Alex.id]: makeCollaborator({
      user: U.Alex,
    }),
    [U.Tom.id]: makeCollaborator({
      user: U.Tom,
    }),
    [U.Leo.id]: makeCollaborator({
      user: U.Leo,
    }),
  };
};

export const DUMMY_IFRAME_REPORT_ID = 'iframe-example';

export const dummyReportsData: Record<string, Report> = {
  '1': makeReport({
    id: '1',
    name: 'Transaction Analysis',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-03-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    segmentationFilters: {
      [SF.cardTypeFilter.id]: {
        label: SF.cardTypeFilter.name,
        value: CardTypeOptions.Debit,
      },
      [SF.networkFilter.id]: {
        label: SF.networkFilter.name,
        value: NetworkOptions.Coop,
      },
    },
    collaborators: justCOOP(),
  }),
  '2': makeReport({
    id: '2',
    name: 'Transaction Volume by Tiers',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-03-19')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '3': makeReport({
    id: '3',
    name: 'Card Usage Analysis',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-02-22')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: {
      ...justCOOP(),
      [U.Sonika.id]: makeCollaborator({
        user: U.Sonika,
        reportRole: RR.Owner,
      }),
    },
  }),
  '4': makeReport({
    id: '4',
    name: 'Chargeback Analysis',
    category: RC.EnterpriseChargebacks,
    lastUpdated: getTime(new Date('2021-02-15')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '5': makeReport({
    id: '5',
    name: 'Merchant Analysis',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-02-10')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '6': makeReport({
    id: '6',
    name: 'Management',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '7': makeReport({
    id: '7',
    name: 'Average Spend and Transactions',
    category: RC.Operational,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  [DUMMY_IFRAME_REPORT_ID]: makeReport({
    id: DUMMY_IFRAME_REPORT_ID,
    name: 'Shared Branch Daily Transaction Report',
    category: RC.SharedBranching,
    lastUpdated: getTime(new Date('2021-01-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '8': makeReport({
    id: '8',
    name: 'Card Usage Analysis',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-01-22')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '9': makeReport({
    id: '9',
    name: 'Campaign Analysis',
    category: RC.SmartGrowth,
    lastUpdated: getTime(new Date('2021-01-12')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '10': makeReport({
    id: '10',
    name: 'Portfolio Account Growth',
    category: RC.Accounts,
    lastUpdated: getTime(new Date('2021-03-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '11': makeReport({
    id: '11',
    name: 'Period-to-Period Comparisons',
    category: RC.Accounts,
    lastUpdated: getTime(new Date('2021-03-19')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '12': makeReport({
    id: '12',
    name: 'Interchange Amount by SIC',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-22')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '13': makeReport({
    id: '13',
    name: 'International Sales Transactions',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-15')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '14': makeReport({
    id: '14',
    name: 'Transactions Trending',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-10')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '15': makeReport({
    id: '15',
    name: 'Miscellaneous Field Report',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '16': makeReport({
    id: '16',
    name: 'Total Interchange Monthly Summary',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: bigGroup(),
  }),
  '17': makeReport({
    id: '17',
    name: 'Summary Period-to-Period Comparison',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-01-22')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '18': makeReport({
    id: '18',
    name: 'Interchange Trending',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-03-19')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '19': makeReport({
    id: '19',
    name: 'Portfolio Trending by Month',
    category: RC.Accounts,
    lastUpdated: getTime(new Date('2021-02-15')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '20': makeReport({
    id: '20',
    name: 'List of Cardholder Accounts',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-03-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '21': makeReport({
    id: '21',
    name: 'Merchant Analysis (Ranking Order)',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '22': makeReport({
    id: '22',
    name: 'Merchant List',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-02-02')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '23': makeReport({
    id: '23',
    name: 'Sales by Merchant Category',
    category: RC.SharedBranching,
    lastUpdated: getTime(new Date('2021-01-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justCOOP(),
  }),
  '24': makeReport({
    id: '24',
    name: 'Account Detail',
    category: RC.Accounts,
    lastUpdated: getTime(new Date('2021-01-22')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '25': makeReport({
    id: '25',
    name: 'Sales Merchants by Group',
    category: RC.Transactions,
    lastUpdated: getTime(new Date('2021-01-12')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.LastFullMonth,
      [FilterType.BIN]: [],
    },
    collaborators: justYou(),
  }),
  '26': makeReport({
    id: '26',
    name: 'Transaction Analysis',
    category: RC.Management,
    lastUpdated: getTime(new Date('2021-03-27')),
    filters: {
      [FilterType.DateType]: DateTypeOptions.AuthorizationDate,
      [FilterType.DateRange]: StandardDateRangeOptions.Last3FullMonths,
      [FilterType.BIN]: [],
    },
    segmentationFilters: {
      [SF.cardTypeFilter.id]: {
        label: SF.cardTypeFilter.name,
        value: CardTypeOptions.Debit,
      },
      [SF.networkFilter.id]: {
        label: SF.networkFilter.name,
        value: NetworkOptions.Coop,
      },
      [SF.transactionTypePosFilter.id]: [
        {
          label: SF.transactionTypePosFilter.name,
          value: 'Card Not Present', // XXX sucks that I have to do this but the way that multi select is implemented the label and value are the same...
        },
      ],
    },
    collaborators: justCOOP(),
  }),
};

export const dummyGroupReportsData: ReportsByGroup = {
  [FrequentlyUsed.id]: [
    makeGroupReport({
      ...dummyReportsData['1'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['2'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['3'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['4'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['5'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['6'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['7'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData[DUMMY_IFRAME_REPORT_ID],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['8'],
      group: FrequentlyUsed,
    }),
    makeGroupReport({
      ...dummyReportsData['9'],
      group: FrequentlyUsed,
    }),
  ],
  [Recommended.id]: [
    makeGroupReport({
      ...dummyReportsData['10'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['11'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['12'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['13'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['14'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['15'],
      group: Recommended,
      collaborators: bigGroup(),
    }),
    makeGroupReport({
      ...dummyReportsData['16'],
      group: Recommended,
    }),
    makeGroupReport({
      ...dummyReportsData['5'],
      group: Recommended,
      lastUpdated: getTime(new Date('2021-01-27')),
    }),
    makeGroupReport({
      ...dummyReportsData['17'],
      group: Recommended,
    }),
  ],
  [AllReports.id]: [
    makeGroupReport({
      ...dummyReportsData['1'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['2'],
      group: AllReports,
      collaborators: justCOOP(),
    }),
    makeGroupReport({
      ...dummyReportsData['8'],
      group: AllReports,
      collaborators: justCOOP(),
    }),
    makeGroupReport({
      ...dummyReportsData['4'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['5'],
      group: AllReports,
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['6'],
      group: AllReports,
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['7'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData[DUMMY_IFRAME_REPORT_ID],
      group: AllReports,
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['8'],
      group: AllReports,
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['9'],
      group: AllReports,
      collaborators: justCOOP(),
    }),
    makeGroupReport({
      ...dummyReportsData['10'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['11'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['12'],
      group: AllReports,
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['13'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['14'],
      group: AllReports,
    }),
    makeGroupReport({
      ...dummyReportsData['15'],
      group: AllReports,
      collaborators: justCOOP(),
    }),
    makeGroupReport({
      ...dummyReportsData['16'],
      group: AllReports,
      collaborators: justCOOP(),
    }),
    makeGroupReport({
      ...dummyReportsData['17'],
      group: AllReports,
    }),
  ],
  [Favorites.id]: [
    makeGroupReport({
      ...dummyReportsData['14'],
      group: Favorites,
      lastUpdated: getTime(new Date('2021-03-27')),
    }),
    makeGroupReport({
      ...dummyReportsData['18'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['10'],
      group: Favorites,
      lastUpdated: getTime(new Date('2021-02-22')),
      collaborators: justYou(),
    }),
    makeGroupReport({
      ...dummyReportsData['19'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['20'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['21'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['22'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['23'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['24'],
      group: Favorites,
    }),
    makeGroupReport({
      ...dummyReportsData['25'],
      group: Favorites,
    }),
  ],
};
