import { Box } from 'rebass';

import styled from '@emotion/styled';

const AutoCompleteContainer = styled(Box)`
  background-color: ${({ theme }) => theme.backgroundColor.mid};
  padding: 10px 15px;
  border-radius: ${({ theme }) => theme.borders.default.radius};
`;

export default AutoCompleteContainer;
