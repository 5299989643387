import {
  ArgumentAxis,
  Chart,
  CommonAxisSettings,
  CommonSeriesSettings,
  Format,
  Label,
  Legend as DevExtremeLegend,
  SeriesTemplate,
  Size,
  Tick,
  ValueAxis,
} from 'devextreme-react/chart';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';

import { usePrevious } from '../../hooks/usePrevious';
import { BarChartData, ValueTextFn } from '../../types';
import { ColorPalette } from '../../types/common';

interface Props {
  data: BarChartData;
  colorPalette: ColorPalette;
  xAxisLabel?: ValueTextFn;
  pointLabel?: ValueTextFn;
}

const HorizontalBarChart: React.FC<Props> = ({
  data,
  colorPalette,
  xAxisLabel,
  pointLabel,
  children,
}) => {
  const theme = useTheme();

  // Devextreme re-render optimization
  // chart re-renders if colorPalette reference is new
  // when using SeriesTemplate
  // even if values are the same
  const prevColorPalette = usePrevious<ColorPalette>(colorPalette);
  const [savedColorPalette, setSavedColorPalette] = useState<
    ColorPalette | undefined
  >(prevColorPalette);

  useEffect(() => {
    if (!equals(prevColorPalette, colorPalette)) {
      setSavedColorPalette(colorPalette);
    }
  }, [colorPalette, prevColorPalette]);

  return (
    <Chart dataSource={data.bars} palette={savedColorPalette} rotated>
      <CommonAxisSettings color={theme.backgroundColor.line}>
        <Tick visible={false} color={theme.backgroundColor.line} />
      </CommonAxisSettings>
      <ArgumentAxis>
        <Label visible={false} />
      </ArgumentAxis>
      <ValueAxis>
        {xAxisLabel && <Label customizeText={xAxisLabel} />}
      </ValueAxis>
      <DevExtremeLegend visible={false} />
      <Size height={100 * data.bars.length} />

      <CommonSeriesSettings
        argumentField="name"
        valueField="value"
        type="bar"
        ignoreEmptyPoints
        barWidth={44}
      >
        <Label
          visible
          position="outside"
          // Sort of a hack, set offset greater than chart height
          // force label to be inside bar, right align
          // https://supportcenter.devexpress.com/ticket/details/t481357/dxchart-is-it-possible-to-display-labels-below-bars
          horizontalOffset={1000 * data.bars.length}
          {...(pointLabel && {
            customizeText: pointLabel,
          })}
        >
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>
      <SeriesTemplate nameField="name" />

      {children}
    </Chart>
  );
};

export default HorizontalBarChart;
