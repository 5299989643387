import styled from '@emotion/styled';
import TableRow from '@mui/material/TableRow';

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    cursor: pointer;

    &:nth-of-type(odd) {
      background: ${({ theme }) => theme.backgroundColor.background2};
    }

    &:nth-of-type(even) {
      background: ${({ theme }) => theme.backgroundColor.background1};
    }
  }
  .MuiTableCell-head {
    cursor: default;
  }
`;

export default StyledTableRow;
