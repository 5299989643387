import IframeResizer from 'iframe-resizer-react';
import React from 'react';
import { Flex } from 'rebass';

import { paths } from '../../../routing/paths';
import { Breadcrumbs } from '../../atoms';
import { Layout } from '../../templates';

const ReportIframe: React.FC = () => {
  return (
    <Layout>
      <Flex mb={3}>
        <Breadcrumbs
          segments={[
            { name: 'Reports', path: paths.reports() },
            { name: 'Shared Branching', path: undefined },
          ]}
        />
      </Flex>

      <IframeResizer
        src={paths.iframeReport()}
        style={{ width: '1px', minWidth: '100%', minHeight: '100vh' }}
      />
    </Layout>
  );
};

export default ReportIframe;
