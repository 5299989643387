import { format, startOfDay } from 'date-fns';

const formatChoices = {
  Mddyyyy: 'M/d/yyyy',
  MMddyyyy: 'MM/dd/yyyy',
  LLLdyyyy: 'LLL d, yyyy',
  EEEELLLLd: 'EEEE LLLL d',
};

export const formats = {
  standard: formatChoices.Mddyyyy,
  input: formatChoices.MMddyyyy,
  shortMonthDayAndYear: formatChoices.LLLdyyyy,
  longDayAndMonth: formatChoices.EEEELLLLd,
};

export const formatDate = ({
  format: givenFormat,
  date,
}: {
  format?: string;
  date: Date | number;
}) => {
  return format(date, givenFormat || formats.standard);
};

export const isSameDay = (dirtyDateLeft: Date, dirtyDateRight: Date) => {
  const dateLeftStartOfDay = startOfDay(dirtyDateLeft);
  const dateRightStartOfDay = startOfDay(dirtyDateRight);

  return dateLeftStartOfDay.getTime() === dateRightStartOfDay.getTime();
};

export * from 'date-fns';
