/* eslint-disable react-hooks/rules-of-hooks */
import { WidgetDataArgs } from './types';
import { getWidgetData, REPORTS } from './util';
import { makePoints } from '../lineChartCurves';
import {
  AgeOptions,
  age,
  transactionTypePosFilter,
  TransactionTypePosOptions,
} from '../reportFilters/dummySegmentationFiltersData';
import {
  report,
  scenario,
  when,
  detailedSegmentationFilter,
  segmentationFilter,
  widget,
} from '../scriptDsl';
import * as W from './demo1/widgets';

const { transactionAnalysis, transactionAnalysisAfter3Months } = REPORTS;

const xScaleDefaultTo = (defaultVal: number) => {
  return window.xScale ? Number(window.xScale) : defaultVal;
};

const yScaleDefaultTo = (defaultVal: number) => {
  return window.yScale ? Number(window.yScale) : defaultVal;
};

const seedDefaultTo = (defaultVal: number) => {
  return window.seed ? Number(window.seed) : defaultVal;
};

const data = () => {
  return {
    interaction0: {
      [W.totalSpendCallout.id]: {
        main: '$20,390,230',
        compare: '6.3% over last period',
      },
      [W.totalVolumeCallout.id]: {
        main: '838,107',
        compare: '3.8% over last period',
      },
      [W.interchangeCallout.id]: {
        main: '$241,390',
        compare: '5.4% over last period',
      },
      [W.totalActiveCardsCallout.id]: {
        main: '73,870',
        compare: '5.4% over last period',
      },
    },
    interaction1: {
      [W.totalSpendCallout.id]: {
        main: '$3,670,241',
        compare: '6.3% over last period',
      },
      // 3,670,241 / 90 = ~$40780/day avg
      [W.totalSpendLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.07),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(300),
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 40000 },
            { x: new Date(2021, 7, 1), y: 42000 },
            { x: new Date(2021, 8, 1), y: 41000 },
          ],
        }),
      ],
      // 241390 / 90 days = 2682/day
      [W.interchangeLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.07),
          yScale: yScaleDefaultTo(0.03),
          seed: seedDefaultTo(45),
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 2400 },
            { x: new Date(2021, 6, 15), y: 2450 },
            { x: new Date(2021, 7, 1), y: 2500 },
            { x: new Date(2021, 7, 15), y: 2550 },
            { x: new Date(2021, 8, 1), y: 2600 },
            { x: new Date(2021, 8, 1), y: 2650 },
          ],
        }),
      ],
    },
    interaction2: {
      [W.totalSpendCallout.id]: {
        main: '$550,536',
        compare: '6.3% over last period',
      },
      [W.totalVolumeCallout.id]: {
        main: '125,716',
        compare: '3.8% over last period',
      },
      [W.interchangeCallout.id]: {
        main: '$36,208',
        compare: '5.4% over last period',
      },
      [W.totalActiveCardsCallout.id]: {
        main: '11,080',
        compare: '5.4% over last period',
      },
      // 550536 / 90 = 6117/day
      [W.totalSpendLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(42),
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 6000 },
            { x: new Date(2021, 6, 19), y: 5500 },
            { x: new Date(2021, 7, 1), y: 5800 },
            { x: new Date(2021, 8, 1), y: 5600 },
          ],
        }),
      ],
      // 36208 / 90 = 402/day
      [W.interchangeLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(42),
          deformMin: 0.8,
          deformMax: 1.2,
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 450 },
            { x: new Date(2021, 7, 1), y: 430 },
            { x: new Date(2021, 7, 15), y: 410 },
            { x: new Date(2021, 8, 1), y: 400 },
          ],
        }),
      ],
    },
    interaction3: {
      [W.totalSpendCallout.id]: {
        main: '$148,644',
        compare: '6.8% over last period',
      },
      [W.totalVolumeCallout.id]: {
        main: '33,943',
        compare: '3.8% over last period',
      },
      [W.interchangeCallout.id]: {
        main: '$9,766',
        compare: '5.4% over last period',
      },
      [W.totalActiveCardsCallout.id]: {
        main: '11,080',
        compare: '5.4% over last period',
      },
      // 148644 / 90 = 1651/day
      [W.totalSpendLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(61),
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 1000 },
            { x: new Date(2021, 7, 1), y: 1300 },
            { x: new Date(2021, 8, 1), y: 2000 },
          ],
        }),
      ],
      // 9776 / 90 = 108/day
      [W.interchangeLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(64),
          deformMin: 0.98,
          deformMax: 1.05,
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 90 },
            { x: new Date(2021, 7, 1), y: 100 },
            { x: new Date(2021, 8, 1), y: 110 },
          ],
        }),
      ],
    },
    interaction4: {
      // 170940/ 90 = 1899/day
      [W.totalSpendLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(63),
          deformMin: 0.9,
          deformMax: 1.1,
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 1700 },
            { x: new Date(2021, 7, 1), y: 1700 },
            { x: new Date(2021, 8, 1), y: 1700 },
          ],
        }),
      ],
      // 9776 / 90 = 108/day (same a last)
      [W.interchangeLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(64),
          deformMin: 0.98,
          deformMax: 1.05,
          keyPoints: [
            { x: new Date(2021, 6, 1), y: 90 },
            { x: new Date(2021, 7, 1), y: 100 },
            { x: new Date(2021, 8, 1), y: 110 },
          ],
        }),
      ],
      [W.totalSpendCallout.id]: {
        main: '$170,940',
        compare: '6.3% over last period',
      },
    },
    report2: {
      [W.totalSpendCallout.id]: {
        main: '$255,128',
        compare: '12% over last period',
      },
      [W.totalVolumeCallout.id]: {
        main: '40,731',
        compare: '9% over last period',
      },
      [W.interchangeCallout.id]: {
        main: '$11,731',
        compare: '11% over last period',
      },
      [W.totalActiveCardsCallout.id]: {
        main: '12,080',
        compare: '5.4% over last period',
      },
      // 170940/ 90 = 1899/day
      [W.totalSpendLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(63),
          deformMin: 0.9,
          deformMax: 1.15,
          keyPoints: [
            { x: new Date(2021, 9, 1), y: 1600 },
            { x: new Date(2021, 10, 1), y: 1750 },
            { x: new Date(2021, 11, 1), y: 5000 },
          ],
        }),
      ],
      [W.interchangeLineChart.id]: [
        makePoints({
          xScale: xScaleDefaultTo(0.06),
          yScale: yScaleDefaultTo(0.04),
          seed: seedDefaultTo(42),
          deformMin: 0.9,
          deformMax: 1.2,
          keyPoints: [
            { x: new Date(2021, 9, 1), y: 150 },
            { x: new Date(2021, 10, 1), y: 180 },
            { x: new Date(2021, 10, 8), y: 250 },
            { x: new Date(2021, 10, 25), y: 350 },
            { x: new Date(2021, 11, 1), y: 400 },
          ],
        }),
      ],
    },
  };
};

const interaction0 = () =>
  scenario(
    'Demo1::interaction0',
    when(report(transactionAnalysis.id).isOpen()),
    () => data().interaction0
  );

const interaction1A = () =>
  scenario(
    'Demo1::interaction1A',
    when(
      report(transactionAnalysis.id).isOpen(),
      widget(W.totalSpendLineChart.id)
        .hasFilter('mcc')
        .including({ values: ['restaurants'] }),
      widget(W.interchangeLineChart.id).exists()
    ),
    () => data().interaction1
  );

const interaction1B = () =>
  scenario(
    'Demo1::interaction1B',
    when(
      report(transactionAnalysis.id).isOpen(),
      widget(W.totalSpendLineChart.id)
        .hasFilter('mcc')
        .including({ values: ['restaurants'] }),
      widget(W.totalSpendCallout.id)
        .hasFilter('mcc')
        .including({ values: ['restaurants'] }),
      widget(W.interchangeLineChart.id).exists()
    ),
    () => data().interaction1
  );

const interaction2 = () =>
  scenario(
    'Demo1::interaction2',
    when(
      report(transactionAnalysis.id).isOpen(),
      detailedSegmentationFilter(age).hasValue(
        AgeOptions.TwentyFiveToThirtyFour
      )
    ),
    () => data().interaction2
  );

const interaction3 = () =>
  scenario(
    'Demo1::interaction3',
    when(
      report(transactionAnalysis.id).isOpen(),
      segmentationFilter(transactionTypePosFilter).hasValue(
        TransactionTypePosOptions.CardNotPresent
      ),
      widget(W.totalSpendCallout.id).exists()
    ),
    () => data().interaction3
  );

const interaction4a = () =>
  scenario(
    'Demo1::interaction4a',
    when(
      report(transactionAnalysis.id).isOpen(),
      widget(W.totalSpendLineChart.id)
        .hasFilter('mcc')
        .including({ values: ['delivery_services'] }),
      widget(W.interchangeLineChart.id).exists()
    ),
    () => data().interaction4
  );

const interaction4b = () =>
  scenario(
    'Demo1::interaction4b',
    when(
      report(transactionAnalysis.id).isOpen(),
      widget(W.totalSpendLineChart.id)
        .hasFilter('mcc')
        .including({ values: ['delivery_services'] }),
      widget(W.totalSpendCallout.id)
        .hasFilter('mcc')
        .including({ values: ['delivery_services'] }),
      widget(W.interchangeLineChart.id).exists()
    ),
    () => data().interaction4
  );

const report2 = () =>
  scenario(
    'Demo1::report2',
    when(report(transactionAnalysisAfter3Months.id).isOpen()),
    () => data().report2
  );

const widgetData = (context: WidgetDataArgs) =>
  getWidgetData(context, [
    interaction0(),
    interaction1A(),
    interaction1B(),
    interaction2(),
    interaction3(),
    interaction4a(),
    interaction4b(),
    report2(),
  ]);

export default {
  widgetData,
};
/* eslint-enable react-hooks/rules-of-hooks */
