import { date, DateSchema, object } from 'yup';

export const customRangePickerValidation = object().shape({
  startDate: date().defined().typeError('Invalid date'),
  endDate: date()
    .defined()
    .typeError('Invalid date')
    .when('startDate', (startDate: Date, schema: DateSchema) => {
      if (schema.isType(startDate)) {
        return schema.min(startDate, 'End date must later than start date');
      }
      return schema;
    }),
});
