import { Link as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

import { styleUtils } from '../../styles/settings';

export const Link = styled(RouterLink)`
  ${styleUtils.linkStyle}
`;

export const UnstyledLink = styled(RouterLink)``;

export default Link;
