import { Box } from 'rebass';

import styled from '@emotion/styled';

const paddingMap = {
  small: '20px',
  medium: '25px 40px',
  mediumHeader: '25px 15px 25px 40px',
};

interface CardBodyProps {
  padding?: keyof typeof paddingMap;
}

interface CardProps {
  leftBorder?: boolean;
  leftBorderColor?: string;
}

export const Card = styled(Box)<CardProps>`
  box-shadow: ${({ theme }) => theme.shadows.widgetShadow};
  border-top-left-radius: ${({ theme, leftBorder }) =>
    leftBorder ? '0' : theme.borders.default.radius};
  border-top-right-radius: ${({ theme }) => theme.borders.default.radius};
  border-bottom-right-radius: ${({ theme }) => theme.borders.default.radius};
  border-bottom-left-radius: ${({ theme, leftBorder }) =>
    leftBorder ? '0' : theme.borders.default.radius};
  background: ${({ theme }) => theme.backgroundColor.background1};
  overflow: hidden;
  border-left-style: solid;
  border-left-width: ${({ leftBorder }) => (leftBorder ? '5px' : '0')};
  border-left-color: ${({ theme, leftBorderColor }) =>
    leftBorderColor || theme.backgroundColor.button.default};
`;

export const CardBody = styled.div<CardBodyProps>`
  padding: ${({ padding = 'small' }) => paddingMap[padding]};
`;

export const CardHeader = styled.div`
  padding: 20px;
`;

export const CardDivider = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.backgroundColor.element1};
`;

interface CardFooterProps {
  hasBackground?: boolean;
}

export const CardFooter = styled.div<CardFooterProps>`
  padding: 20px;

  ${(props) =>
    props.hasBackground &&
    `
      border-top: 1px solid ${props.theme.backgroundColor.element1};
      background: ${props.theme.backgroundColor.background1};
    `}
`;
