// TODO: rename this file to something more contextual
import * as R from 'ramda';

import { ReportWidget, WidgetData, WidgetType } from '../../types';
import { Report } from '../../types/report';
import { ReportFilterContext, Scenario, ScenarioDataType } from '../scriptDsl';
import { dummyReportsData as reportsData } from '../reports/dummyReportsData';

export const findReport = (
  reports: Record<string, Report>,
  { id }: { id: string }
) => {
  const found = reports[id];

  if (!found) {
    /* eslint-disable-next-line */
    throw `Report named '${name}' was expected in dummyReportsData but could not be found`;
  }

  return found;
};

export const findWidget = ({
  name,
  type,
  reportWidgets,
}: {
  name: string;
  type: WidgetType;
  reportWidgets: Record<string, ReportWidget>;
}) => {
  const toReturn = R.find(
    ({ widget }) => widget.name === name && widget.type === type,
    R.values(reportWidgets)
  );
  if (!toReturn) {
    // eslint-disable-next-line
    throw `Could not find a widget named "${name}" in passed report widgets`;
  }
  return toReturn.widget;
};

// We want the _last_ matching scenario because it's likely
// the scenarios will build on one-another in terms of
// filters applied. So this way the DSL user doesn't need
// to list scenarios in reverse.
export const lastMatchingScenario = <
  Context = ReportFilterContext,
  Data = ScenarioDataType
>(
  context: Context,
  scenarios: Scenario<Context, Data>[]
) => {
  const runScenarios = R.map((scenario) => scenario.run(context), scenarios);
  const matchingScenarios = R.reject(R.isNil, runScenarios);
  return R.last(matchingScenarios);
};

export const getWidgetData = <
  Context extends ReportFilterContext,
  Data extends ScenarioDataType
>(
  context: Context,
  scenarios: Scenario<Context, Data>[]
) => {
  const widgetId = context?.widget?.id;
  if (!widgetId) {
    // console.log(
    //   '### in getWidgetData: no widget found on passed context: ',
    //   context
    // );
    return undefined;
  }
  const scenario = lastMatchingScenario(context, scenarios);
  if (!scenario) {
    // console.log('### No scenarios matched for widget ', context?.widget);
    return undefined;
  }

  // console.log('### Matching scenario: ', scenario, context);

  // TODO: yeaaaah...how do we NOT hit this with the type hammer?
  const widgetData = scenario.getData()[widgetId] as WidgetData | undefined;

  return widgetData;
};

export const REPORTS = {
  transactionAnalysis: findReport(reportsData, {
    id: '1',
  }),
  transactionAnalysisAfter3Months: findReport(reportsData, {
    id: '26',
  }),
};
