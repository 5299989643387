import { CheckboxGroup, DropdownItem } from '../../components/atoms';

export const dummyBinFilterData: DropdownItem[] = [
  {
    label: '789652',
    value: '789652',
  },
  {
    label: '789653',
    value: '789653',
  },
  {
    label: '789654',
    value: '789654',
  },
  {
    label: '789655',
    value: '789655',
  },
  {
    label: '789656',
    value: '789656',
  },
  {
    label: '789657',
    value: '789657',
  },
];

export const dummyMCCFilterData: DropdownItem[] = [
  {
    label: 'Health Care',
    value: 'healthcare',
  },
  {
    label: 'Retail',
    value: 'retail',
  },
  {
    label: 'Other Services',
    value: 'otherservices',
  },
  {
    label: 'Travel',
    value: 'travel',
  },
  {
    label: 'Transportation',
    value: 'transportation',
  },
  {
    label: 'Financial',
    value: 'financial',
  },
  {
    label: 'Entertainment',
    value: 'entertainment',
  },
  {
    label: 'Restaurants',
    value: 'restaurants',
  },
  {
    label: 'Digital Services',
    value: 'digitalservices',
  },
  {
    label: 'Personal Services',
    value: 'personalservices',
  },
  {
    label: 'Education',
    value: 'education',
  },
];

export const dummyDigitalWalletFilterData: DropdownItem[] = [
  {
    label: 'Apple Pay',
    value: 'applepay',
  },
  {
    label: 'Samsung Pay',
    value: 'sansungpay',
  },
  {
    label: 'Google Pay',
    value: 'googlepay',
  },
  {
    label: 'Amazon One Pay',
    value: 'amazononepay',
  },
  {
    label: 'Garmin Pay',
    value: 'garminpay',
  },
  {
    label: 'Fitbit Pay',
    value: 'fitbitpay',
  },
];

export const transactionDetails: CheckboxGroup[] = [
  {
    groupTitle: 'Card Present',
    rows: [
      {
        rowTitle: 'Form Factor',
        checkboxItems: [
          { value: 'contactless_chip', label: 'Contactless Chip' },
          { value: 'digital_wallet', label: 'Digital Wallet' },
          { value: 'emv_chip', label: 'EMV Chip' },
          { value: 'mag_stripe', label: 'Mag Stripe' },
          { value: 'manual_key_entry', label: 'Manual Key Entry' },
        ],
      },
      {
        rowTitle: 'Verification Method',
        checkboxItems: [
          { value: 'avs', label: 'AVS' },
          { value: 'no_cvm', label: 'No CVM' },
          { value: 'offline_pin', label: 'Offline PIN' },
          { value: 'online_pin', label: 'Online PIN' },
          { value: 'signature', label: 'Signature' },
        ],
      },
    ],
  },
  {
    groupTitle: 'Card Not Present',
    rows: [
      {
        rowTitle: 'Form Factor',
        checkboxItems: [
          { value: 'online', label: 'Online' },
          { value: 'card_on_file', label: 'Card on File' },
          { value: 'recurring', label: 'Recurring' },
        ],
      },
      {
        rowTitle: 'Verification Method',
        checkboxItems: [
          { value: 'avs', label: 'AVS' },
          { value: 'cvv', label: 'CVV' },
          { value: 'no_cvm', label: 'No CVM' },
        ],
      },
      {
        rowTitle: 'Recurring Type',
        checkboxItems: [
          { value: 'bill_pay', label: 'Bill Pay' },
          { value: 'money_transfer', label: 'Money Transfer' },
          { value: 'subscriptions', label: 'Subscriptions' },
        ],
      },
    ],
  },
];

export const dummyInstitutions: DropdownItem[] = [
  {
    label: '1st Community Credit Union',
    value: '1stcommunitycreditunion',
  },
  {
    label: '1st Gateway Credit Union',
    value: '1stgatewaycreditunion',
  },
  {
    label: '1st United Services Credit Union',
    value: '1stunitedservicescreditunion',
  },
  {
    label: 'ACE Credit Union',
    value: 'acecreditunion',
  },
  {
    label: 'Orange Credit Union',
    value: 'Orange',
  },
];
