import React, { useEffect, useState } from 'react';

import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import * as R from 'ramda';
import RGL from 'react-grid-layout';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Flex } from 'rebass';

import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';

import { standardDateRangeFilters } from '../../../data/report/dateRangeFilterData';
import {
  dummyBinFilterData,
  dummyDigitalWalletFilterData,
  dummyInstitutions,
  dummyMCCFilterData,
  transactionDetails,
} from '../../../data/report/dummyBinFilterData';
import {
  ActivityTypeOptions,
  CardTypeOptions,
  NetworkOptions,
} from '../../../data/reportFilters/dummySegmentationFiltersData';
import {
  DUMMY_IFRAME_REPORT_ID,
  dummyReportsData,
} from '../../../data/reports/dummyReportsData';
import { WidgetDataArgs } from '../../../data/scripts/types';
import { dummyTransactionAnalysisReportCollaborators as collaborators } from '../../../data/users/dummyUserData';
import { widgetData } from '../../../data/widget/dummyWidgetData';
import { paths } from '../../../routing/paths';
import {
  AdditionalFilterSelection,
  AdditionalFilterSelections,
  AllDateRangeOptions,
  CustomDateRangeOptions,
  DateTypeOptions,
  DetailedSegmentationFilters,
  FilterType,
  FilterTypeOptions,
  Report as ReportData,
  ReportAlert,
  ReportWidget,
  ScheduledReport,
  SegmentationFilters as SegmentationFiltersType,
  Widget,
  WidgetId,
} from '../../../types';
import {
  addItemById,
  mapKvpValues,
  mapValues,
  max,
  removeItemById,
} from '../../../util/collections';
import {
  calculateInitialEndDate,
  calculateInitialStartDate,
  createNewReport,
  NEW_REPORT_ID,
} from '../../../util/report';
import { layoutSizeAndConstraintsProps } from '../../../util/widget';
import { Breadcrumbs, ButtonDropdown, DropdownItem } from '../../atoms';
import { ReportFilters } from '../../molecules';
import { ReportMenuItems, WidgetGrid } from '../../organisms';
import { EditWidgetFormValues } from '../../organisms/EditWidgetModal/editWidgetTypes';
import { transformFormToWidget } from '../../organisms/EditWidgetModal/editWidgetUtils';
import { MenuItems } from '../../organisms/ReportMenuItems';
import { Layout } from '../../templates';
import AddWidgetSection from './AddWidgetSection';
import ReportIframe from './ReportIframe';
import ReportNotifications from './ReportNotifications';
import ScheduleReportModal from './ScheduleReportModal/ScheduleReportModal';
import SetAlertModal from './SetAlertModal/SetAlertModal';
import ShareModal from './ShareModal';

const MI = MenuItems;

const ReportFilterContainer = styled(Flex)`
  margin-right: -8px;
  margin-left: -8px;
  padding-bottom: 16px;
  > * {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const TitleInput = styled.input`
  font-size: ${({ theme }) => theme.fontSize.h3};
  color: ${({ theme }) => theme.textColor.text1};
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

const dateTypeDropdownItems: Record<DateTypeOptions, DropdownItem> = {
  [DateTypeOptions.AuthorizationDate]: {
    label: 'Authorization Date',
    value: DateTypeOptions.AuthorizationDate,
  },
  [DateTypeOptions.SettlementDate]: {
    label: 'Settlement Date',
    value: DateTypeOptions.SettlementDate,
  },
};

const newWidgetLayoutFor = (widget: Widget, gridLayouts: RGL.Layout[]) => {
  const currentMaxY = max(R.map(R.prop('y'), gridLayouts));

  return {
    i: widget.id,
    x: 0,
    y: currentMaxY > 0 ? currentMaxY + 1 : 0,
    ...layoutSizeAndConstraintsProps(widget.type, widget.size),
  };
};

interface NameFormValues {
  name: string;
}

enum Modals {
  Filter = 'Filter',
  Share = 'Share',
  ScheduleReport = 'ScheduleReport',
  SetAlert = 'SetAlert',
}

const ReportModals = (() => {
  const initialState = {
    [Modals.Filter]: false,
    [Modals.Share]: false,
    [Modals.ScheduleReport]: false,
    [Modals.SetAlert]: false,
  };

  const open = R.curry((state: Record<Modals, boolean>, modal: Modals) => {
    return { ...state, ...{ [modal]: true } };
  });

  const close = R.curry((state: Record<Modals, boolean>, modal: Modals) => {
    return { ...state, ...{ [modal]: false } };
  });

  const isOpen = R.curry((state: Record<Modals, boolean>, modal: Modals) => {
    return state[modal];
  });

  return {
    initialState,
    open,
    close,
    isOpen,
  };
})();

const Report: React.FC = () => {
  const { id: reportId } = useParams<{ id: string }>();

  const reportData: ReportData =
    reportId === NEW_REPORT_ID ? createNewReport : dummyReportsData[reportId];

  const initialDateRange = reportData.filters.DateRange;

  const [reportName, setReportName] = useState(reportData.name);

  const [filters, setFilters] = useState<FilterTypeOptions>({
    [FilterType.DateType]:
      reportData.filters.DateType || DateTypeOptions.AuthorizationDate,
    [FilterType.DateRange]: initialDateRange,
    [FilterType.BIN]: reportData.filters.BIN || [],
    [FilterType.MCCGroup]: reportData.filters.MCCGroup || [],
    [FilterType.DigitalWallet]: reportData.filters.DigitalWallet || [],
    [FilterType.Institutions]: reportData.filters.Institutions || [
      dummyInstitutions[0].value,
    ],
    [FilterType.ActivityType]:
      reportData.filters.ActivityType || ActivityTypeOptions.POS,
    [FilterType.Network]: reportData.filters.Network || [],
    [FilterType.CardType]: reportData.filters.CardType || [],
  });

  type GroupValuesType = {
    [groupTitle: string]: {
      [rowTitle: string]: string[];
    };
  };

  const [groupValues, setGroupValues] = useState<GroupValuesType>(() => {
    const initialGroupValues: GroupValuesType = {};
    transactionDetails.forEach((group) => {
      initialGroupValues[group.groupTitle] = {};
      group.rows.forEach((row) => {
        initialGroupValues[group.groupTitle][row.rowTitle] = [];
      });
    });
    return initialGroupValues;
  });

  const [savedFilters, setSavedFilters] = useState<FilterTypeOptions>(filters);
  const [tempGroupValues, setTempGroupValues] = useState<GroupValuesType>(
    groupValues
  );

  const applyFilters = () => {
    setSavedFilters(filters);
    setGroupValues(tempGroupValues);
    // ... handle other states if needed
  };

  // Close Without Changes - Revert current state to saved state
  const closeWithoutChanges = () => {
    setFilters(savedFilters);
    setTempGroupValues(groupValues);
    // ... handle other states if needed
  };

  const handleGroupChange = (
    groupTitle: string,
    rowTitle: string,
    values: string[],
    isChecked: boolean
  ) => {
    setTempGroupValues((prevTempValues) => {
      const updatedValues = new Set(prevTempValues[groupTitle][rowTitle]);
      values.forEach((value) => {
        if (isChecked) {
          updatedValues.add(value);
        } else {
          updatedValues.delete(value);
        }
      });
      return {
        ...prevTempValues,
        [groupTitle]: {
          ...prevTempValues[groupTitle],
          [rowTitle]: Array.from(updatedValues),
        },
      };
    });
  };

  const networkOptions: DropdownItem[] = Object.entries(NetworkOptions).map(
    ([key, value]) => ({
      label: value,
      value,
    })
  );

  const activityTypeOptions: DropdownItem[] = Object.entries(
    ActivityTypeOptions
  ).map(([key, value]) => ({
    label: value,
    value,
  }));

  const cardTypeOptions: DropdownItem[] = Object.entries(CardTypeOptions).map(
    ([key, value]) => ({
      label: value,
      value,
    })
  );

  const [
    additionalFilters,
    setAdditionalFilters,
  ] = useState<AdditionalFilterSelections>({});

  const [
    segmentationFilters,
    setSegmentationFilters,
  ] = useState<SegmentationFiltersType>(reportData.segmentationFilters || {});

  const [
    detailedSegmentationFilters,
    setDetailedSegmentationFilters,
  ] = useState<DetailedSegmentationFilters>(
    reportData.detailedSegmentationFilters || {}
  );

  const updateReportWidgetsData = ({
    reportWidgets,
    ...restWidgetDataArgs
  }: Omit<WidgetDataArgs, 'widget'> & {
    reportWidgets: Record<string, ReportWidget>;
  }) =>
    mapValues((reportWidget) => {
      return {
        ...reportWidget,
        ...{
          data: widgetData({
            ...restWidgetDataArgs,
            report: restWidgetDataArgs.report,
            widget: reportWidget.widget,
          }),
        },
      };
    }, reportWidgets);

  const initialReportWidgets = updateReportWidgetsData({
    reportWidgets: reportData.widgets,
    report: reportData,
    filters,
    additionalFilters,
    segmentationFilters,
    detailedSegmentationFilters,
    offset: 0,
  });

  const [reportWidgets, setReportWidgets] = useState<
    Record<string, ReportWidget>
  >(initialReportWidgets);

  const [startDate, setStartDate] = useState<Date>(
    calculateInitialStartDate(reportData)
  );
  const [endDate, setEndDate] = useState<Date>(
    calculateInitialEndDate(reportData)
  );

  const [gridLayouts, setGridLayouts] = useState<RGL.Layout[]>([]);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [openModals, setOpenModals] = useState(ReportModals.initialState);
  const [scheduledReports, setScheduledReports] = useState<
    Record<string, ScheduledReport>
  >({});
  const [alerts, setAlerts] = useState<Record<string, ReportAlert>>({});

  const isOpen = ReportModals.isOpen(openModals);
  const open = R.pipe(R.identity, ReportModals.open(openModals), setOpenModals);
  const close = R.pipe(
    R.identity,
    ReportModals.close(openModals),
    setOpenModals
  );

  const [dummyDataCounter, setDummyDataCounter] = useState(0);

  useEffect(() => {
    setDummyDataCounter((oldCounter) => oldCounter + 1);
  }, [filters, additionalFilters, startDate, endDate, startTime, endTime]);

  useEffect(() => {
    setReportWidgets((oldReportWidgets) =>
      updateReportWidgetsData({
        reportWidgets: oldReportWidgets,
        report: reportData,
        filters,
        additionalFilters,
        segmentationFilters,
        detailedSegmentationFilters,
        offset: dummyDataCounter,
      })
    );
  }, [
    reportData,
    filters,
    additionalFilters,
    segmentationFilters,
    detailedSegmentationFilters,
    dummyDataCounter,
  ]);

  const { register, handleSubmit, setValue } = useForm<NameFormValues>();

  const onTitleSubmit: SubmitHandler<NameFormValues> = (data) => {
    const newReportName = data.name || 'Untitled report';
    setValue('name', newReportName);
    setReportName(newReportName);
  };

  const onMenuItemClicked = R.curry(
    (
      closeMenu: () => void,
      menuItem: MenuItems,
      event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => {
      const handleMenuItem = () => {
        switch (menuItem) {
          case MI.Share:
            return open(Modals.Share);
          case MI.ScheduleReport:
            return open(Modals.ScheduleReport);
          case MI.SetAlert:
            return open(Modals.SetAlert);
          default:
            return null;
        }
      };

      event.stopPropagation();
      handleMenuItem();
      closeMenu();
    }
  );

  const onLayoutChange = (layouts: RGL.Layout[]) => {
    const newReportWidgets = mapKvpValues((widgetId, reportWidget) => {
      const layout = R.find(({ i }) => widgetId === i, layouts);
      // The set of layouts did not contain the widget
      if (!layout) {
        return reportWidget;
      }
      return { ...reportWidget, ...{ layout } };
    }, reportWidgets);

    setGridLayouts(layouts);
    // TODO: Nothing is currently being _done_ with this...
    // consider storing widget state in, ie, redux (or something tied to
    // localstorage anyway)
    setReportWidgets(newReportWidgets);
  };

  const handleDateTypeChange = (event: SelectChangeEvent<unknown>) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.DateType]: event.target.value as DateTypeOptions,
      };
    });

    // TODO: maybe save the setting in the future
  };

  const handleDateChange = (newValue: string) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.DateType]: newValue,
      };
    });
  };

  const handleBinChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.BIN]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleNetworkChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.Network]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleCardTypeChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.CardType]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleActivityChange = (newValue: string) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.ActivityType]: newValue, // Use newValue directly
      };
    });
  };

  const handleMCCChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.MCCGroup]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleDigitalWalletChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.DigitalWallet]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleInstitutionChange = (newSelectedValues: string[]) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.Institutions]: newSelectedValues, // Directly use the newSelectedValues array
      };
    });
  };

  const handleDateRangeChange = (value: AllDateRangeOptions) => {
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [FilterType.DateRange]: value as AllDateRangeOptions,
      };
    });

    if (value !== CustomDateRangeOptions.Custom) {
      setEndDate(standardDateRangeFilters[value].endDateFn());
      setStartDate(standardDateRangeFilters[value].startDateFn());
    }
  };

  const handleCustomDateRangeChange = (
    newStartDate: Date,
    newEndDate: Date,
    newStartTime: Date | null,
    newEndTime: Date | null
  ) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleApplyAdditionalFilters = (
    filtersToApply: AdditionalFilterSelections
  ): void => {
    setAdditionalFilters((oldFilters) => {
      return {
        ...oldFilters,
        ...filtersToApply,
      };
    });
  };

  const handleRemoveAdditionalFilters = (
    categoryValue: AdditionalFilterSelection['categoryValue']
  ): void => {
    setAdditionalFilters(R.dissoc(categoryValue));
  };

  const handleCreateReportWidget = (widget: Widget) => {
    const layout = newWidgetLayoutFor(widget, gridLayouts);

    const reportWidget: ReportWidget = {
      reportId,
      widget,
      layout,
      data: widgetData({
        report: reportData,
        widget,
        filters,
        additionalFilters,
        segmentationFilters,
        detailedSegmentationFilters,
        offset: dummyDataCounter,
      }),
    };

    setReportWidgets({
      ...reportWidgets,
      ...{ [widget.id]: reportWidget },
    });
  };

  const handleEditWidget = (widgetId: WidgetId) => (
    formData: EditWidgetFormValues
  ) => {
    setReportWidgets((prevReportWidgets) => {
      const widget = {
        ...prevReportWidgets[widgetId].widget,
        ...transformFormToWidget(widgetId, formData),
      };

      const layout = {
        ...prevReportWidgets[widgetId].layout,
        ...layoutSizeAndConstraintsProps(widget.type, widget.size),
      };

      const data = widgetData({
        report: reportData,
        widget,
        filters,
        additionalFilters,
        segmentationFilters,
        detailedSegmentationFilters,
        offset: dummyDataCounter,
      });

      return {
        ...prevReportWidgets,
        [widgetId]: {
          ...prevReportWidgets[widgetId],
          widget,
          layout,
          data,
        },
      };
    });
  };

  const handleSegmentationFiltersChanged = (
    givenSegmentationFilters: SegmentationFiltersType
  ) => {
    setSegmentationFilters(givenSegmentationFilters);
    // TODO: this is where we'd probably do something w/ the
    // changed segmentation filters - for now we just update
    // the counter that simulates a data update
    setDummyDataCounter(dummyDataCounter + 1);
  };

  const handleDetailedSegmentationFiltersChanged = (
    givenDetailedSegmentationFilters: DetailedSegmentationFilters
  ) => {
    setDetailedSegmentationFilters(givenDetailedSegmentationFilters);
    setDummyDataCounter(dummyDataCounter + 1);
  };

  const handleRemoveScheduledReport = (report: ScheduledReport) => {
    setScheduledReports((prevReports) => removeItemById(prevReports, report));
  };

  const handleAddScheduledReport = (report: ScheduledReport) => {
    setScheduledReports((prevReports) => addItemById(prevReports, report));
  };

  const handleRemoveAlert = (alert: ReportAlert) => {
    setAlerts((prevAlerts) => removeItemById(prevAlerts, alert));
  };

  const handleAddAlert = (alert: ReportAlert) => {
    setAlerts((prevAlerts) => addItemById(prevAlerts, alert));
  };

  const renderSelectedValues = (filterKey: FilterType) => {
    return filters[filterKey].join(', ');
  };

  return (
    <Layout>
      <AnimateSharedLayout>
        <AnimatePresence>
          <ReportNotifications reportId={reportId} />
          <motion.div
            layout="position"
            key="report"
            transition={{ ease: 'easeOut', duration: 0.5 }}
          >
            <Flex mb={3}>
              <Breadcrumbs
                segments={[
                  { name: 'Reports', path: paths.reports() },
                  { name: reportName, path: undefined },
                ]}
              />
            </Flex>
            <Flex justifyContent="space-between" mb={4}>
              <TitleInput
                placeholder="Report title..."
                defaultValue={reportName}
                {...R.dissoc('onBlur', register('name'))}
                onBlur={(e) => {
                  register('name').onBlur(e);
                  handleSubmit(onTitleSubmit)();
                }}
              />

              <ButtonDropdown
                renderItems={(closeMenu) => (
                  <ReportMenuItems
                    closeMenu={closeMenu}
                    onMenuItemClick={onMenuItemClicked}
                    isWithinReport
                  />
                )}
              >
                Report Actions
              </ButtonDropdown>
            </Flex>
            <ReportFilters
              filters={filters}
              dateTypeDropdownItems={dateTypeDropdownItems}
              dummyBinFilterData={dummyBinFilterData}
              dummyMCCFilterData={dummyMCCFilterData}
              dummyDigitalWalletFilterData={dummyDigitalWalletFilterData}
              dummyInstitutions={dummyInstitutions}
              networkOptions={networkOptions}
              cardTypeOptions={cardTypeOptions}
              activityTypeOptions={activityTypeOptions}
              transactionDetails={transactionDetails}
              handleDateTypeChange={handleDateChange}
              handleActivityChange={handleActivityChange}
              handleBinChange={handleBinChange}
              handleNetworkChange={handleNetworkChange}
              handleCardTypeChange={handleCardTypeChange}
              handleMCCChange={handleMCCChange}
              handleDigitalWalletChange={handleDigitalWalletChange}
              handleGroupChange={handleGroupChange}
              handleInstitutionChange={handleInstitutionChange}
              groupValues={tempGroupValues}
              setGroupValues={setTempGroupValues}
              applyFilters={applyFilters}
              closeWithoutChanges={closeWithoutChanges}
            />
            {/* <ReportFilterContainer>
              <DateRangeDropdown
                selectedOption={filters[FilterType.DateRange]}
                handleDateRangeOptionChange={handleDateRangeChange}
                handleCustomDateRangeChange={handleCustomDateRangeChange}
                endDate={endDate}
                startDate={startDate}
                startTime={startTime}
                endTime={endTime}
              />
              <LabelledDropdown
                filter
                value={filters[FilterType.DateType]}
                onChange={handleDateTypeChange}
                dropdownItems={Object.values(dateTypeDropdownItems)}
                label="Date type"
              />
              {Object.values(additionalFilters).map((filter) => (
                <Chip
                  key={filter.categoryValue}
                  label={additionalFilterCategoryLabel(
                    dummyAdditionalFilterCategoriesData,
                    filter
                  )}
                  text={additionalFilterTypeLabel(
                    dummyAdditionalFilterCategoriesData,
                    filter
                  )}
                  onRemove={() => {
                    handleRemoveAdditionalFilters(filter.categoryValue);
                  }}
                  removeButtonLabel="Remove filter"
                />
              ))}
              <Button
                bold
                variant="text"
                type="button"
                onClick={() => open(Modals.Filter)}
              >
                + Add Filter
              </Button>

              <AddFilterModal
                open={isOpen(Modals.Filter)}
                onClose={() => close(Modals.Filter)}
                applyNewFilters={handleApplyAdditionalFilters}
                alreadyAppliedFilters={additionalFilters}
                aria-labelledby="Add filter modal"
                aria-describedby="Modal for adding additional filters"
              />
            </ReportFilterContainer>
            <SegmentationFilters
              onSegmentationFiltersChange={handleSegmentationFiltersChanged}
              onDetailedSegmentationFiltersChange={
                handleDetailedSegmentationFiltersChanged
              }
              segmentationFilters={segmentationFilters}
              detailedSegmentationFilters={detailedSegmentationFilters}
            /> */}
            <WidgetGrid
              onLayoutChange={onLayoutChange}
              widgets={reportWidgets}
              onEditWidgetSubmit={handleEditWidget}
            />
            <AddWidgetSection
              firstWidget={Object.values(reportWidgets).length === 0}
              onSubmit={handleCreateReportWidget}
            />
            <ShareModal
              open={isOpen(Modals.Share)}
              onClose={() => close(Modals.Share)}
              collaborators={collaborators}
            />
            <ScheduleReportModal
              scheduledReports={scheduledReports}
              open={isOpen(Modals.ScheduleReport)}
              onClose={() => close(Modals.ScheduleReport)}
              onAddReport={handleAddScheduledReport}
              onRemoveReport={handleRemoveScheduledReport}
            />
            <SetAlertModal
              alerts={alerts}
              open={isOpen(Modals.SetAlert)}
              onClose={() => close(Modals.SetAlert)}
              onAdd={handleAddAlert}
              onRemove={handleRemoveAlert}
            />
          </motion.div>
        </AnimatePresence>
      </AnimateSharedLayout>
    </Layout>
  );
};

const ReportPage = () => {
  const { id: reportId } = useParams<{ id: string }>();

  if (reportId === DUMMY_IFRAME_REPORT_ID) {
    return <ReportIframe />;
  }

  return <Report />;
};

export default ReportPage;
