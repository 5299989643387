import RGL from 'react-grid-layout';

import { HasId } from './common';
import { Notification } from './notification';
import {
  AdditionalFilterSelections,
  FilterTypeOptions,
  SegmentationFilters,
  DetailedSegmentationFilters,
} from './reportFilters';
import { User } from './user';
import { Widget, WidgetData, WidgetId } from './widget';

export enum ReportCategory {
  Accounts = 'Accounts',
  Transactions = 'Transactions',
  Management = 'Management',
  EnterpriseChargebacks = 'EnterpriseChargebacks',
  Operational = 'Operational',
  SharedBranching = 'SharedBranching',
  SmartGrowth = 'SmartGrowth',
}

export enum ReportRole {
  Owner = 'Owner',
  Editor = 'Editor',
  Viewer = 'Viewer',
}

export interface Collaborator extends HasId {
  user: User;
  reportRole: ReportRole;
}

export type CollaboratorId = Collaborator['id'];

export interface ReportWidget {
  reportId: string;
  widget: Widget;
  layout: RGL.Layout;
  data?: WidgetData;
}

export interface Report extends HasId {
  name: string;
  widgets: Record<WidgetId, ReportWidget>;
  category: ReportCategory;
  lastUpdated: number;
  filters: FilterTypeOptions;
  additionalFilters?: AdditionalFilterSelections;
  segmentationFilters?: SegmentationFilters;
  detailedSegmentationFilters?: DetailedSegmentationFilters;
  startDate?: Date;
  endDate?: Date;
  collaborators: Record<CollaboratorId, Collaborator>;
  notifications?: Notification[];
}

export enum RepeatOptions {
  DoesNotRepeat = 'DoesNotRepeat',
  Daily = 'Daily',
  WeeklyOnMonday = 'WeeklyOnMonday',
  MonthlyOnTheFirstMonday = 'MonthlyOnTheFirstMonday',
  AnnuallyOnArbitraryDate = 'AnnuallyOnArbitraryDate',
  EveryWeekday = 'EveryWeekday',
  Custom = 'Custom',
}

export enum RepeatEveryPeriodOptions {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export enum RepeatEveryWeekOnOptions {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum RepeatEveryMonthOnOptions {
  MonthlyOnArbitraryDay = 'MonthlyOnArbitraryDay',
  MonthlyOnTheFirstMonday = 'MonthlyOnTheFirstMonday',
}

export interface ScheduledReport extends HasId {
  title: string;
  emailTo: User[];
  startDate: Date | null;
  startTime: Date | null;
  repeat: RepeatOptions;
  repeatEveryNumber?: number;
  repeatEveryPeriod?: RepeatEveryPeriodOptions;
  repeatEveryWeekOn?: RepeatEveryWeekOnOptions;
  repeatEveryMonthOn?: RepeatEveryMonthOnOptions;
}

export interface ReportAlert extends HasId {
  title: string;
  emailTo: User[];
  // TODO: fill in proper field values for condition
  conditionField: string;
  conditionOperator: string;
  conditionAmount: number;
}

export type ReportGroupId = ReportGroup['id'];

export type ReportId = Report['id'];

export interface ReportGroup extends HasId {
  name: string;
}

export interface GroupReport {
  group: ReportGroup;
  report: Report;
}

export type ReportsByGroup = Record<ReportGroupId, GroupReport[]>;
