import styled from '@emotion/styled';
import TableCell from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: ${({ theme }) => theme.textColor.text1};
    font-size: ${({ theme }) => theme.fontSize.h5};
    border-bottom: none;
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: ${({ theme }) => theme.textColor.text3};
    font-weight: bold;
  }

  &.MuiTableCell-body {
    border-left: ${({ theme }) => theme.borders.table};
    padding: 15px 5px 15px 20px;
    &:first-of-type {
      border-left: none;
    }
  }

  &.MuiTableCell-head {
    background: ${({ theme }) => theme.backgroundColor.background1};
    color: ${({ theme }) => theme.textColor.text3};
    border-bottom: ${({ theme }) => theme.borders.table};
    border-top: ${({ theme }) => theme.borders.table};
    font-size: ${({ theme }) => theme.fontSize.tableHeader};
    border-left: none;
    padding: 10px 5px 10px 20px;
  }
`;

// may change text olor on tablesort label depending on ryan fedeback

export default StyledTableCell;
