import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';

const StyledMenuItem = styled(MenuItem)`
  &:not(.Mui-disabled) {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.backgroundColor.rowHover};
      color: ${({ theme }) => theme.textColor.rowHover};
    }
  }
` as typeof MenuItem;

export default StyledMenuItem;
