import { useState } from 'react';

import styled from '@emotion/styled';

import { Icon_AddWidget as AddWidgetIcon } from '../../../assets/images';
import {
  CalloutWidget,
  CompareToType,
  Widget,
  WidgetTheme,
  WidgetType,
} from '../../../types';
import { stringToEnum } from '../../../util/enums';
import { makeId } from '../../../util/ids';
import { widgetTypeSizeOptions } from '../../../util/widget';
import { EditWidgetModal as NewWidgetModal } from '../../organisms';
import { EditWidgetFormValues } from '../../organisms/EditWidgetModal/editWidgetTypes';
import AddFirstWidget from './AddFirstWidget';

const WT = WidgetType;

// TODO: needs more "woooo"
const ContainerBox = styled.button`
  width: 100%;
  padding: 24px;
  border: 2px dashed ${({ theme }) => theme.backgroundColor.button.default};
  border-radius: 4px;
  cursor: pointer;
  background-color: inherit;
  color: ${({ theme }) => theme.backgroundColor.button.default};
`;

function tryParseEnum<Eenoom>(str?: string, eenoom?: Eenoom) {
  if (!eenoom) {
    return;
  }
  return str ? stringToEnum(str, eenoom) : undefined;
}

const makeLineChartWidget = (data: EditWidgetFormValues) => {
  const {
    name,
    value,
    valueMetric,
    secondaryValue,
    secondaryValueMetric,
    compareTo,
    brokenDownBy,
    size,
    theme,
  } = data;

  return {
    id: makeId({ entity: 'Widget' }),
    name,
    value: value || '',
    valueMetric: valueMetric || undefined,
    secondaryValue,
    secondaryValueMetric: secondaryValueMetric || undefined,
    compareTo: tryParseEnum(compareTo, CompareToType),
    brokenDownBy,
    type: WT.LineChart,
    size: size || widgetTypeSizeOptions[WT.LineChart][0],
    theme: theme || WidgetTheme.Theme1,
  };
};

const makeBarChartWidget = (data: EditWidgetFormValues) => {
  const {
    name,
    value,
    valueMetric,
    secondaryValue,
    compareTo,
    brokenDownBy,
    size,
    theme,
  } = data;
  return {
    id: makeId({ entity: 'Widget' }),
    name,
    value: value || '',
    valueMetric: valueMetric || undefined,
    secondaryValue,
    compareTo: tryParseEnum(compareTo, CompareToType),
    brokenDownBy,
    type: WT.BarChart,
    size: size || widgetTypeSizeOptions[WT.BarChart][0],
    theme: theme || WidgetTheme.Theme1,
  };
};

const makeCalloutWidget = (data: EditWidgetFormValues): CalloutWidget => {
  const { name, value, valueMetric, compareTo, size, theme } = data;

  return {
    id: makeId({ entity: 'Widget' }),
    name,
    value: value || '',
    valueMetric: valueMetric || undefined,
    compareTo: tryParseEnum(compareTo, CompareToType),
    type: WT.Callout,
    size: size || widgetTypeSizeOptions[WT.Callout][0],
    theme: theme || WidgetTheme.Theme1,
  };
};

// TODO: add table once the edit widget portion is done for this
/* eslint-disable-next-line */
const makeTableWidget = (data: EditWidgetFormValues) => {};

const makeWidget = (data: EditWidgetFormValues) => {
  switch (data.type) {
    case WT.LineChart:
      return makeLineChartWidget(data);
    case WT.BarChart:
      return makeBarChartWidget(data);
    case WT.Callout:
      return makeCalloutWidget(data);
    case WT.Table:
      return makeTableWidget(data);
    default:
      return undefined;
  }
};

interface AddWidgetSectionProps {
  onSubmit: (widget: Widget) => void;
  firstWidget: boolean;
}

const AddWidgetSection: React.FC<AddWidgetSectionProps> = ({
  onSubmit,
  firstWidget,
}) => {
  const [addingNewWidget, setAddingNewWidget] = useState(false);

  const onClicked = () => {
    setAddingNewWidget(true);
  };

  const handleNewWidgetModalClosed = () => {
    setAddingNewWidget(false);
  };

  const handleCreateWidget = (data: EditWidgetFormValues) => {
    const widget = makeWidget(data);
    if (widget) {
      onSubmit(widget);
    }
  };

  return (
    <>
      {firstWidget ? (
        <AddFirstWidget onAddNewWidgetClick={onClicked} />
      ) : (
        <ContainerBox onClick={onClicked} type="button">
          <AddWidgetIcon />
          <h1>Add a Widget</h1>
        </ContainerBox>
      )}

      <NewWidgetModal
        newWidget
        open={addingNewWidget}
        onSubmit={handleCreateWidget}
        onClose={handleNewWidgetModalClosed}
        aria-labelledby="Add widget modal"
        aria-describedby="Modal for adding a new widget"
      />
    </>
  );
};

export default AddWidgetSection;
