import React, { useState } from 'react';

import { Flex } from 'rebass';

import styled from '@emotion/styled';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { DateTypeOptions } from '../../types';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
}

interface DropdownItem {
  label: string;
  value: string;
}

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root .MuiTypography-body1 {
    color: ${({ theme }) => theme.textColor.text1};
    font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  }
`;

const Container = styled.label`
  position: relative;
`;

const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.textColor.text1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  font-weight: bold;
  padding-left: 7px;
  padding-top: 2px;
  cursor: pointer;

  &.Mui-focused {
    color: ${({ theme }) => theme.textColor.text1};
  }
`;

const RadioFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.textColor.text1};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  font-weight: bold;
  padding-top: 2px;
  cursor: pointer;

  &.Mui-focused {
    color: ${({ theme }) => theme.textColor.text1};
  }
`;

const FilterTitleContainer = styled(Flex)`
  align-items: center;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
  cursor: pointer;
  user-select: none;
`;

const RadioListContainer = styled.div`
  padding-left: 22px;
`;
// Accessible custom radio button styling
// https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
const StyledInput = styled.input`
  position: absolute;
  opacity: 0.00001;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;

  & + .visible-label {
    border-radius: ${({ theme }) => theme.borders.default.radius};
    background: ${({ theme }) => theme.backgroundColor.radioButton.unchecked};
    padding: 6px 9px;
  }

  &:checked + .visible-label {
    background: ${({ theme }) => theme.backgroundColor.radioButton.checked};
    color: ${({ theme }) => theme.textColor.onDark};
  }

  &:focus + .visible-label {
    box-shadow: 0 0 0 2pt
      ${({ theme }) => theme.backgroundColor.radioButton.focus};
    color: ${({ theme }) => theme.textColor.onDark};
  }

  &:disabled + .visible-label {
    opacity: 0.25;
  }

  &:focus:not(:focus-visible) + .visible-label {
    box-shadow: none;
  }

  .MuiRadio-root.Mui-disabled {
    color: ${({ theme }) => theme.textColor.text1};
    cursor: not-allowed;
  }
`;

interface RadioSelectProps {
  options: { label: string; value: string }[];
  label: string;
  value: string | DateTypeOptions;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const RadioButton: React.FC<Props> = ({ label, id, ...rest }) => {
  return (
    <Container htmlFor={id}>
      <StyledInput {...rest} type="radio" id={id} />

      <div className="visible-label">{label}</div>
    </Container>
  );
};
export const RadioSelect: React.FC<RadioSelectProps> = ({
  options,
  label,
  value,
  disabled,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <FormControl>
      <RadioFormLabel>{label}</RadioFormLabel>
      <RadioGroup value={value} onChange={handleChange} row>
        {options.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            control={<StyledRadio />}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

interface RadioSelectListProps {
  options: Record<string, DropdownItem>;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

export const RadioSelectList: React.FC<RadioSelectListProps> = ({
  options,
  label,
  value,
  onChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <FormControl>
      <FilterTitleContainer onClick={toggleExpansion}>
        {isExpanded ? (
          <RemoveCircle color="primary" fontSize="inherit" />
        ) : (
          <AddCircle color="primary" fontSize="inherit" />
        )}

        <StyledFormLabel>{label}</StyledFormLabel>
      </FilterTitleContainer>
      {isExpanded && (
        <RadioListContainer>
          <RadioGroup value={value} onChange={handleChange} row>
            {Object.values(options).map((option) => (
              <StyledFormControlLabel
                key={option.value}
                value={option.value}
                control={<StyledRadio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </RadioListContainer>
      )}
    </FormControl>
  );
};
