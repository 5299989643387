import styled from '@emotion/styled';
import { Input, InputProps } from '@mui/material';

import { styleUtils } from '../../styles/settings';

const StyledInput = styled(Input)`
  ${styleUtils.pillContainerStyle}
  .MuiInputBase-input {
    color: ${({ theme }) => theme.textColor.text1};
  }
`;

const CustomInput: React.FC<InputProps> = (props) => (
  <StyledInput disableUnderline {...props} />
);

export default CustomInput;
