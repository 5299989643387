import styled from '@emotion/styled';

import { UsersAutocomplete } from '../../organisms';

const EmailToAutocomplete = styled(UsersAutocomplete)`
  .MuiChip-root {
    background-color: ${({ theme }) => theme.backgroundColor.element1};
    font-weight: bold;
    border-radius: 5px;
  }
`;

export default EmailToAutocomplete;
