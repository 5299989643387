import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReportGroupId, ReportsByGroup } from '../../types';
import { call } from '../../util/mockApi';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';
import { dummyGroupReportsData } from './dummyReportsData';

export interface ReportsSliceState {
  loading?: boolean;
  reports: ReportsByGroup;
}

const initialState: ReportsSliceState = {
  reports: {},
};

export const getReports = createAsyncThunk(
  'reports/getReports',
  async ({ group }: { group: ReportGroupId }) => {
    // TODO: will probably have to revisit this and/or
    // make the dummy data default state data if/when users
    // are allowed to modify their report groups and add reports

    const reports = dummyGroupReportsData[group] || {};
    const data = { group, reports };
    const response = await call({ data });

    return response.data;
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReports.fulfilled, (state, action) => {
        const { payload } = action;

        state.loading = undefined;

        state.reports[payload.group] = payload.reports;
      })
      .addCase(getReports.rejected, (_state, action) => {
        console.error(action);
      });
  },
});

// Selectors + hooks using them
const _reports = (state: RootState) => state.reports.reports;
const _loading = (state: RootState) => state.reports.loading;

export const useReports = () => {
  return useAppSelector(_reports);
};

export const useLoading = () => {
  return useAppSelector(_loading);
};

export default reportsSlice.reducer;
