import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import { useState } from 'react';
import { Box, Flex } from 'rebass';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';

import { dummyUsers } from '../../../data/users/dummyUserData';
import { Collaborator, ReportRole, User } from '../../../types';
import { uniqueLeft } from '../../../util/collections';
import { stringToEnum } from '../../../util/enums';
import { makeId } from '../../../util/ids';
import {
  Button,
  Checkbox,
  Dropdown,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  UserAvatar,
} from '../../atoms';
import { UsersAutocomplete, CardModal } from '../../organisms';
import { CardModalProps } from '../../organisms/CardModal';

type ButtonClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface ShareModalProps extends Omit<CardModalProps, 'children'> {
  collaborators: Collaborator[];
}

const ColoredBox = styled(Box)`
  background-color: ${({ theme }) => theme.backgroundColor.background2};
  border-radius: ${({ theme }) => theme.borders.default.radius};
`;

const ShareContainer = styled.div`
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
  max-height: 275px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.borders.default.radius};
    background-color: ${({ theme }) => theme.backgroundColor.element2};
  }
`;

const NotifyText = styled(TextField)`
  width: 100%;

  .MuiFilledInput-multiline {
    background-color: ${({ theme }) => theme.backgroundColor.background2};
  }
`;

const AutoCompleteContainerInitial = styled(Box)`
  .MuiAutocomplete-root {
    background-color: ${({ theme }) => theme.backgroundColor.background2};
    padding: 10px 15px;
    border-radius: ${({ theme }) => theme.borders.default.radius};
  }
`;

const AutoCompleteContainer = styled(Box)`
  .MuiChip-root {
    background-color: ${({ theme }) => theme.backgroundColor.element1};
    font-weight: bold;
    border-radius: 5px;
  }

  .MuiAutocomplete-root {
    background-color: ${({ theme }) => theme.backgroundColor.background2};
    padding: 5px 10px;
    border-radius: ${({ theme }) => theme.borders.default.radius};
  }
`;

const roles = [
  { label: ReportRole.Editor, value: ReportRole.Editor },
  { label: ReportRole.Viewer, value: ReportRole.Viewer },
];

const allUsers = R.values(dummyUsers);

const makeCollaborator = R.curry(
  (reportRole: ReportRole, user: User): Collaborator => {
    return {
      id: makeId({ entity: 'Collaborator' }),
      user,
      reportRole,
    };
  }
);

interface AddPeopleOrGroupsProps {
  chosenUsers: User[];
  collaborators: Collaborator[];
  onReset: () => void;
  onUsersChanged: (users: User[]) => void;
  onSubmit: (collaborators: Collaborator[]) => void;
}

const AddPeopleOrGroups: React.FC<AddPeopleOrGroupsProps> = ({
  chosenUsers,
  collaborators,
  onReset,
  onSubmit,
  onUsersChanged,
}) => {
  const [chosenUsersRole, setChosenUsersRole] = useState<ReportRole>(
    ReportRole.Editor
  );

  const handleChosenUsersRoleChanged = (event: SelectChangeEvent<unknown>) => {
    const role: ReportRole = stringToEnum(
      event.target.value as string,
      ReportRole
    );

    setChosenUsersRole(role);
  };

  const handleSubmit = () => {
    const makeCollaboratorWithChosenRole = makeCollaborator(chosenUsersRole);
    const newlySharedCollaborators = R.map(
      makeCollaboratorWithChosenRole,
      chosenUsers
    );
    const newCollaborators = collaborators.concat(newlySharedCollaborators);

    onSubmit(newCollaborators);
    onReset();
  };

  const nonCollaboratorUsers = uniqueLeft(
    allUsers,
    R.map(R.prop('user'), collaborators)
  );

  return (
    <Formik
      initialValues={{ notifyPeople: false }}
      onSubmit={(_values, { resetForm }) => {
        handleSubmit();
        resetForm();
      }}
    >
      {({ values }) => (
        <Form>
          <Flex flexDirection="row">
            <AutoCompleteContainer width={1} pr={3}>
              <UsersAutocomplete
                chosenUsers={chosenUsers}
                users={nonCollaboratorUsers}
                onChange={(_event, newUsers) => onUsersChanged(newUsers)}
              />
            </AutoCompleteContainer>
            <Box width={1 / 6}>
              <Dropdown
                value={chosenUsersRole}
                onChange={handleChosenUsersRoleChanged}
              >
                {roles.map((role) => (
                  <MenuItem value={role.value} key={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </Box>
          </Flex>
          <Box width={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Field
                    name="notifyPeople"
                    value="shouldNotifyInvitedCollaborators"
                    as={Checkbox}
                    color="primary"
                  />
                }
                label="Notify people"
              />
            </FormGroup>
          </Box>
          {values.notifyPeople && (
            <Box width={1} mt={2}>
              <NotifyText variant="filled" placeholder="Message" multiline />
            </Box>
          )}
          <Flex flexDirection="row" justifyContent="flex-end" mt={4}>
            <Button variant="text" onClick={onReset}>
              Cancel
            </Button>
            <Button type="submit">
              {values.notifyPeople ? 'Send' : 'Share'}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

interface MainActionsProps {
  collaborators: Collaborator[];
  users: User[];
  chosenUsers: User[];
  onUsersChanged: (users: User[]) => void;
  onClose?: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void;
}

const MainActions: React.FC<MainActionsProps> = ({
  collaborators,
  users,
  chosenUsers,
  onClose,
  onUsersChanged,
}) => {
  const { avatarColor } = useTheme();
  const palette = R.values(avatarColor);

  /* eslint-disable-next-line */
  const handleDoneClicked = (_event: ButtonClickEvent) => {
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <Flex flexDirection="column">
      <AutoCompleteContainerInitial width={1} pb={2}>
        <UsersAutocomplete
          chosenUsers={chosenUsers}
          users={users}
          onChange={(_event, newUsers) => onUsersChanged(newUsers)}
        />
      </AutoCompleteContainerInitial>
      <ShareContainer>
        {collaborators.map(({ id, user }, i) => (
          <ColoredBox key={id} width={1} p={1} my={2}>
            <Flex flexDirection="row">
              <Flex m={2} alignItems="center">
                <UserAvatar
                  circleProps={{
                    width: '50px',
                    height: '50px',
                    fontSize: '25px',
                  }}
                  user={user}
                  indexInSeries={i}
                  palette={palette}
                />
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="space-between"
                ml={1}
                my="10px"
              >
                <div>
                  <b>{user.name}</b>
                </div>
                <div>{user.email}</div>
              </Flex>
            </Flex>
          </ColoredBox>
        ))}
      </ShareContainer>
      <Flex justifyContent="flex-end" mt={4}>
        <Button onClick={handleDoneClicked}>Done</Button>
      </Flex>
    </Flex>
  );
};

const ShareModal: React.FC<ShareModalProps> = ({
  collaborators: givenCollaborators,
  onClose,
  ...props
}) => {
  const [collaborators, setCollaborators] = useState<Collaborator[]>(
    givenCollaborators
  );
  const [chosenUsers, setChosenUsers] = useState<User[]>([]);

  const currentlyAddingUsers = !R.isEmpty(chosenUsers);

  const resetModal = () => {
    setChosenUsers([]);
  };

  const handleUsersChanged = (value: User[]) => {
    setChosenUsers(value);
  };

  const handleClose: ShareModalProps['onClose'] = (
    event,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    resetModal();
    if (onClose) {
      onClose(event, reason);
    }
  };

  const handleAddPeopleOrGroupsSubmit = (newCollaborators: Collaborator[]) => {
    setCollaborators(newCollaborators);
    resetModal();
  };

  const nonCollaboratorUsers = uniqueLeft(
    allUsers,
    R.map(R.prop('user'), collaborators)
  );

  return (
    <CardModal onClose={handleClose} title="Share" {...props}>
      {currentlyAddingUsers ? (
        <AddPeopleOrGroups
          collaborators={collaborators}
          chosenUsers={chosenUsers}
          onUsersChanged={handleUsersChanged}
          onReset={resetModal}
          onSubmit={handleAddPeopleOrGroupsSubmit}
        />
      ) : (
        <MainActions
          collaborators={collaborators}
          users={nonCollaboratorUsers}
          chosenUsers={chosenUsers}
          onUsersChanged={handleUsersChanged}
          onClose={handleClose}
        />
      )}
    </CardModal>
  );
};

export default ShareModal;
