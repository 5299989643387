import Lottie from 'lottie-react';
import { useState } from 'react';
import { Box, Flex } from 'rebass';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import {
  AccountManagerImg,
  Icon_Build,
  Icon_Focus,
  Icon_Share,
  IconMail,
  IconPhone,
  IconQuestion,
  InsightCenterB,
  LogoCoop,
  WelcomeGraphic,
  COOPNew,
} from '../../../assets/images';
import {
  Button,
  Card,
  ColoredCircle,
  H1,
  H3,
  H4,
  Paragraph,
} from '../../atoms';
import { Layout } from '../../templates';
import GuestModal from './GuestModal';
import { useUserSettings } from '../../../hooks/useUserSettings';

const FeatureContainer = styled(Flex)`
  justify-content: space-between;
`;

const FeatureCard = styled(Flex)`
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  border: 2px solid ${({ theme }) => theme.backgroundColor.lightest};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  box-shadow: ${({ theme }) => theme.shadows.contentShadow};
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 40px;
  width: 100%;
  text-align: center;
  &:nth-of-type(2) {
    margin-right: 40px;
    margin-left: 40px;
  }
`;

const FeatureHeader = styled.h4`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize60};
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.textColor.text4};
  padding: 16px 0px;
`;

const ImageContainer = styled(ColoredCircle)`
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const BlueCircle = styled(ColoredCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const CircleContainer = styled.div`
  position: relative;
  margin-top: -80px;
  width: 90px;
  margin-left: 40px;
`;

const BorderHeader = styled(Box)`
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  align-items: center;
  border-left: 6px solid ${({ theme }) => theme.backgroundColor.button.default};
`;

const QuestionsCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundColor.background2};
  padding: 40px;
  position: relative;
  overflow: visible;
  box-shadow: none;
`;

const QuestionsContainer = styled(Box)`
  width: 420px;
`;

const IconHeaderContainer = styled(Flex)`
  align-items: center;
  padding-bottom: 24px;
`;

const AccountManagerCard = styled(Box)`
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  border: 2px solid ${({ theme }) => theme.backgroundColor.lightest};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  box-shadow: ${({ theme }) => theme.shadows.contentShadow};
  z-index: 0;
  width: 564px;
  position: absolute;
  bottom: -70px;
  right: -40px;
`;

const AccountManagerContent = styled(Flex)`
  align-items: center;
`;

const AccountManagerText = styled(Box)`
  padding-left: 20px;
  padding-top: 40px;
  padding-bottom: 45px;
  z-index: 5;
`;

const NameAndTitle = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
`;

const ContactInfo = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize50};
  color: ${({ theme }) => theme.textColor.action};
`;

const JobText = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize60};
  color: ${({ theme }) => theme.colors.greyDark4};
`;

const GreyRectangle = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  z-index: 3;
  position: absolute;
  right: 0;
  width: 85%;
  height: 100%;
`;

const RedRectangle = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.redMedium};
  z-index: 3;
  position: absolute;
  right: 20px;
  top: 0;
  width: 60px;
  height: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15px;
`;

const WelcomeGraphicContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  height: 100%;
`;

const Welcome: React.FC = () => {
  const theme = useTheme();
  const { isNew } = useUserSettings();
  const [guestModalOpen, setGuestModalOpen] = useState(false);

  const handleGetStartedClick = () => {
    setGuestModalOpen(true);
  };

  const handleGuestModalClose = () => {
    setGuestModalOpen(false);
  };

  return (
    <Layout noSidebar mainContentBackground={theme.backgroundColor.background1}>
      <GuestModal open={guestModalOpen} onClose={handleGuestModalClose} />
      <Box
        sx={{
          display: 'grid',
          gridGap: 5,
          gridTemplateColumns: ['1fr', '1fr', '1fr', '6fr 5fr'],
        }}
      >
        <div>
          <img
            src={isNew ? COOPNew : InsightCenterB}
            alt="CO-OP Insights Center"
          />
          <Box mb={[4, 5]} />
          <H1>Analyze.</H1>
          <H1>Strategize.</H1>
          <H1>Thrive.</H1>
          <Box py={[3]} />
          <Paragraph>
            The CO-OP Insights Center provides new ways to look at your
            financial data. Segment or filter your data and gather insights to
            improve your numbers to new heights.
          </Paragraph>
          <Box py={3} />
          <Button onClick={handleGetStartedClick} type="button">
            Let&apos;s get started
          </Button>
        </div>
        <WelcomeGraphicContainer>
          <WelcomeGraphic />
        </WelcomeGraphicContainer>
      </Box>
      <Box py={5} />
      <BorderHeader mb={4}>
        <H4>Explore the Features</H4>
      </BorderHeader>
      <FeatureContainer>
        <FeatureCard>
          <Lottie animationData={Icon_Build} />
          <FeatureHeader>BUILD</FeatureHeader>
          <Paragraph>
            Create new reports from templates tailored to your role, or build a
            completely custom report.
          </Paragraph>
        </FeatureCard>
        <FeatureCard>
          <Lottie animationData={Icon_Focus} />
          <FeatureHeader>FOCUS</FeatureHeader>
          <Paragraph>
            Access, filter, segment, and customize all of your reports. Glean
            valuable insights from your data, at a glance.
          </Paragraph>
        </FeatureCard>
        <FeatureCard>
          <Lottie animationData={Icon_Share} />
          <FeatureHeader>SHARE</FeatureHeader>
          <Paragraph>Export and share report snapshots and raw data.</Paragraph>
        </FeatureCard>
      </FeatureContainer>
      <Box py={5} />
      <QuestionsCard>
        <QuestionsContainer>
          <IconHeaderContainer>
            <IconQuestion />
            <Box px={1} />
            <H4>Questions</H4>
          </IconHeaderContainer>
          <Paragraph>
            If you have concerns about your account, roles or general questions
            about Insights Center, please contact your assigned CO-OP Account
            Manager.
          </Paragraph>
        </QuestionsContainer>
        <AccountManagerCard>
          <AccountManagerContent>
            <GreyRectangle />
            <CircleContainer>
              <BlueCircle width="90px" height="90px" color="#2979FF" />
              <ImageContainer width="80px" height="80px" color="#FFFFFF">
                <img src={AccountManagerImg} alt="account manager" />
              </ImageContainer>
            </CircleContainer>
            <AccountManagerText>
              <NameAndTitle>
                <H3>Carrie Cooper</H3>
                <JobText>Account Manager</JobText>
              </NameAndTitle>
              <Flex alignItems="center" pb={2}>
                <IconMail />
                <Box px={2} />
                <ContactInfo>carrie.cooper@coop.org</ContactInfo>
              </Flex>
              <Flex alignItems="center">
                <IconPhone />
                <Box px={2} />
                <ContactInfo>555-123-4567 ext.0000</ContactInfo>
              </Flex>
            </AccountManagerText>
          </AccountManagerContent>
          <RedRectangle>
            <LogoCoop />
          </RedRectangle>
        </AccountManagerCard>
      </QuestionsCard>
      <Box py={5} />
    </Layout>
  );
};

export default Welcome;
