import styled from '@emotion/styled';

interface Props {
  active: boolean;
}

const VerticalTab = styled.button<Props>`
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left-width: 6px;
  border-left-color: ${({ active, theme }) =>
    active ? theme.backgroundColor.button.default : 'transparent'};
  color: ${({ active, theme }) =>
    active ? theme.backgroundColor.button.default : theme.textColor.default};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  padding: 1rem 2rem 1rem 1rem;
  width: 100%;
  text-align: left;
  background: ${({ active, theme }) => (active ? theme.colors.tealA : 'none')};
  &:hover {
    background: ${({ theme }) => theme.colors.tealB};
    border-left-color: ${({ theme }) => theme.backgroundColor.button.default};
  }
  &:focus {
    border-left-color: ${({ theme }) => theme.backgroundColor.button.default};
    font-weight: bold;
  }
`;

export default VerticalTab;
