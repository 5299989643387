// NOTE: Adapted from https://material-ui.com/components/tables/#sorting-amp-selecting
import React, { useState } from 'react';
import styled from '@emotion/styled';

import { HasId } from '../../types/common';
import { getComparator, Order, stableSort } from '../../util/tableSorting';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '../atoms';

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  notSortable?: boolean;
}

interface EnhancedTableProps {
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

interface IndexTableProps<Data extends HasId> {
  headCells: HeadCell[];
  rows: Data[];
  renderRow: (row: Data, key: string) => React.ReactNode;
}

const StyledTable = styled(Table)`
  min-width: 750px;
`;

const SortLabelContainer = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.notSortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <SortLabelContainer>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </SortLabelContainer>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function IndexTable<
  Data extends { [x: string]: string | number | boolean; id: string }
>({ headCells, rows, renderRow }: IndexTableProps<Data>) {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<string>('lastUpdated');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div>
      <StyledTable
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
        stickyHeader
      >
        <EnhancedTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort<Data>(rows, getComparator(order, orderBy)).map((row) => {
            return renderRow(row, row.id);
          })}
        </TableBody>
      </StyledTable>
    </div>
  );
}

export default IndexTable;
