import demo1 from './demo1';

export const loadScript = () => {
  const scriptName = process.env.REACT_APP_SCRIPT_NAME || 'NONE';

  const script = {
    DEMO1: demo1,
  }[scriptName];

  // TODO: remove maybe?
  if (script) {
    console.log(`### Loaded script ${scriptName}`);
  }

  return script;
};
