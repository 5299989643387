import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';

import { paths } from '../../../routing/paths';
import { reportingFormValidation } from '../welcome/reportingFormValidation';
import {
  Card,
  Button,
  Checkbox,
  ErrorTextWithContainer,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paragraph,
  Dropdown,
} from '../../atoms';
import { SunIcon, MoonIcon } from '../../../assets/images';
import { useUserSettings } from '../../../hooks/useUserSettings';

const ProfileCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  width: 1000px;
  box-shadow: none;
  border-left: 1px solid ${({ theme }) => theme.backgroundColor.element2};
  border-radius: 0;
`;

const Sun = styled(SunIcon)`
  height: 20px;
  width: 20px;
`;

const Moon = styled(MoonIcon)`
  height: 20px;
  width: 20px;
`;

const ProfilePreferences: React.FC = () => {
  const history = useHistory();

  const { isDark, toggleDarkMode } = useUserSettings();

  return (
    <Flex>
      <Box>
        <ProfileCard>
          <Box py={[4, 5]} px={[3, 5]}>
            <Box mb={3}>
              <Paragraph>What areas of reporting do you use?</Paragraph>
            </Box>

            <Box mb={[3]} />
            <Formik
              initialValues={{ reportingAreas: [] }}
              validationSchema={reportingFormValidation}
              onSubmit={
                /* eslint-disable @typescript-eslint/no-unused-vars */
                (values) => {
                  // Fill in real form submission once ready
                  history.push(paths.reports());
                  /* eslint-enable @typescript-eslint/no-unused-vars */
                }
              }
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Field
                          name="reportingAreas"
                          value="fraud"
                          as={Checkbox}
                          color="primary"
                        />
                      }
                      label="Fraud"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name="reportingAreas"
                          value="atm"
                          // TODO: should re-use ui component for checkbox
                          as={Checkbox}
                          color="primary"
                        />
                      }
                      label="ATM"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name="reportingAreas"
                          value="spending_trends"
                          as={Checkbox}
                          color="primary"
                          defaultChecked
                        />
                      }
                      label="Spending Trends"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name="reportingAreas"
                          value="campaigns"
                          as={Checkbox}
                          color="primary"
                        />
                      }
                      label="Campaigns"
                    />
                    <Box mt={5} mb={2}>
                      <Paragraph>Show welcome screen on login</Paragraph>
                    </Box>

                    <Box width="200px">
                      <Dropdown placeholder="Show">
                        <MenuItem>Hide</MenuItem>
                      </Dropdown>
                    </Box>
                    <Box mt={4}>
                      <Flex justifyContent="flex-start">
                        <FormControlLabel
                          // TODO: set margin-left to 0 with sx styling in MUI 5
                          control={
                            <Field
                              name="reportingAreas"
                              value="Day Mode"
                              as={Switch}
                              color="primary"
                              checked={isDark}
                              onChange={toggleDarkMode}
                              size="medium"
                              icon={<Sun />}
                              checkedIcon={<Moon />}
                            />
                          }
                          label="Day Mode"
                          labelPlacement="start"
                        />
                      </Flex>
                    </Box>
                    <Box mt={[2]} minHeight="15px">
                      <ErrorMessage name="reportingAreas">
                        {(message) => (
                          <ErrorTextWithContainer>
                            {message}
                          </ErrorTextWithContainer>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Box mt={4}>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Button type="submit" disabled={isSubmitting}>
                          Save
                        </Button>
                      </Flex>
                    </Box>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </Box>
        </ProfileCard>
      </Box>
    </Flex>
  );
};

export default ProfilePreferences;
