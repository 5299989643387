import { last } from 'ramda';

import { widgetMainValueOptions } from '../data/widget/widgetDisplayData';
import {
  ByFieldAll,
  ChartValueFormatOption,
  HasValueText,
  QueryField,
  QueryMetric,
  ValueTextFn,
  WidgetSize,
  WidgetType,
} from '../types';

const moneyLabelFn = (valueText: string) => `$${valueText}`;

const usesValueText = (fn: (valueText: string) => string) => ({
  valueText,
}: HasValueText) => fn(valueText);

const customizeFns: Record<string, ValueTextFn> = {
  Spend: usesValueText(moneyLabelFn),
  Interchange: usesValueText(moneyLabelFn),
};

export const customizeTextFn = (
  valueField: string
): ValueTextFn | undefined => {
  return customizeFns[valueField];
};

export const queryFieldToFormat: Record<QueryField, ChartValueFormatOption> = {
  spend: 'currency',
  interchange: 'decimal',
};

export const byFieldToFormat: Record<ByFieldAll, ChartValueFormatOption> = {
  time: 'shortDate',
};

interface LayoutSize {
  w: number;
  h: number;
}

interface LayoutSizeAndConstraints extends LayoutSize {
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
}

export const widgetTypeSizeOptions: Record<
  WidgetType,
  [WidgetSize, ...WidgetSize[]] // array of min length 1
> = {
  [WidgetType.BarChart]: [WidgetSize.Medium, WidgetSize.Large],
  [WidgetType.LineChart]: [WidgetSize.Medium, WidgetSize.Large],
  [WidgetType.Table]: [WidgetSize.Medium, WidgetSize.Large],
  [WidgetType.Callout]: [WidgetSize.Small],
};

export const widgetSizeToLayoutSize: Record<WidgetSize, LayoutSize> = {
  [WidgetSize.Small]: {
    w: 1,
    h: 1,
  },
  [WidgetSize.Medium]: {
    w: 2,
    h: 2,
  },
  [WidgetSize.Large]: {
    w: 3,
    h: 2,
  },
};

export const layoutSizeAndConstraintsProps = (
  widgetType: WidgetType,
  widgetSize?: WidgetSize
): LayoutSizeAndConstraints => {
  const sizeOptions = widgetTypeSizeOptions[widgetType];
  const minLayoutSize = widgetSizeToLayoutSize[sizeOptions[0]];
  const maxLayoutSize = widgetSizeToLayoutSize[last(sizeOptions) as WidgetSize];

  return {
    ...(widgetSize ? widgetSizeToLayoutSize[widgetSize] : minLayoutSize),
    minW: minLayoutSize.w,
    minH: minLayoutSize.h,
    maxW: maxLayoutSize.w,
    maxH: maxLayoutSize.h,
    isResizable: sizeOptions.length > 1,
  };
};

export const widgetSizeDisabled = (size: WidgetSize, type?: WidgetType) => {
  return !type ? true : !widgetTypeSizeOptions[type].includes(size);
};

// TODO: only for prototype
export const dummyWidgetDataOffset = (
  value: QueryField,
  valueMetric: QueryMetric
): number => {
  if (!value || !valueMetric) {
    return 0;
  }

  const valueOffset = Object.keys(widgetMainValueOptions).findIndex(
    (key) => key === value
  );

  const valueMetricOffset = Object.keys(
    widgetMainValueOptions[value].metrics
  ).findIndex((key) => key === valueMetric);

  return valueOffset + valueMetricOffset;
};
