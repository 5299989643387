import { add, getTime } from 'date-fns';

import { standardDateRangeFilters } from '../data/report/dateRangeFilterData';
import {
  CustomDateRangeOptions,
  DateTypeOptions,
  Report,
  ReportCategory,
  StandardDateRangeOptions,
} from '../types';

export const calculateInitialEndDate = (report: Report) => {
  switch (report.filters.DateRange) {
    case CustomDateRangeOptions.Custom:
      return report.endDate || new Date();
    default:
      return standardDateRangeFilters[report.filters.DateRange].endDateFn();
  }
};

export const calculateInitialStartDate = (report: Report) => {
  switch (report.filters.DateRange) {
    case CustomDateRangeOptions.Custom:
      return report.startDate || add(new Date(), { months: -6 });
    default:
      return standardDateRangeFilters[report.filters.DateRange].startDateFn();
  }
};

// TODO: just for the prototype
export const NEW_REPORT_ID = '456';
export const createNewReport: Report = {
  id: NEW_REPORT_ID,
  name: 'New report',
  filters: {
    DateType: DateTypeOptions.AuthorizationDate,
    DateRange: StandardDateRangeOptions.LastFullMonth,
    BIN: [],
  },
  widgets: {},
  lastUpdated: getTime(new Date()),
  collaborators: {},
  // TODO: this should be optional in the future?
  // since the user can create a report w/o a category
  category: ReportCategory.Accounts,
};
