import { HasId } from '../util/ids';

// ///////// Filter Type Options - TODO: should probably move to src/data/reportFilters/dummyRegularFiltersData
export enum FilterType {
  DateRange = 'DateRange',
  DateType = 'DateType',
  MCCGroup = 'MCCGroup',
  BIN = 'BIN',
  DigitalWallet = 'DigitalWallet',
  Institutions = 'Institutions',
  ActivityType = 'ActivityType',
  Network = 'Network',
  CardType = 'CardType',
}

export enum DateTypeOptions {
  AuthorizationDate = 'AuthorizationDate',
  SettlementDate = 'SettlementDate',
}

export enum StandardDateRangeOptions {
  MonthToDate = 'MonthToDate',
  LastFullMonth = 'LastFullMonth',
  Last3FullMonths = 'Last3FullMonths',
  YearToDate = 'YearToDate',
}

export enum CustomDateRangeOptions {
  Custom = 'Custom',
}

export type AllDateRangeOptions =
  | StandardDateRangeOptions
  | CustomDateRangeOptions;

export type FilterTypeOptions = {
  [FilterType.DateType]: DateTypeOptions;
  [FilterType.DateRange]: AllDateRangeOptions;
  [FilterType.BIN]: string[];
  [FilterType.MCCGroup]: string[];
  [FilterType.DigitalWallet]: string[];
  [FilterType.Institutions]: string[];
  [FilterType.ActivityType]: string;
  [FilterType.Network]: string[];
  [FilterType.CardType]: string[];
};

// ///// Filter types

export enum FilterTypes {
  SelectFilter = 'SelectFilter',
  DateRangeFilter = 'DateRangeFilter',
}

export interface SelectOption<ValueType = string> {
  label: string;
  value: ValueType;
  isParent?: boolean;
  parentValue?: ValueType;
}

export interface SelectFilter<ValueType = string> extends HasId {
  _type: FilterTypes.SelectFilter;
  name: string;
  label: string;
  defaultOption?: SelectOption<ValueType>;
  options: SelectOption<ValueType>[];
}

export interface DateRangeFilter extends HasId {
  _type: FilterTypes.DateRangeFilter;
  name: string;
  label: string;
  dateRangeOption: AllDateRangeOptions;
  startDate?: Date;
  endDate?: Date;
}

// ////// Filter selection types

export type SelectFilterSelection<ValueType = string> = SelectOption<ValueType>;

export interface DateRangeFilterSelection {
  dateRangeOption: AllDateRangeOptions;
  startDate: Date;
  endDate: Date;
}

export type FilterSelection =
  | SelectFilterSelection[]
  | SelectFilterSelection
  | DateRangeFilterSelection;

export interface AppliedFilter<F, S = FilterSelection> {
  filter: F;
  selection: S;
}

export type AdditionalFilterType = SelectOption;

export interface AdditionalFilterCategory extends SelectOption {
  types: Record<string, AdditionalFilterType>;
}

export type AdditionalFiltersData = Record<
  AdditionalFilterCategory['value'],
  AdditionalFilterCategory
>;

export interface AdditionalFilterSelection {
  categoryValue: AdditionalFilterCategory['value'];
  typeValue: AdditionalFilterType['value'];
}

export type AdditionalFilterSelections = Record<
  AdditionalFilterSelection['categoryValue'],
  AdditionalFilterSelection
>;

export type FilterId = string;
export type FilterSelections = Record<FilterId, FilterSelection>;
export type SegmentationFilters = FilterSelections;
export type DetailedSegmentationFilters = Record<
  FilterId,
  SelectFilterSelection
>;
