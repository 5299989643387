import { Flex } from 'rebass';

import styled from '@emotion/styled';

interface Props {
  variant?: 'primary' | 'secondary';
}

export const Tag = styled(Flex)<Props>`
  background-color: ${({ theme }) => theme.backgroundColor.element2};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  color: ${({ theme }) => theme.textColor.primaryButton};
  font-weight: bold;
  padding: ${({ theme }) => theme.padding.input.all};
`;

export const FilterTag = styled(Flex)`
  background-color: ${({ theme }) => theme.backgroundColor.element1};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  color: ${({ theme }) => theme.textColor.default};
  font-size: ${({ theme }) => theme.fontSize.avatar};
  padding: 3px 8px;
`;
