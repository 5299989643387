import React from 'react';
import { HiX } from 'react-icons/hi';
import { Flex } from 'rebass';
import styled from '@emotion/styled';

import {
  Box,
  Card,
  CardBody,
  CardDivider,
  IconButton,
  Modal,
  Paragraph,
} from '../atoms';
import { ModalProps } from '../atoms/Modal';

const Content = styled(Box)`
  width: 650px;
`;

export interface CardModalProps extends ModalProps {
  title?: string;
}

const CardModal: React.FC<ModalProps> = ({
  children,
  title,
  onClose,
  ...props
}) => {
  return (
    <Modal onClose={onClose} {...props}>
      <Content>
        <Card>
          <CardBody padding="mediumHeader">
            <Flex justifyContent="space-between" alignItems="center">
              {title && <Paragraph>{title}</Paragraph>}

              <IconButton
                icon={HiX}
                aria-controls="Close modal"
                onClick={(e) => onClose && onClose(e, 'backdropClick')}
                active={false}
                variant="secondary"
                size="small"
              />
            </Flex>
          </CardBody>

          <CardDivider />

          <CardBody padding="medium">{children}</CardBody>
        </Card>
      </Content>
    </Modal>
  );
};

export default CardModal;
