import { useState } from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { HiChevronRight, HiChevronLeft, HiPlay } from 'react-icons/hi';
import {
  SegmentationReports,
  CustomReport,
  AlertTips,
} from '../../../assets/images';
import { Paragraph } from '../../atoms';

const TutorialsSlider = styled(Slider)`
  width: 100%;
  .slick-dots li button:before {
    color: ${({ theme }) => theme.backgroundColor.element2};
  }
  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.backgroundColor.darkest};
  }
`;

const CardContainer = styled(Box)`
  padding: 0 15px 10px 15px;
`;

const TutorialText = styled(Paragraph)`
  padding: 20px 0;
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize60};
  color: inherit;
`;

const RightArrow = styled(HiChevronRight)`
  color: ${({ theme }) => theme.backgroundColor.darkest};
  :hover {
    color: ${({ theme }) => theme.textColor.link};
  }
`;

const LeftArrow = styled(HiChevronLeft)`
  color: ${({ theme }) => theme.backgroundColor.darkest};
  :hover {
    color: ${({ theme }) => theme.textColor.link};
  }
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: ${({ theme }) => theme.borders.default.radius};
  background-color: ${({ theme }) => theme.backgroundColor.background1};
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => theme.backgroundColor.button.default};
    color: ${({ theme }) => theme.textColor.link};
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;

const ThumbnailContainer = styled.div`
  position: relative;
`;

const StyledHiPlay = styled(HiPlay)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.colors.white};
`;

const settings = {
  autoplay: false,
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <RightArrow />,
  prevArrow: <LeftArrow />,
};

interface FeatureProps {
  image: string;
  text: string;
}

const FeatureCard: React.FC<FeatureProps> = ({ image, text }) => {
  const [inHover, setHover] = useState(false);
  return (
    <CardContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Content>
        <ThumbnailContainer>
          <Thumbnail src={image} />
          {inHover && <StyledHiPlay />}
        </ThumbnailContainer>
        <TutorialText>{text}</TutorialText>
      </Content>
    </CardContainer>
  );
};

const WelcomeBackSlider: React.FC = () => {
  return (
    <TutorialsSlider {...settings}>
      <FeatureCard image={CustomReport} text="How to Create a Custom Report" />
      <FeatureCard
        image={SegmentationReports}
        text="Using Segmentation in Your Reports"
      />
      <FeatureCard image={AlertTips} text="Tips for Setting Alerts" />
      <FeatureCard
        image={SegmentationReports}
        text="Using Segmentation in Your Reports"
      />
      <FeatureCard image={CustomReport} text="How to Create a Custom Report" />
      <FeatureCard image={AlertTips} text="Tips for Setting Alerts" />
    </TutorialsSlider>
  );
};

export default WelcomeBackSlider;
