import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import Layout from '../../templates/Layout';
import ProfileEdit from '../profile/ProfileEdit';
import ProfilePreferences from '../profile/ProfilePreferences';
import { VerticalTab } from '../../molecules';
import { H3 } from '../../atoms';

enum ProfileMenu {
  Profile = 'Profile',
  Preferences = 'Preferences',
  Notifications = 'Notifications',
}

const Profile: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<ProfileMenu>(
    ProfileMenu.Profile
  );
  return (
    <Layout>
      <H3>Profile</H3>
      <Box my={4} />
      <Flex>
        <Box flex={['0 1 230px']}>
          <VerticalTab
            active={selectedMenu === ProfileMenu.Profile}
            onClick={() => setSelectedMenu(ProfileMenu.Profile)}
          >
            Edit Profile
          </VerticalTab>
          <VerticalTab
            active={selectedMenu === ProfileMenu.Preferences}
            onClick={() => setSelectedMenu(ProfileMenu.Preferences)}
          >
            Preferences
          </VerticalTab>
          <VerticalTab
            active={selectedMenu === ProfileMenu.Notifications}
            onClick={() => setSelectedMenu(ProfileMenu.Notifications)}
          >
            Notifications
          </VerticalTab>
        </Box>

        <Flex flex="1">
          {selectedMenu === ProfileMenu.Profile ? (
            <ProfileEdit />
          ) : selectedMenu === ProfileMenu.Preferences ? (
            <ProfilePreferences />
          ) : (
            <Box />
          )}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Profile;
