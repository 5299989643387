import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';

import { Box, Flex } from 'rebass';
import styled from '@emotion/styled';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import { styleUtils } from '../../../styles/settings';
import { formats } from '../../../util/dates';
import { Button, ErrorTextWithContainer, TimePicker } from '../../atoms';
import { customRangePickerValidation } from './customRangePickerValidation';

// TODO: adjust padding with values from theme
const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    ${styleUtils.pillContainerStyle}
    background: ${({ theme }) => theme.backgroundColor.background2};
  }

  .MuiInputBase-input {
    padding: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const FormTitle = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.textColor.text3};
  font-weight: bold;
`;

const ButtonText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.textColor.onDark};
`;

const CancelText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme }) => theme.textColor.link};
`;

// TODO: reapply these styles to the datepicker
// OR this might get refactored out with the ui library
// const StyledCalendar = styled(HiCalendar)`
//   color: ${({ theme }) => theme.textColor.text3};
// `;

interface CustomDatePickerBaseProps<TDate> extends DatePickerProps<TDate> {
  format?: string;
}

export const CustomDatePickerBase = ({
  format,
  ...props
}: CustomDatePickerBaseProps<unknown>) => {
  return (
    <StyledDatePicker
      format={format || formats.input}
      // keyboardIcon={<StyledCalendar />}

      {...props}
    />
  );
};

interface Props {
  initialStartDate: Date;
  initialEndDate: Date;
  initialStartTime: Date | null;
  initialEndTime: Date | null;
  startPickerId: string;
  endPickerId: string;
  handleApply: (
    startDate: Date,
    endDate: Date,
    startTime: Date | null,
    endTime: Date | null
  ) => void;
  handleCancel: () => void;
}

const CustomDateRangePicker: React.FC<Props> = ({
  initialStartDate,
  initialEndDate,
  initialStartTime,
  initialEndTime,
  startPickerId,
  endPickerId,
  handleApply,
  handleCancel,
}) => {
  return (
    <Formik
      initialValues={{
        startDate: initialStartDate,
        endDate: initialEndDate,
        startTime: initialStartTime,
        endTime: initialEndTime,
      }}
      validationSchema={customRangePickerValidation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleApply(
          values.startDate,
          values.endDate,
          values.startTime,
          values.endTime
        );
      }}
    >
      {({ values, setFieldValue, handleBlur, isSubmitting }) => (
        <Form>
          <label htmlFor={startPickerId}>
            <FormTitle>Start</FormTitle>
          </label>
          <CustomDatePickerBase
            value={values.startDate}
            slotProps={{
              textField: {
                id: startPickerId,
                name: 'startDate',
                onBlur: handleBlur,
              },
            }}
            onChange={(date) => {
              setFieldValue('startDate', date);
            }}
          />

          <ErrorMessage name="startDate">
            {(message) => (
              <Box mt={[2]}>
                <ErrorTextWithContainer>{message}</ErrorTextWithContainer>
              </Box>
            )}
          </ErrorMessage>

          <Box mt={[1]} />

          <TimePicker
            value={values.startTime}
            onChange={(value: moment.Moment | null) => {
              setFieldValue('startTime', value && value.toDate());
            }}
          />

          <Box mt={[3]} />

          <label htmlFor={endPickerId}>
            <FormTitle>End</FormTitle>
          </label>

          <CustomDatePickerBase
            value={values.endDate}
            slotProps={{
              textField: {
                id: endPickerId,
                name: 'endDate',
                onBlur: handleBlur,
              },
            }}
            onChange={(date) => {
              setFieldValue('endDate', date);
            }}
          />

          <ErrorMessage name="endDate">
            {(message) => (
              <Box mt={[2]}>
                <ErrorTextWithContainer>{message}</ErrorTextWithContainer>
              </Box>
            )}
          </ErrorMessage>

          <Box mt={[1]} />

          <TimePicker
            value={values.endTime}
            onChange={(value: moment.Moment | null) => {
              setFieldValue('endTime', value && value.toDate());
            }}
          />

          <Box mt={[4]} />
          <Flex flexDirection="column">
            <Button disabled={isSubmitting} type="submit">
              <ButtonText>Apply</ButtonText>
            </Button>
            <Box mt={[2]} />

            <Button bold variant="text" type="button" onClick={handleCancel}>
              <CancelText>Cancel</CancelText>
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CustomDateRangePicker;
