import { add, startOfMonth, startOfYear } from 'date-fns';

import { DropdownItem } from '../../components/atoms';
import { StandardDateRangeOptions } from '../../types';

interface StandardDateRangeFilter extends DropdownItem {
  endDateFn: () => Date;
  startDateFn: () => Date;
}

export const standardDateRangeFilters: Record<
  StandardDateRangeOptions,
  StandardDateRangeFilter
> = {
  [StandardDateRangeOptions.MonthToDate]: {
    label: 'This Month to Date',
    value: StandardDateRangeOptions.MonthToDate,
    endDateFn: () => new Date(),
    startDateFn: () => startOfMonth(new Date()),
  },
  [StandardDateRangeOptions.LastFullMonth]: {
    label: 'Last Full Month',
    value: StandardDateRangeOptions.LastFullMonth,
    endDateFn: () => new Date(),
    startDateFn: () => add(new Date(), { months: -1 }),
  },
  [StandardDateRangeOptions.Last3FullMonths]: {
    label: 'Last 3 Full Months',
    value: StandardDateRangeOptions.Last3FullMonths,
    endDateFn: () => new Date(),
    startDateFn: () => add(new Date(), { months: -3 }),
  },
  [StandardDateRangeOptions.YearToDate]: {
    label: 'Year to Date',
    value: StandardDateRangeOptions.YearToDate,
    endDateFn: () => new Date(),
    startDateFn: () => startOfYear(new Date()),
  },
};
