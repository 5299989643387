import React from 'react';
import { F } from 'ts-toolbelt';

import { MenuItem } from '../atoms';

export enum MenuItems {
  CloneReport = 'CloneReport',
  Share = 'Share',
  Save = 'Save',
  ScheduleReport = 'ScheduleReport',
  SetAlert = 'SetAlert',
  Export = 'Export',
  AddToFavorites = 'AddToFavorites',
  Remove = 'Remove',
  Delete = 'Delete',
}
const MI = MenuItems;

interface ReportMenuItemsProps {
  closeMenu: () => void;
  onMenuItemClick: F.Curry<
    (
      closeMenu: () => void,
      menuItem: MenuItems,
      event: React.MouseEvent<HTMLLIElement, MouseEvent>
    ) => void
  >;
  isWithinReport?: boolean;
}

const ReportMenuItems: React.FC<ReportMenuItemsProps> = ({
  closeMenu,
  onMenuItemClick,
  isWithinReport,
}) => {
  const onClick = onMenuItemClick(closeMenu);

  return (
    <>
      <MenuItem onClick={onClick(MI.CloneReport)}>Clone Report</MenuItem>
      <MenuItem onClick={onClick(MI.Share)}>Share</MenuItem>
      <MenuItem onClick={onClick(MI.Save)}>Save as PDF</MenuItem>
      {isWithinReport && (
        <MenuItem onClick={onClick(MI.ScheduleReport)}>
          Schedule Report
        </MenuItem>
      )}
      {isWithinReport && (
        <MenuItem onClick={onClick(MI.SetAlert)}>Set Alert</MenuItem>
      )}
      <MenuItem onClick={onClick(MI.Export)}>Export / Download</MenuItem>
      <MenuItem onClick={onClick(MI.AddToFavorites)}>Add to Favorites</MenuItem>
      <MenuItem onClick={onClick(MI.Remove)}>Remove from My Reports</MenuItem>
      <MenuItem onClick={onClick(MI.Delete)}>Delete</MenuItem>
    </>
  );
};

export default ReportMenuItems;
