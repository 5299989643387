import React from 'react';

import styled from '@emotion/styled';
import {
  Button as OrigButton,
  ButtonProps as OrigButtonProps,
} from '@mui/material';

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledButton = styled(OrigButton, {
  shouldForwardProp: (prop) => !['small'].includes(prop.toString()),
})<StyledButtonProps>`
  &.MuiButton-root {
    font-size: ${({ small, theme }) =>
      small ? theme.fonts.sizes.fontSize30 : theme.fontSize.body};
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    padding: 7px 16px;
    text-transform: none;
  }
  &.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.backgroundColor.button.default};
    color: ${({ theme }) => theme.textColor.primaryButton};
    box-shadow: none;
  }
  &.MuiButton-textPrimary {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }

  &.MuiButton-outlined {
    font-size: ${({ small, theme }) =>
      small ? theme.fonts.sizes.fontSize30 : theme.fontSize.body};
    font-weight: ${({ bold }) => (bold ? '700' : '400')};
    text-transform: none;
    border-color: ${({ theme }) => theme.backgroundColor.button.default};
    color: ${({ theme }) => theme.backgroundColor.button.default};
    padding: 5px 16px;
    border-style: solid;
    border-width: 2px;
    box-shadow: none;
  }
  &.MuiButton-outlinedSecondary {
    background-color: transparent;
    color: ${({ theme }) => theme.backgroundColor.button.default};
    &:hover,
    &.Mui-focusVisible {
      background-color: ${({ theme, backgroundOnHover }) =>
        backgroundOnHover || theme.backgroundColor.button.default};
      color: ${({ theme }) => theme.textColor.primaryButton};
      border-color: ${({ theme }) => theme.backgroundColor.button.default};
      border-style: solid;
      border-width: 2px;
      box-shadow: none;
    }
  }
  &.MuiButton-textSecondary {
    color: ${({ theme }) => theme.backgroundColor.button.default};
  }
  &.MuiButton-textSizeSmall {
    font-size: ${({ theme }) => theme.fonts.sizes.fontSize20};
    padding: 6px 16px;
    &:hover {
      background-color: ${({ theme, backgroundOnHover }) =>
        backgroundOnHover || theme.backgroundColor.button.default};
    }
  }
`;

interface StyledButtonProps {
  bold?: boolean;
  backgroundOnHover?: string;
  small: boolean;
}

// Allowing 'component' prop to be used
// https://material-ui.com/guides/typescript/#usage-of-component-prop
export type ButtonProps<C extends React.ElementType = never> = OrigButtonProps<
  C,
  { component?: C }
> &
  StyledButtonProps;

export function Button<C extends React.ElementType>(props: ButtonProps<C>) {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      disableRipple
      disableElevation
      {...props}
    />
  );
}
