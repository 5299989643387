import { IconContext } from 'react-icons';

import { ThemeProvider } from '@emotion/react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  THEME_ID,
  StyledEngineProvider,
} from '@mui/material/styles';

import { Routes } from './routing/Routes';
import GlobalStyles from './styles/Global';
import { ThemeDark, ThemeLight, Theme } from './styles/settings';
import { UserSettingsProvider } from './hooks/useUserSettings';

function App() {
  const theme = createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={{ [THEME_ID]: theme }}>
        <UserSettingsProvider
          render={({ isDark }) => (
            <ThemeProvider theme={isDark ? ThemeDark : ThemeLight}>
              <IconContext.Provider value={{ size: Theme.icon.size }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <GlobalStyles />
                  <Routes />
                </LocalizationProvider>
              </IconContext.Provider>
            </ThemeProvider>
          )}
        />
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
