import * as R from 'ramda';
import { SyntheticEvent } from 'react';

import { FilterOptionsState } from '@mui/material';
import { User } from '../../types';
import { uniqueLeft } from '../../util/collections';
import S from '../../util/strings';
import { Autocomplete, TextField } from '../atoms';

export type ChangeEvent<A = Record<string, unknown>> = React.ChangeEvent<A>;

interface UsersAutocompleteProps {
  chosenUsers: User[];
  users: User[];
  onChange: (event: SyntheticEvent<Element, Event>, value: User[]) => void;
  placeholder?: string;
  className?: string;
}

const UsersAutocomplete: React.FC<UsersAutocompleteProps> = ({
  chosenUsers,
  users,
  onChange,
  placeholder,
  className,
}) => {
  const unchosenUsersMatchingInput = (
    givenUsers: User[],
    state: FilterOptionsState<User>
  ) => {
    const unchosenUsers = uniqueLeft(givenUsers, chosenUsers);
    const { inputValue } = state;

    if (R.isEmpty(inputValue)) {
      return unchosenUsers;
    }

    return R.filter(
      (user) => S.startsWith(S.lowerCase(user.name), S.lowerCase(inputValue)),
      unchosenUsers
    );
  };

  return (
    <Autocomplete
      className={className}
      value={chosenUsers}
      autoComplete
      multiple
      onChange={onChange}
      options={users}
      filterOptions={unchosenUsersMatchingInput}
      filterSelectedOptions
      getOptionLabel={(user) => user.name}
      renderOption={(props, user) => (
        <li {...props}>
          <div>
            {user.name} — {user.email}
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={placeholder || 'Add people and groups'}
        />
      )}
    />
  );
};

export default UsersAutocomplete;
