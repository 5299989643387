import React from 'react';

import { TableData, TableWidget } from '../../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../atoms';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';
import ReportWidgetBase from './ReportWidgetBase';

interface Props {
  widget: TableWidget;
  data?: TableData;
  preview?: boolean;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const ReportWidgetTable: React.FC<Props> = ({
  widget,
  data,
  preview,
  onEditWidgetSubmit,
}) => {
  return (
    <ReportWidgetBase
      widget={widget}
      contentNoPadding
      preview={preview}
      onEditWidgetSubmit={onEditWidgetSubmit}
    >
      {data && (
        <TableContainer>
          <Table aria-labelledby={`${widget.name} table`} size="medium">
            <TableHead>
              <TableRow>
                {widget.columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows.map((row) => (
                <TableRow key={row.id}>
                  {widget.columns.map((column) => (
                    <TableCell key={column}>{row[column]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ReportWidgetBase>
  );
};

export default ReportWidgetTable;
