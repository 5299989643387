import * as R from 'ramda';
import { FaRegTrashAlt as TrashCanIcon } from 'react-icons/fa';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';

import { Button, IconButton, Paragraph } from '../../atoms';

const ItemsContainer = styled(Box)`
  background-color: ${({ theme }) => theme.backgroundColor.background2};
`;

const EmptyScheduleText = styled.p`
  color: ${({ theme }) => theme.textColor.text3};
`;

interface ReportActionsIndexProps<T> {
  items: Record<string, T>;
  onAddClick: () => void;
  onEditClick: (item: T) => void;
  onRemoveClick: (item: T) => void;
  removeConfirmationText: string;
  emptyItemsText: string;
  addButtonText: string;
}

interface HasIdAndTitle {
  id: string;
  title: string;
}

const ReportActionsIndex = <T extends HasIdAndTitle>({
  items,
  onAddClick,
  onEditClick,
  onRemoveClick,
  removeConfirmationText,
  emptyItemsText,
  addButtonText,
}: ReportActionsIndexProps<T>) => {
  const handleRemoveClick = (item: T) => {
    /* eslint-disable-next-line */
    if (confirm(removeConfirmationText)) {
      onRemoveClick(item);
    }
  };

  return (
    <>
      <ItemsContainer p={3} mb={4}>
        {R.isEmpty(items) ? (
          <Flex my={4} justifyContent="center">
            <EmptyScheduleText>{emptyItemsText}</EmptyScheduleText>
          </Flex>
        ) : (
          <>
            {R.values(items).map((item) => (
              <Flex key={item.id} justifyContent="space-between" mb={2}>
                <Paragraph>{item.title}</Paragraph>
                <Flex justifyContent="flex-end">
                  <Button variant="text" onClick={() => onEditClick(item)}>
                    Edit
                  </Button>
                  <IconButton
                    icon={TrashCanIcon}
                    aria-controls="Remove report"
                    onClick={() => handleRemoveClick(item)}
                    active={false}
                    variant="secondary"
                    size="small"
                  />
                </Flex>
              </Flex>
            ))}
          </>
        )}
      </ItemsContainer>
      <Flex justifyContent="flex-end">
        <Button onClick={() => onAddClick()}>{addButtonText}</Button>
      </Flex>
    </>
  );
};

export default ReportActionsIndex;
