import React from 'react';

import { useTheme } from '@emotion/react';

import ColoredCircle from './ColoredCircle';

const NotificationDot = () => {
  const theme = useTheme();

  return (
    <ColoredCircle
      color={theme.backgroundColor.notification}
      width="6px"
      height="6px"
    />
  );
};

export default NotificationDot;
