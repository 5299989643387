import { Box, Flex } from 'rebass';

import { withTheme } from '@emotion/react';

import { HasTheme, ThemeType } from '../../../styles/settings/Theme';

interface QuadrantsProps extends HasTheme {
  topLeft: React.ReactNode;
  topRight: React.ReactNode;
  bottomLeft: React.ReactNode;
  bottomRight: React.ReactNode;
}

const Quadrants: React.FC<QuadrantsProps> = ({
  theme,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}) => {
  enum QuadrantNames {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
  }

  const { TopLeft, TopRight, BottomLeft, BottomRight } = QuadrantNames;

  const quadrantBorder = ({
    quadrant,
    theme: givenTheme,
  }: {
    quadrant: number;
    theme: ThemeType;
  }) => {
    const borderAttrs = `${givenTheme.borders.default.attrs} ${givenTheme.colors.greyLight4}`;

    switch (quadrant) {
      case TopLeft:
        return { borderBottom: borderAttrs };
      case TopRight:
        return { borderBottom: borderAttrs, borderLeft: borderAttrs };
      case BottomLeft:
        return {};
      case BottomRight:
        return { borderLeft: borderAttrs };
      default:
        return {};
    }
  };

  const quadrantComponent = ({ quadrant }: { quadrant: number }) => {
    switch (quadrant) {
      case TopLeft:
        return topLeft;
      case TopRight:
        return topRight;
      case BottomLeft:
        return bottomLeft;
      case BottomRight:
        return bottomRight;
      default:
        return null;
    }
  };

  const quadrantPadding = (quadrant: number) => {
    const xPadding = [3, 5];
    const yPadding = [3, 4];

    switch (quadrant) {
      case TopLeft:
        return {
          pt: 0,
          pr: xPadding,
          pb: yPadding,
          pl: 0,
        };
      case TopRight:
        return {
          pt: 0,
          pr: 0,
          pb: yPadding,
          pl: xPadding,
        };
      case BottomLeft:
        return {
          pt: yPadding,
          pr: xPadding,
          pb: 0,
          pl: 0,
        };
      case BottomRight:
        return {
          pt: yPadding,
          pr: 0,
          pb: 0,
          pl: xPadding,
        };
      default:
        return null;
    }
  };

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {[...Array(4).keys()].map((quadrant: number) => (
        <Box
          key={quadrant.toString()}
          width={0.5}
          style={quadrantBorder({ quadrant, theme })}
          {...quadrantPadding(quadrant)}
        >
          {quadrantComponent({ quadrant })}
        </Box>
      ))}
    </Flex>
  );
};

export default withTheme(Quadrants);
