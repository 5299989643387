import { HasId } from './common';

export enum NotificationCategory {
  Business = 'Business',
  Scheduled = 'Scheduled',
  Shared = 'Shared',
}

export enum NotificationResourceType {
  Report = 'Report',
  None = 'None',
}

export interface Notification extends HasId {
  title: string;
  longTitle: string;
  date: number;
  message: string;
  category: NotificationCategory;
  resource: {
    type: NotificationResourceType;
    id: string;
  };
}
