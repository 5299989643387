import React from 'react';

import { CalloutData, CalloutWidget } from '../../types';
import { H1, TrendLine } from '../atoms';
import { EditWidgetFormValues } from '../organisms/EditWidgetModal/editWidgetTypes';
import ReportWidgetBase from './ReportWidgetBase';

interface Props {
  widget: CalloutWidget;
  data?: CalloutData;
  preview?: boolean;
  onEditWidgetSubmit: (data: EditWidgetFormValues) => void;
}

const ReportWidgetCallout: React.FC<Props> = ({
  widget,
  data,
  preview,
  onEditWidgetSubmit,
}) => {
  const footerContent = <TrendLine direction="up" text={data?.compare || ''} />;

  return (
    <ReportWidgetBase
      footerContent={footerContent}
      preview={preview}
      widget={widget}
      onEditWidgetSubmit={onEditWidgetSubmit}
    >
      {data && <H1>{data.main}</H1>}
    </ReportWidgetBase>
  );
};

export default ReportWidgetCallout;
