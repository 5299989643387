import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  FourOhFour,
  Report,
  ReportsIndex,
  Notifications,
  Profile,
  ProfileEdit,
  ProfilePreferences,
  Welcome,
  WidgetData,
} from '../components/pages';
import ScrollToTop from '../util/ScrollToTop';
import { paths } from './paths';
import RouteChange from './RouteChange';

export const Routes: React.FC = () => {
  return (
    <Router>
      <RouteChange />
      <ScrollToTop />
      <Switch>
        {/* Omitted for Prototype */}
        {/* <Redirect from={paths.home()} to={paths.welcome()} exact /> */}
        <Route exact path={paths.welcome()} component={Welcome} />
        <Route exact path={paths.reports()} component={ReportsIndex} />
        <Route exact path={paths.report()} component={Report} />
        <Route exact path={paths.widgetData()} component={WidgetData} />
        <Route exact path={paths.notifications()} component={Notifications} />
        <Route exact path={paths.profile()} component={Profile} />
        <Route exact path={paths.profileEdit()} component={ProfileEdit} />
        <Route
          exact
          path={paths.profilePreferences()}
          component={ProfilePreferences}
        />

        <Route component={FourOhFour} />
      </Switch>
    </Router>
  );
};

export default Routes;
