import * as R from 'ramda';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Icon_IntroBottomLeftQuadrant,
  Icon_IntroBottomRightQuadrant,
  Icon_IntroTopLeftQuadrant,
  Icon_IntroTopRightQuadrant,
  Logo_COOPIC,
  Guest_Image,
  COOPNew,
} from '../../../assets/images';
import { paths } from '../../../routing/paths';
import { SelectOption } from '../../../types';
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  ErrorText,
  ErrorContainer,
  FormControlLabel,
  FormGroup,
  H3,
  IconButton,
  Modal,
  Paragraph,
} from '../../atoms';
import { ModalProps } from '../../atoms/Modal';
import Quadrants from './Quadrants';
import { reportingFormValidation } from './reportingFormValidation';
import { useUserSettings } from '../../../hooks/useUserSettings';

interface QuadrantContentProps {
  text: string;
  iconSrc: string;
  iconAlt: string;
}
interface ReportingFormValues {
  reportingAreas: string[];
}

const QUADRANT_CONTENT_DATA = [
  {
    text: 'Glean valuable insights from your data, at a glance',
    iconSrc: Icon_IntroTopLeftQuadrant,
    iconAlt: 'Glean insights icon',
  },
  {
    text: 'Acccess, filter, and customize all of your Reports',
    iconSrc: Icon_IntroTopRightQuadrant,
    iconAlt: 'Reports icon',
  },
  {
    text: 'Create new Reports from templates tailored to your role',
    iconSrc: Icon_IntroBottomLeftQuadrant,
    iconAlt: 'Create new report icon',
  },
  {
    text: 'Exports and share Report snapshots and raw data',
    iconSrc: Icon_IntroBottomRightQuadrant,
    iconAlt: 'Export and share icon',
  },
];

const checkboxFieldsData: SelectOption[] = [
  {
    value: 'fraud',
    label: 'Fraud',
  },
  {
    value: 'atm',
    label: 'ATM',
  },
  {
    value: 'spending_trends',
    label: 'Spending Trends',
  },
  {
    value: 'campaigns',
    label: 'Campaigns',
  },
];

const Content = styled(Box)`
  width: 1200px;
`;

const QuadrantImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

const CaptionText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.fontSize30};
`;

const QuadrantContent = ({ text, iconSrc, iconAlt }: QuadrantContentProps) => (
  <>
    <QuadrantImage src={iconSrc} alt={iconAlt} />
    <Box mb={3} />
    <CaptionText>{text}</CaptionText>
  </>
);

const RightCard = styled(Card)`
  height: 100%;
  background: ${({ theme }) => theme.backgroundColor.background2};
  box-shadow: none;
`;

const Logo = styled.img`
  height: 40px;
  color: ${({ theme }) => theme.backgroundColor.darkest};
`;

const GuestHeader = styled.h2`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.h2};
  white-space: pre-wrap;
  word-break: keep-all;
  hyphens: unset;
`;

const FormErrorContainer = styled(ErrorContainer)`
  margin-top: 6px;
  min-height: 15px;
`;

const GuestModal: React.FC<Omit<ModalProps, 'children'>> = ({
  onClose,
  ...props
}) => {
  const history = useHistory();

  const {
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ReportingFormValues>({
    defaultValues: {
      reportingAreas: [],
    },
    resolver: yupResolver(reportingFormValidation),
  });

  const onSubmit = (data: ReportingFormValues) => {
    console.log('RESULTS', data);
    history.push(paths.reports());
  };

  const handleSelect = (value: string): void => {
    const currentSelection = getValues('reportingAreas');

    const newSelection = currentSelection.includes(value)
      ? R.without([value], currentSelection)
      : [...currentSelection, value];

    setValue('reportingAreas', newSelection, {
      shouldValidate: true,
    });
  };

  const { isNew } = useUserSettings();

  return (
    <Modal onClose={onClose} {...props}>
      <Content>
        <Card>
          <CardBody padding="small">
            <Flex justifyContent="space-between" alignItems="center">
              <Logo
                src={isNew ? COOPNew : Logo_COOPIC}
                alt="CO-OP Insights Center"
              />
              <IconButton
                icon={HiX}
                aria-controls="Close modal"
                onClick={(e) => onClose && onClose(e, 'backdropClick')}
                active={false}
                variant="secondary"
                size="small"
              />
            </Flex>

            <Box mb={4} />
            <Flex>
              <Box flex="1" mx={4}>
                <Flex
                  px={3}
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Box>
                    <GuestHeader>
                      Connect your insights to{'\n'}high-performing results
                    </GuestHeader>
                    <Box my={4} />
                    <Paragraph>
                      The Insights Center technology combines accurate data with
                      industry insights to deliver powerful business results.
                    </Paragraph>
                    <Box my={5} />

                    <Quadrants
                      topLeft={
                        <QuadrantContent {...QUADRANT_CONTENT_DATA[0]} />
                      }
                      topRight={
                        <QuadrantContent {...QUADRANT_CONTENT_DATA[1]} />
                      }
                      bottomLeft={
                        <QuadrantContent {...QUADRANT_CONTENT_DATA[2]} />
                      }
                      bottomRight={
                        <QuadrantContent {...QUADRANT_CONTENT_DATA[3]} />
                      }
                    />
                  </Box>
                  <div>
                    <Box my={3} />
                    <Button
                      variant="text"
                      size="small"
                      backgroundOnHover="transparent"
                    >
                      Contact Us
                    </Button>
                  </div>
                </Flex>
              </Box>
              <Box flex="1">
                <RightCard leftBorder>
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    px={4}
                  >
                    <Box my={4} />
                    <img src={Guest_Image} alt="graphic of charts and graphs" />

                    <H3>What areas of reporting do you use?</H3>
                    <Box my={1} />
                    <CaptionText>
                      (You can change these selections in your preferences)
                    </CaptionText>
                    <Box my={2} />

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup>
                        {checkboxFieldsData.map((checkboxItem) => (
                          <Controller
                            key={checkboxItem.value}
                            name="reportingAreas"
                            control={control}
                            render={({
                              field: { onChange, value, ref, ...field },
                            }) => (
                              <FormControlLabel
                                label={checkboxItem.label}
                                control={
                                  <Checkbox
                                    color="primary"
                                    value={checkboxItem.value}
                                    onChange={(e) => {
                                      handleSelect(e.target.value);
                                    }}
                                    inputRef={ref}
                                    {...field}
                                  />
                                }
                              />
                            )}
                          />
                        ))}
                      </FormGroup>
                      <FormErrorContainer>
                        <ErrorMessage
                          errors={errors}
                          name="reportingAreas"
                          render={({ message }) => (
                            <ErrorText>{message}</ErrorText>
                          )}
                        />
                      </FormErrorContainer>
                      <Box my={4} />

                      <Button type="submit">Continue</Button>
                    </form>
                    <Box my={3} />
                  </Flex>
                </RightCard>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Content>
    </Modal>
  );
};

export default GuestModal;
