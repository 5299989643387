import { HiCheck, HiMinus } from 'react-icons/hi';

import styled from '@emotion/styled';
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@mui/material';

const StyledCheckbox = styled(MUICheckbox)`
  padding: 0 0 4px 0;
`;

const CheckboxIcon = styled.span`
  border: ${({ theme }) => theme.borders.checkbox.unchecked};
  border-radius: ${({ theme }) => theme.borders.default.radius};
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.backgroundColor.background1};

  input:disabled ~ & {
    border: ${({ theme }) => theme.borders.checkbox.disabled};
  }
`;

const CheckedIcon = styled(CheckboxIcon)`
  border: ${({ theme }) => theme.borders.checkbox.checked};
  background-color: ${({ theme }) => theme.backgroundColor.button.default};
  color: ${({ theme }) => theme.backgroundColor.background1};
  display: flex;
  justify-content: center;
  align-items: center;

  input:disabled ~ & {
    background: ${({ theme }) => theme.colors.tealB};
  }
`;

const Checkbox = (props: MUICheckboxProps) => {
  return (
    <StyledCheckbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={
        <CheckedIcon>
          <HiCheck size={16} />
        </CheckedIcon>
      }
      icon={<CheckboxIcon />}
      indeterminateIcon={
        <CheckedIcon>
          <HiMinus size={16} />
        </CheckedIcon>
      }
      {...props}
    />
  );
};

export default Checkbox;
