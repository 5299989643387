import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { IconBell } from '../../assets/images';

import ColoredCircle from './ColoredCircle';

interface Props {
  width?: string;
  height?: string;
  iconSize?: string;
}

const NotificationCircle = styled(ColoredCircle)`
  display: inline-flex;
  text-align: center;
`;

const NotificationIcon: React.FC<Props> = ({
  width,
  height,
  children,
  iconSize,
}) => {
  const theme = useTheme();

  return (
    <NotificationCircle
      width={width || '30px'}
      height={height || '30px'}
      color={theme.backgroundColor.notification}
    >
      {children || (
        <IconBell
          color="#6B4800"
          width={iconSize || '20px'}
          height={iconSize || '20px'}
        />
      )}
    </NotificationCircle>
  );
};

export default NotificationIcon;
