import { Box } from 'rebass';

import styled from '@emotion/styled';

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.redMedium};
  font-size: ${({ theme }) => theme.fontSize.caption1};
`;

const ErrorTextBottom = styled(ErrorText)`
  position: absolute;
  right: 0;
`;

export const ErrorContainer = styled(Box)`
  position: relative;
`;

interface Props {
  className?: string;
}
export const ErrorTextWithContainer: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <ErrorContainer className={className}>
      <ErrorTextBottom>{children}</ErrorTextBottom>
    </ErrorContainer>
  );
};
