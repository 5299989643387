import { getTime } from 'date-fns';
import * as R from 'ramda';

import {
  Collaborator,
  CollaboratorId,
  GroupReport,
  Notification,
  Report,
  ReportCategory,
  ReportGroup,
  ReportRole as RR,
} from '../../types';
import { mapValues } from '../../util/collections';
import { makeId } from '../../util/ids';
import S from '../../util/strings';

export const keyToDisplayName = (category: string) => {
  return R.split('-', S.titleCase(S.kebabCase(category))).join(' ');
};

const ReportGroups = (() => {
  const makeGroupReport = ({
    name,
    group,
    category,
    lastUpdated,
    id,
    filters,
    collaborators,
    notifications,
  }: {
    name: string;
    group: ReportGroup;
    category?: ReportCategory;
    lastUpdated?: number;
    id?: string;
    filters: Report['filters'];
    collaborators?: Record<CollaboratorId, Collaborator>;
    notifications?: Notification[];
  }): GroupReport => {
    return {
      group,
      report: {
        id: id || makeId({ entity: 'ReportGroup' }),
        name,
        category: category || ReportCategory.Management,
        lastUpdated: lastUpdated || getTime(new Date()),
        widgets: {},
        filters,
        collaborators: collaborators || {},
        notifications,
      },
    };
  };

  const makeReportGroup = ({
    id,
    name,
  }: Pick<ReportGroup, 'id' | 'name'>): ReportGroup => {
    return {
      id,
      name,
    };
  };

  const FrequentlyUsed = makeReportGroup({ id: '1', name: 'Frequently Used' });
  const Recommended = makeReportGroup({ id: '2', name: 'Recommended' });
  const AllReports = makeReportGroup({ id: '3', name: 'All Reports' });
  const Favorites = makeReportGroup({ id: '4', name: 'Favorites' });

  const standard = {
    FrequentlyUsed,
    Recommended,
    AllReports,
    Favorites,
  };

  const standardIds = mapValues(R.prop('id'), standard);
  const standardNames = mapValues(R.prop('name'), standard);

  return {
    standard,
    standardIds,
    standardNames,
    makeGroupReport,
  };
})();

export const owner = ({ collaborators }: Report) => {
  return R.find(
    (collaborator) => collaborator.reportRole === RR.Owner,
    R.values(collaborators)
  );
};

export const ownerName = (report: Report) => {
  return owner(report)?.user?.name;
};

export default ReportGroups;
